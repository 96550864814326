import { ErrorMessage, Field, Form, Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import FieldError from "../../shared/components/field-error.component";
import FormikRadioButton from "../../shared/components/formik-radio-button.component";

const scheduleSessionVisitTypeSchema = Yup.object({
  visitType: Yup.string().required("Please select a visit type"),
});

interface scheduleSessionVisitTypeProps {
  value: any;
  setCurrentOverlay: React.Dispatch<React.SetStateAction<string>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<{
    visitType: string;
    location: string;
    preferredDate: string;
    specialty: string;
    visitReason: string;
  }>>;
}

const ScheduleSessionVisitType: React.FC<scheduleSessionVisitTypeProps> = ({
  value,
  setCurrentOverlay,
  setFieldValue,
}) => {
  return (
    <div className="mt-4 relative h-full">
      {/* Heading */}
      <p className="items-center justify-center gap-3 text-primary hidden max-lg:flex">
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.6667 2.16602C17.1267 2.16602 17.5 2.53935 17.5 2.99935V17.9993C17.5 18.4593 17.1267 18.8327 16.6667 18.8327H5C4.54 18.8327 4.16667 18.4593 4.16667 17.9993V16.3327H2.5V14.666H4.16667V12.9993H2.5V11.3327H4.16667V9.66602H2.5V7.99935H4.16667V6.33268H2.5V4.66602H4.16667V2.99935C4.16667 2.53935 4.54 2.16602 5 2.16602H16.6667ZM15.8333 3.83268H5.83333V17.166H15.8333V3.83268ZM11.6667 7.16602V9.66602H14.1667V11.3327H11.6658L11.6667 13.8327H10L9.99917 11.3327H7.5V9.66602H10V7.16602H11.6667Z"
            fill="#3537E8"
          />
        </svg>
        Visit Type
      </p>
      <p className="max-lg:text-center font-semibold mt-5 mx-4 mb-6">
        Where would you like to schedule the visit?
      </p>

      {/* Form */}
      <Formik
        initialValues={{
          visitType: value.visitType,
        }}
        validationSchema={scheduleSessionVisitTypeSchema}
        onSubmit={(values) => {
          setCurrentOverlay("");
          setFieldValue("visitType", values.visitType);
        }}>
        {({ values }) => (
          <Form className="mt-4 flex flex-col gap-4 px-4">
            <div className="flex max-lg:flex-col gap-10 max-lg:gap-6">
              <Field
                component={FormikRadioButton}
                name="visitType"
                id="Patient's Home"
                label="Patient's Home"
              />
              <Field
                component={FormikRadioButton}
                name="visitType"
                id="Provider's Hospital"
                label="Provider's Hospital"
              />
            </div>

            <ErrorMessage name="visitType">
              {(msg) => <FieldError message={msg} />}
            </ErrorMessage>

            <button
              type="submit"
              className="w-fit max-lg:w-[90%] bg-black px-20 max-sm:px-4 py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16 max-lg:absolute max-lg:bottom-10">
              Done
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ScheduleSessionVisitType;
