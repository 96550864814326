import { useNavigate, useParams } from "react-router-dom";
import docProfile from "../../assets/images/doc-profile.png";
import { loggerService, networkService } from "../../services";
import appConstants, { ScopeType } from "../shared/config";
import { useEffect, useState } from "react";
import { useServiceConsumer } from "../shared/hooks";
import {
  selectIsConnectedToRoom,
  selectLocalPeer,
  selectLocalPeerRole,
  selectPeersByRole,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { IServiceConsumer, OTRoleType } from "../../interfaces";
import { useLazyQuery } from "@apollo/client";
import {
  GET_APPOINTMENT_BY_TOKEN,
  GET_SERVICE_CONSUMER_BY_EMAIL_OR_MOBILE,
} from "../../apollo";
import { useConsumerStore } from "../../store";
import dateService from "../../services/date.service";
import { useAuth0 } from "@auth0/auth0-react";
import websocketLoggerService from "../../services/websocket-logger";
import { helper } from "../shared/utils";

const Appointment = () => {
  const navigate = useNavigate();

  const { id: appointmentToken } = useParams();
  const serviceConsumer = useServiceConsumer();
  const [sessionID, setSessionID] = useState<string>("");
  const consumerId = serviceConsumer?.id.toString();
  const consumerName = `${serviceConsumer?.firstName} ${serviceConsumer?.lastName}`;
  const [appointmentDetails, setAppointmentDetails] = useState<any>({});

  const hmsActions = useHMSActions(),
    isConnected = useHMSStore(selectIsConnectedToRoom),
    waitingPeers = useHMSStore(selectPeersByRole(OTRoleType.Waiting));

  const [getAppointmentByToken] = useLazyQuery(GET_APPOINTMENT_BY_TOKEN);
  const setConsumer = useConsumerStore((store) => store.setConsumer);
  const { user } = useAuth0();

  const [getServiceConsumerByEmail] = useLazyQuery<{
    getConsumerByEmail: IServiceConsumer;
  }>(GET_SERVICE_CONSUMER_BY_EMAIL_OR_MOBILE, {
    context: {
      scope: ScopeType.Consumer,
    },
  });

  useEffect(() => {
    const getAppointment = async () => {
      const { data } = await getAppointmentByToken({
        variables: {
          appointmentToken,
        },
      });
      setAppointmentDetails(data.appointmentByToken);
    };

    getAppointment();
  }, []);

  useEffect(() => {
    if (!serviceConsumer) {
      return;
    }
    networkService
      .post<any>(`${appConstants.urls.openTeleUrl}/api/sessions`, {
        consumerId: serviceConsumer?.id,
      })
      .then((res) => {
        setSessionID(res.id);
      });
  }, [serviceConsumer]);

  useEffect(() => {
    const getServiceConsumer = async () => {
      const userTimezone = dateService.getUserTimezone();

      if (!user?.email && !user?.phone_number) {
        return;
      }

      try {
        const userEmail = user?.email || "";
        const userPhoneNumber = user?.phone_number || "";

        const { data } = await getServiceConsumerByEmail({
          variables: {
            consumer: {
              email: userEmail,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
              mobileNumber: userPhoneNumber,
            },
          },
          // disable cache to get latest data
          fetchPolicy: "no-cache",
        });

        websocketLoggerService.sendMessage({
          eventType: `Auth0 Login`,
          eventSubType: `API: getServiceConsumerByEmail success`,
          eventData: `User Email: ${user?.email}`,
        });
        const response = helper.getPropertyValue<IServiceConsumer>(
          data,
          "getConsumer"
        );

        if (!response) {
          return;
        }

        const serviceConsumer = { ...response };
        if (!serviceConsumer.timeZone) {
          serviceConsumer.timeZone = userTimezone;
        }

        serviceConsumer.isGuestUser = false;

        const { idProof, insuranceDetails, ...dateToSetInStore } =
          serviceConsumer;

        setConsumer(dateToSetInStore);
      } catch (error) {
        console.log(error);

        websocketLoggerService.sendMessage({
          eventType: `Auth0 Login`,
          eventSubType: `Error`,
          eventData: `Error data ${JSON.stringify(error)}`,
        });
      }
    };
    getServiceConsumer();
  }, [
    getServiceConsumerByEmail,
    setConsumer,
    user,
    user?.email,
    user?.phone_number,
  ]);

  useEffect(() => {
    window.onunload = () => {
      if (isConnected) {
        hmsActions.leave();
      }
    };
  }, [hmsActions, isConnected]);

  const getPeersBeforeMe = () => {
    // ToDo: add logic to compute peers added before me
    return waitingPeers.length;
  };
  const joinRoom = async () => {
    try {
      const res = await networkService.get<any>(
        `${
          appConstants.urls.openTeleUrl
        }/api/rooms/direct-consumer-room?region=${
          appointmentDetails?.appointmentType?.serviceProvider.state as string
        }&organizationId=${
          appointmentDetails.organizationId as string
        }&consumerId=${consumerId}`
      );

      console.log("res", res);

      await networkService
        .put<any>(
          `${appConstants.urls.openTeleUrl}/api/sessions/${sessionID}`,
          {
            consumerID: parseInt(consumerId),
            id: sessionID,
            consumerState: "CONSUMERASSIGNED",
            roomId: res.roomId,
          }
        )
        .then(async (result) => {
          await hmsActions.join({
            userName: consumerName,
            authToken: res.appToken,
            metaData: sessionID,
          });

          navigate("consult-now", {
            state: {
              appointmentToken,
              appointmentDetails,
            },
          });
        });
    } catch (err) {
      loggerService.error("joinRoom", err);
    }
  };

  return (
    <main className="">
      <div className="mt-8">
        <h1 className="text-2xl mt-4 px-4">Appointment Details</h1>
        <section className="mt-4 px-4">
          <div className="w-1/2 max-lg:w-3/4 max-sm:w-full shadow-blue border mt-2 border-[#3537E8] rounded-md mb-2 flex items-center justify-between gap-2">
            <div className="flex max-sm:flex-col items-start justify-between gap-0  w-full">
              <div className="flex items-center gap-2 ml-4  border-b ">
                <img
                  src={docProfile}
                  alt=""
                  className="w-14 h-14  rounded-full"
                />
                <div className="flex flex-col p-4 ">
                  {appointmentDetails?.appointmentType && (
                    <p className="flex justify-between font-bold ">
                      Dr.{" "}
                      {
                        appointmentDetails?.appointmentType?.serviceProvider
                          ?.firstName
                      }{" "}
                      {
                        appointmentDetails?.appointmentType?.serviceProvider
                          ?.lastName
                      }
                    </p>
                  )}
                  <p className="flex justify-between text-primary ">
                    Primary Care
                  </p>
                </div>
              </div>

              <div
                className={`flex justify-between max-sm:border-primary max-sm:w-full `}>
                <div className="flex flex-col p-4 max-sm:border-none ">
                  <div className="flex justify-between flex-col ">
                    <div className="text-sm text-gray-light">Punch in at</div>
                    <div>{appointmentDetails.startTime}</div>
                  </div>
                </div>
                <div className="flex justify-center items-center flex-col p-4 ">
                  <div className="bg-red-50 text-red-500 px-3 py-1">
                    In Consultation
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="absolute w-full  px-4  bottom-0 h-3/5 bg-bgLightBlue rounded-t-xl">
        {getPeersBeforeMe() > 0 ? (
          <div>
            <div className="flex gap-4  rounded-xl border border-lightBlue items-center bg-white w-full px-4 py-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="37"
                fill="none"
                viewBox="0 0 37 37">
                <g
                  stroke="#3537E8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                  clipPath="url(#clip0_5913_39051)">
                  <path d="M26.023 32v-3a6 6 0 00-6-6h-12a6 6 0 00-6 6v3M14.023 17a6 6 0 100-12 6 6 0 000 12zM35.023 31.998v-3a6 6 0 00-4.5-5.805M24.523 5.193a6 6 0 010 11.625"></path>
                </g>
                <defs>
                  <clipPath id="clip0_5913_39051">
                    <path
                      fill="#fff"
                      d="M0 0H36V36H0z"
                      transform="translate(.523 .5)"></path>
                  </clipPath>
                </defs>
              </svg>

              <div>
                <div className="text-sm text-gray-light">
                  Patients before you
                </div>
                <div className="font-bold">2 Patients</div>
              </div>
            </div>

            <div className="flex gap-4 mt-4  rounded-xl border border-lightBlue items-center bg-white w-full px-4 py-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="37"
                fill="none"
                viewBox="0 0 37 37">
                <path
                  fill="#3537E8"
                  d="M18.523 3.5c8.285 0 15 6.716 15 15 0 8.285-6.715 15-15 15-8.284 0-15-6.715-15-15h3c0 6.627 5.373 12 12 12s12-5.373 12-12-5.373-12-12-12c-4.125 0-7.764 2.08-9.922 5.25h3.922v3h-9v-9h3V9.5a14.97 14.97 0 0112-6zm1.5 7.5v6.878l4.865 4.864-2.123 2.122-5.742-5.745V11h3z"></path>
              </svg>
              <div>
                <div className="text-sm text-gray-light">
                  Estimated meeting time
                </div>
                <div className="font-bold">10:14 AM</div>
              </div>
            </div>
          </div>
        ) : null}
        <button
          onClick={async () => await joinRoom()}
          type="submit"
          className={`w-full ${
            getPeersBeforeMe() > 0 ? "mt-20" : ""
          } bg-black   py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16 max-sm:px-4`}>
          Join Room
        </button>
      </div>
    </main>
  );
};

export default Appointment;
