import produce from "immer";
import create, { State } from "zustand";
import { devtools, persist } from "zustand/middleware";
import appConstants from "../../app/shared/config";

interface AvailabilityState extends State {
  visitType: string;
  patientLocation: string;
  preferredDate: string;
  specialty: string;
  visitReason: string;
  provider: string;
  appointmentDate: string;
  appointmentStartTime: string;
  appointmentEndTime: string;
  setVisitType: (visitType: string) => void;
  setPatientLocation: (patientLocation: string) => void;
  setPreferredDate: (preferredDate: string) => void;
  setSpecialty: (specialty: string) => void;
  setVisitReason: (visitReason: string) => void;
  setWholeState: (wholeState: any) => void;
  setProvider: (provider: string) => void;
  setAppointmentDate: (appointmentDate: string) => void;
  setAppointmentStartTime: (appointmentStartTime: string) => void;
  setAppointmentEndTime: (appointmentEndTime: string) => void;
}

const useAvailabilityStore = create<AvailabilityState>(
  persist(
    devtools(
      (set) => ({
        visitType: "",
        patientLocation: "",
        preferredDate: "",
        specialty: "",
        visitReason: "",
        provider: "",
        appointmentDate: "",
        appointmentStartTime: "",
        appointmentEndTime: "",
        setVisitType: (visitType: string) =>
          set(
            produce((state) => {
              state.visitType = visitType;
            })
          ),
        setPatientLocation: (patientLocation: string) =>
          set(
            produce((state) => {
              state.patientLocation = patientLocation;
            })
          ),
        setPreferredDate: (preferredDate: string) =>
          set(
            produce((state) => {
              state.preferredDate = preferredDate;
            })
          ),
        setSpecialty: (specialty: string) =>
          set(
            produce((state) => {
              state.specialty = specialty;
            })
          ),
        setVisitReason: (visitReason: string) =>
          set(
            produce((state) => {
              state.visitReason = visitReason;
            })
          ),
        setWholeState: (wholeState: any) =>
          set(
            produce((state) => {
              state.visitType = wholeState.visitType;
              state.patientLocation = wholeState.patientLocation;
              state.preferredDate = wholeState.preferredDate;
              state.specialty = wholeState.specialty;
              state.visitReason = wholeState.visitReason;
            })
          ),
        setProvider: (provider: string) =>
          set(
            produce((state) => {
              state.provider = provider;
            })
          ),
        setAppointmentDate: (appointmentDate: string) =>
          set(
            produce((state) => {
              state.appointmentDate = appointmentDate;
            })
          ),
        setAppointmentStartTime: (appointmentStartTime: string) =>
          set(
            produce((state) => {
              state.appointmentStartTime = appointmentStartTime;
            })
          ),
        setAppointmentEndTime: (appointmentEndTime: string) =>
          set(
            produce((state) => {
              state.appointmentEndTime = appointmentEndTime;
            })
          ),
      }),
      { name: "availability", serialize: true }
    ),
    {
      name: "availability", // unique name
      getStorage: () => appConstants.keys.storage, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useAvailabilityStore;
