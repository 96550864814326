import { AxiosRequestConfig } from "axios";
import { useConsumerStore } from "../../store";
import { urls } from "../../app/shared/config";

const requestInterceptor = (config: AxiosRequestConfig) => {
  const authToken = useConsumerStore.getState().token;

  if (config.url === urls.googleMapsVerifyAddress) {
    return config;
  }

  if (authToken !== null) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${authToken}`,
    });
  }
  return config;
};

export default requestInterceptor;
