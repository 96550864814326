import { gql } from "@apollo/client";

export const ADD_SERVICE_CONSUMER = gql`
  mutation Mutation($consumer: ConsumerInput!) {
    addConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      gender
      birthDate
      mobileNumber
      homePhoneNumber
      timeZone
      organizationId
      isPatient
      idProof {
        idNumber
        state
        drivingLicenseFront
        drivingLicenseBack
      }
      dependants {
        id
        firstName
        lastName
        email
        gender
        birthDate
        mobileNumber
        homePhoneNumber
        timeZone
        organizationId
        isPatient
        idProof {
          idNumber
          state
          drivingLicenseFront
          drivingLicenseBack
        }
        insuranceDetails {
          idNumber
          groupNumber
          provider
          frontSide
          backSide
          guarantorFirstName
          guarantorLastName
          guarantorPhone
          guarantorRelation
          guarantorBirthDate
        }
      }
    }
  }
`;

export const LINK_CONSUMER = gql`
  mutation Mutation($linkGuardianToConsumer: LinkGuardianToConsumerInput!) {
    linkConsumers(linkGuardianToConsumer: $linkGuardianToConsumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      homePhoneNumber
      timeZone
      gender
      organizationId
      idProof {
        idNumber
        state
        drivingLicenseFront
        drivingLicenseBack
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide
        backSide
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
      }
      dependants {
        id
        firstName
        lastName
        email
        gender
        birthDate
        mobileNumber
        homePhoneNumber
        timeZone
        organizationId
      }
    }
  }
`;

export const UPDATE_CONSUMER_GUARDIAN = gql`
  mutation Mutation($consumer: ConsumerInput!) {
    updateConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      gender
      birthDate
      mobileNumber
      homePhoneNumber
      timeZone
      organizationId
      isPatient
      addresses {
        state
        id
        line1
        line2
        zipCode
        state
        district
        country
        city
      }

      dependants {
        id
        firstName
        lastName
        email
        gender
        birthDate
        mobileNumber
        homePhoneNumber
        timeZone
        organizationId
        addresses {
          id
          line1
          line2
          zipCode
          state
          district
          country
          city
        }

        isPatient
        idProof {
          idNumber
          state
          drivingLicenseFront
          drivingLicenseBack
        }
        insuranceDetails {
          idNumber
          groupNumber
          provider
          frontSide
          backSide
          guarantorFirstName
          guarantorLastName
          guarantorPhone
          guarantorRelation
          guarantorBirthDate
        }
      }
    }
  }
`;

export const GET_STRIPE_INTENT_SECRET = gql`
  mutation Mutation($organizationId: Int!) {
    addPaymentIntent(organizationId: $organizationId) {
      clientSecret
    }
  }
`;

export const CONSUMER_EPISODE_START = gql`
  mutation createConsumerEpisode($consumerEpisodeInput: ConsumerEpisodeInput!) {
    createConsumerEpisode(consumerEpisodeInput: $consumerEpisodeInput) {
      id
      isComplete
    }
  }
`;

export const CONSUMER_EPISODE_UPDATE = gql`
  mutation updateConsumerEpisode($consumerEpisodeInput: ConsumerEpisodeInput!) {
    updateConsumerEpisode(consumerEpisodeInput: $consumerEpisodeInput) {
      id
      isComplete
    }
  }
`;

export const BOOK_BLOCKED_APPOINTMENT = gql`
  mutation BookBlockedAppointmentMutation(
    $appointment: BlockedSlotAppointmentInput!
  ) {
    bookBlockedAppointment(appointment: $appointment) {
      token
    }
  }
`;

export const CONSUMER_TERMS_CONFIRM = gql`
  mutation createAgreementMutation($agreement: AgreementInput!) {
    createAgreement(agreement: $agreement) {
      id
      agreementTemplateId
      personId
      fileId
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_DRIVING_LICENSE = gql`
  mutation Mutation($consumer: ConsumerInput!) {
    updateConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      homePhoneNumber
      organizationId
      isPatient
      idProof {
        idNumber
        state
        drivingLicenseFront
        drivingLicenseBack
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide
        backSide
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
        guarantorBirthDate
      }
    }
  }
`;

export const UPDATE_CONSUMER_IDENTITY_PROOF = gql`
  mutation Mutation($consumer: ConsumerInput!) {
    updateConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      homePhoneNumber
      organizationId
      isPatient
      idProof {
        idNumber
        state
        drivingLicenseFront
        drivingLicenseBack
        healthCareProxyDocument
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide
        backSide
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
        guarantorBirthDate
      }
    }
  }
`;

export const UPDATE_INSURANCE_DETAILS = gql`
  mutation Mutation($consumer: ConsumerInput!) {
    updateConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      homePhoneNumber
      organizationId
      isPatient
      idProof {
        idNumber
        state
        drivingLicenseFront
        drivingLicenseBack
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide
        backSide
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
        guarantorBirthDate
      }
    }
  }
`;

export const CONSUMER_GUEST_TOKEN = gql`
  mutation Mutation($guest: GuestInput!) {
    guestUserToken(guest: $guest) {
      token
    }
  }
`;

export const BOOK_APPOINTMENT = gql`
  mutation BookAppointmentMutation($appointment: AppointmentInput!) {
    bookAppointment(appointment: $appointment) {
      id
      appointmentTypeId
      organizationId
      consumerId
      date
      startTime
      endTime
      token
      locationType
      locationInfo
      attributes
      status
    }
  }
`;

export const UPDATE_CONSUMER_APPOINTMENT = gql`
  mutation BookAppointmentMutation($appointment: AppointmentInput!) {
    bookAppointment(appointment: $appointment) {
      id
      appointmentTypeId
      consumerId
      date
      startTime
      endTime
      token
      status
      consumer {
        firstName
        lastName
        email
        mobileNumber
      }
      note
      symptoms
      appointmentType {
        id
        durationInMinutes
        startDate
        endDate
        name
        description
        type
        minTimeBeforeBookingInMinutes
        serviceProviderId
        locationType
        serviceProvider {
          id
          firstName
          lastName
          email

          organization {
            id
            name
          }
        }
      }
    }
  }
`;
