import { useMutation } from "@apollo/client";
import domtoimage from "dom-to-image";
import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSketchCanvas } from "react-sketch-canvas";
import * as Yup from "yup";
import { CONSUMER_EPISODE_UPDATE, CONSUMER_TERMS_CONFIRM } from "../../apollo";
import { IConsumerEpisode } from "../../interfaces";
import { loggerService } from "../../services";
import websocketLoggerService from "../../services/websocket-logger";
import { useConsumerStore } from "../../store";
import useFormStore from "../../store/form/form.store";
import DateSeparateInput from "../shared/components/date-separate-input.component";
import FormikInput from "../shared/components/formik-input.component";
import GuideBanner from "../shared/components/guide-banner.component";
import { PatientFlowType, ScopeType } from "../shared/config";
import { parseDOB } from "../shared/utils/helper.util";

interface ITermsConsentFormProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  newPatient: any;
  newPatientId: any;
  serviceConsumer: any;
  TermsAndConditionAgreements: any;
  agreementCompletedCount: any;
  patientFlow: any;
  haveInsurance: boolean;
  consentFormType: number;
  setConsentInputs: (consentInputs: any) => void;
  setAgreementCompletedCount: React.Dispatch<React.SetStateAction<number>>;
}

const FormikContext = ({
  setConsentInputs,
}: {
  setConsentInputs: (consentInputs: any) => void;
}) => {
  const {
    values,
  }: {
    values: {
      patientname: string;
      relationship: string;
      address: string;
      dob: string;
    };
  } = useFormikContext();

  React.useEffect(() => {
    setConsentInputs({
      patientname: values?.patientname,
      relationship: values?.relationship,
      address: values?.address,
      dob: values?.dob,
    });
  }, [setConsentInputs, values]);

  return null;
};

const TermsConsentForm: React.FC<ITermsConsentFormProps> = ({
  newPatient,
  newPatientId,
  serviceConsumer,
  TermsAndConditionAgreements,
  agreementCompletedCount,
  setStep,
  patientFlow,
  haveInsurance,
  consentFormType,
  setConsentInputs,
  setAgreementCompletedCount,
}) => {
  const navigate = useNavigate();
  const typeSign = "type";
  const drawSign = "draw";
  const [signType, setSignType] = React.useState(drawSign);
  const [saveTermsConsent] = useMutation(CONSUMER_TERMS_CONFIRM);
  const [signed, setSigned] = React.useState(false);
  const signComp = useRef(null);

  const [updateConsumerEpisode] = useMutation<{
    createConsumerEpisode: IConsumerEpisode;
  }>(CONSUMER_EPISODE_UPDATE);

  const consumerEpisode = useConsumerStore((state) => state.consumerEpisode);
  const consumerEpisodeId = consumerEpisode?.id;

  const isCcConsentCaptured = useFormStore(
    (state) => state.isCcConsentCaptured
  );

  useEffect(() => {
    TermsAndConditionAgreements.forEach((item) => {
      if (item.name === "Credit Card on File Authorization Form") {
        setAgreementCompletedCount((prev: number) => prev + 1);
      }
    });
  }, [TermsAndConditionAgreements, setAgreementCompletedCount]);

  const handleTermsSubmit = async (values) => {
    const saveConsent = async (base64Img, agreementId) => {
      const options = [];
      const optionres = [];

      TermsAndConditionAgreements.forEach((item) => {
        item.fields.forEach((field) => {
          if (field.type === "boolean") {
            options.push({ key: field.key });
          }
        });
      });

      options.forEach((item) => {
        if ((document.getElementById(item.key) as HTMLInputElement)?.checked) {
          optionres.push({ key: item.key, value: "true" });
        } else {
          optionres.push({ key: item.key, value: "false" });
        }
      });

      try {
        const data = [
          ...optionres,
          { key: "patientname", value: values.patientname },
          {
            key: "relationship",
            value: values.relationship === "" ? " " : values.relationship,
          },
          { key: "address", value: values.address },
          { key: "dob", value: newPatient?.birthDate || "" },
          { key: "date", value: new Date().toString() },
          { key: "signature", value: base64Img },
          {
            key: "selection",
            value:
              document.getElementsByClassName("selection")[0]?.innerHTML || "",
          },
        ];

        // check if data have all the fields present in terms TermsAndConditionAgreements.forEach((item) => { item.fields;
        const termsDataKeys = TermsAndConditionAgreements.flatMap((item) =>
          item.fields.map((field) => field.key)
        );
        const remainingFields = termsDataKeys.filter(
          (item) => !data.some((d) => d.key === item)
        );
        remainingFields.forEach((item) => data.push({ key: item, value: "" }));

        await saveTermsConsent({
          variables: {
            agreement: {
              consumerEpisodeIntakeId: consumerEpisodeId,
              consumerId: newPatientId || serviceConsumer?.id,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
              agreementTemplateId: agreementId,
              data,
            },
          },
          context: {
            scope: ScopeType.Consumer,
          },
        });

        websocketLoggerService.sendMessage({
          eventType: "Terms and condition Form submit",
          eventSubType: "API: CONSUMER_TERMS_CONFIRM success",
          eventData: `Consumer ${
            serviceConsumer?.id as string
          } has signed the terms and conditions location: ${
            window.location.href
          }`,
        });
      } catch (err) {
        websocketLoggerService.sendMessage({
          eventType: "Terms and condition Form submit",
          eventSubType: "API: CONSUMER_TERMS_CONFIRM failed",
          eventData: `Consumer ${
            serviceConsumer?.id as string
          } has signed the terms and conditions location: ${
            window.location.href
          } Error: ${JSON.stringify(err)}`,
        });
        loggerService.error("err", err);
        throw err;
      }
    };

    try {
      let base64Img;
      if (signType === typeSign) {
        const node = document.getElementById("text-sign");
        base64Img = await domtoimage.toPng(node);
      } else {
        base64Img = await signComp.current.exportImage("png");
      }

      for (const template of TermsAndConditionAgreements) {
        if (
          template.name === "Credit Card on File Authorization Form" &&
          isCcConsentCaptured
        ) {
          await saveConsent(base64Img, template?.id);
        } else if (template.name !== "Credit Card on File Authorization Form") {
          await saveConsent(base64Img, template?.id);
        }
      }

      await updateConsumerEpisode({
        variables: {
          consumerEpisodeInput: {
            id: consumerEpisodeId,
            consumerId: serviceConsumer?.id,
            organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            isComplete: true,
            attributes: JSON.stringify({
              ccCaptured: isCcConsentCaptured,
              haveInsurance,
            }),
          },
        },
      });

      navigate("/success");
    } catch (error) {
      console.log(error);
    }
  };
  const clear = () => {
    signComp.current.undo();
  };

  const formSchema = Yup.object().shape({
    patientname: Yup.string().required("Required"),
    relationship: Yup.string().optional(),
    address: Yup.string().test("isRequired", "Required", (val) => {
      if (val?.length > 0 || haveInsurance) {
        return true;
      }
      return false;
    }),
    dob: Yup.date().test("isRequired", "Required", (val) => {
      if (val || consentFormType === 1) {
        return true;
      }
      return false;
    }),
  });

  return (
    <Formik
      initialValues={{
        patientname: `${newPatient?.firstName as string} ${
          newPatient?.lastName as string
        }`,
        relationship: "",
        address: "",
        dob: parseDOB(newPatient?.birthDate) || "",
      }}
      enableReinitialize
      validationSchema={formSchema}
      onSubmit={handleTermsSubmit}>
      {({ values, errors }) => (
        <Form className="flex flex-col gap-5 w-full">
          <FormikContext setConsentInputs={setConsentInputs} />
          <FormikInput
            name="patientname"
            placeholder="Enter First Name"
            type="text"
            label="Patient or Legal Representative Name"
            value={values.patientname}
          />

          <FormikInput
            name="relationship"
            placeholder="Relationship"
            type="text"
            label="Legal Representative’s Relationship to Patient (if applicable)"
            value={values.relationship}
          />

          {!haveInsurance && (
            <FormikInput
              name="address"
              placeholder="XYZ street, Y city, Z state, 123456"
              type="text"
              label="Enter address"
              value={values.address}
            />
          )}

          {consentFormType !== 1 && (
            <>
              <FormikInput
                name="dob"
                placeholder="MM/DD/YYYY"
                type="date"
                label="Date of Birth"
                component={DateSeparateInput}
                value={values.dob}
              />
            </>
          )}

          {/* Modal */}
          <div>
            <label className="text-sm font-semibold  text-gray-600" htmlFor="">
              Patient or Legal representative's signature
            </label>
            <div className="flex border mt-2 rounded-md items-end justify-center text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-xl bg-white  text-left  transition-all sm:my-8 sm:w-full sm:max-w-lg ">
                <div>
                  <div className="flex">
                    <button
                      type="button"
                      className={`flex justify-center w-1/2 items-center h-10 px-2 py-2 -mb-px text-center ${
                        signType === typeSign
                          ? "text-primary-blue border-primary-blue"
                          : "text-gray-700 border-transparent hover:border-gray-400"
                      } bg-transparent border-b-2 sm:px-4 -px-1 whitespace-nowrap cursor-base focus:outline-none `}
                      onClick={() => {
                        setSigned(false);
                        setSignType(typeSign);
                      }}>
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.09736 18.9997C1.05331 18.9997 1.00941 18.9934 0.966259 18.98C0.729154 18.9081 0.594439 18.6586 0.667212 18.4206L5.84025 1.3199C5.91302 1.08279 6.1617 0.948077 6.40062 1.02085C6.63863 1.09272 6.77244 1.3432 6.69967 1.58122L1.52586 18.6811C1.46753 18.8751 1.28966 19 1.09751 19L1.09736 18.9997Z"
                          fill="currentColor"
                          stroke="currentColor"
                          strokeWidth="0.5"
                        />
                        <path
                          d="M11.6982 18.9996C11.5052 18.9996 11.3273 18.8739 11.2681 18.6798L6.09425 1.57993C6.02238 1.34192 6.15619 1.09311 6.39329 1.01955C6.6322 0.946784 6.88104 1.0824 6.95367 1.3186L12.1275 18.4193C12.1993 18.6564 12.0664 18.907 11.8284 18.9787C11.7853 18.9931 11.7414 18.9993 11.6982 18.9993L11.6982 18.9996Z"
                          fill="currentColor"
                          stroke="currentColor"
                          strokeWidth="0.5"
                        />
                        <path
                          d="M16.0833 18.5858C15.433 18.5858 14.7981 18.4798 14.2574 18.24C13.3307 17.8277 12.8151 17.0589 12.8016 16.0728C12.7926 15.3165 13.0638 14.6798 13.6081 14.1805C15.2803 12.6439 19.0532 12.9941 19.2149 13.0094C19.461 13.0327 19.6423 13.2537 19.6182 13.4997C19.594 13.7449 19.3793 13.9298 19.127 13.9038C19.0928 13.8993 15.592 13.576 14.2153 14.8414C13.8615 15.1655 13.6934 15.5661 13.6997 16.0601C13.7087 16.7005 14.0105 17.146 14.623 17.419C15.9441 18.0055 18.1561 17.5618 19.0382 16.785C19.2249 16.6215 19.5079 16.6394 19.6722 16.8263C19.8365 17.0131 19.8177 17.2951 19.6318 17.4604C18.88 18.1195 17.4503 18.5857 16.0833 18.5857L16.0833 18.5858Z"
                          fill="currentColor"
                          stroke="currentColor"
                          strokeWidth="0.5"
                        />
                        <path
                          d="M9.97555 14.5426H2.79065C2.54272 14.5426 2.34155 14.3414 2.34155 14.0935C2.34155 13.8456 2.54272 13.6444 2.79065 13.6444H9.97555C10.2226 13.6444 10.4247 13.8456 10.4247 14.0935C10.4247 14.3414 10.2235 14.5426 9.97555 14.5426Z"
                          fill="currentColor"
                          stroke="currentColor"
                          strokeWidth="0.5"
                        />
                        <path
                          d="M19.4045 18.5839C19.1565 18.5839 18.9554 18.3827 18.9554 18.1348V11.426C18.6482 10.9581 18.2441 10.6733 17.7232 10.5576C16.0635 10.1876 13.8749 11.6541 13.8515 11.6677C13.6468 11.806 13.3674 11.7538 13.2291 11.5474C13.0899 11.3417 13.142 11.0632 13.3485 10.9258C13.4509 10.8558 15.8775 9.22928 17.9153 9.68019C18.7163 9.85806 19.3468 10.3241 19.7887 11.066C19.83 11.1351 19.8524 11.2151 19.8524 11.2959V18.1332C19.8533 18.3829 19.6521 18.5841 19.4042 18.5841L19.4045 18.5839Z"
                          fill="currentColor"
                          stroke="currentColor"
                          strokeWidth="0.5"
                        />
                      </svg>

                      <span className="mx-1 text-sm sm:text-base">Type</span>
                    </button>

                    <button
                      type="button"
                      className={`flex justify-center w-1/2 items-center h-10 px-2 py-2 -mb-px text-center ${
                        signType === drawSign
                          ? "text-primary-blue border-primary-blue"
                          : "text-gray-700 border-transparent hover:border-gray-400"
                      } bg-transparent border-b-2 sm:px-4 -px-1 whitespace-nowrap focus:outline-none`}
                      onClick={() => {
                        setSigned(false);
                        setSignType(drawSign);
                      }}>
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.6102 2.87968L13.3545 0.619783C12.5297 -0.206594 11.0819 -0.206594 10.274 0.619783L1.78977 9.1025C1.45312 9.43975 1.21745 9.87831 1.11648 10.3505L0.274791 14.5497C0.190598 14.9544 0.325283 15.3591 0.611436 15.6458C0.847102 15.8819 1.15018 16 1.46993 16C1.55412 16 1.63831 16 1.70559 15.9832L5.89719 15.14C6.36854 15.0388 6.78938 14.8195 7.14292 14.4655L15.6103 5.98274C16.0143 5.57797 16.25 5.02142 16.25 4.43123C16.25 3.84093 16.0143 3.30129 15.6102 2.87968ZM5.94767 13.2681C5.84668 13.3692 5.712 13.4536 5.56052 13.4704L2.05911 14.1787L2.76612 10.6709C2.79982 10.5192 2.86711 10.4011 2.96809 10.2831L9.02824 4.21195L11.9909 7.18003L5.94767 13.2681ZM14.415 4.78534L13.1862 6.0164L10.2234 3.04832L11.4522 1.81726C11.5532 1.7161 11.6879 1.66551 11.8058 1.66551C11.9237 1.66551 12.0583 1.7161 12.1594 1.81726L14.4151 4.07704C14.617 4.27938 14.617 4.5998 14.415 4.78534Z"
                          fill="currentColor"
                        />
                      </svg>

                      <span className="mx-1 text-sm sm:text-base">Draw</span>
                    </button>
                  </div>

                  {signType === typeSign && (
                    <div className="mt-6">
                      <textarea
                        data-clarity-mask="True"
                        onChange={(e) => {
                          e.target.value = e.target.value.trim();
                          e.target.value.length > 3
                            ? setSigned(true)
                            : setSigned(false);
                        }}
                        id="text-sign"
                        className="signature-font w-full px-4 pt-4 text-center bg-gray-100 border border-gray-200 rounded-lg focus:outline-none"></textarea>
                    </div>
                  )}
                  {signType === drawSign && (
                    <div className="relative mt-6 ">
                      <button
                        type="button"
                        className="absolute right-2 top-2 flex flex-col items-center"
                        onClick={() => clear()}>
                        <svg
                          width="33"
                          height="32"
                          viewBox="0 0 33 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <ellipse
                            cx="16.8993"
                            cy="16"
                            rx="16.0282"
                            ry="16"
                            fill="#3537E8"
                          />
                          <path
                            d="M16.4895 24.6226C14.8106 24.6262 13.1694 24.1259 11.7788 23.1866C11.6312 23.0872 11.5923 22.8871 11.6919 22.7398C11.7915 22.5924 11.9919 22.5536 12.1395 22.653C13.6808 23.6893 15.5428 24.1406 17.3885 23.925C19.2342 23.7096 20.9416 22.8418 22.202 21.4785C23.4622 20.1153 24.1918 18.3467 24.2589 16.493C24.3259 14.6393 23.7259 12.8229 22.5676 11.3724C21.4091 9.92199 19.7689 8.93343 17.9435 8.58564C16.1182 8.23795 14.2286 8.55402 12.6162 9.47662C12.4621 9.56542 12.265 9.51255 12.1761 9.35876C12.0871 9.20483 12.14 9.00811 12.2942 8.91931C13.7925 8.0606 15.5192 7.68254 17.2402 7.83619C18.9611 7.98998 20.593 8.66824 21.9146 9.77895C23.2365 10.8897 24.1842 12.3793 24.6294 14.0456C25.0746 15.7122 24.9957 17.4749 24.4036 19.0952C23.8114 20.7155 22.7346 22.1148 21.3189 23.1036C19.9032 24.0924 18.2172 24.6228 16.4895 24.6228L16.4895 24.6226Z"
                            fill="white"
                            stroke="white"
                          />
                          <path
                            d="M14.6274 10.669L9.88672 11.5264L11.512 7L14.6274 10.669Z"
                            fill="white"
                          />
                        </svg>

                        <span className="text-xs font-semibold text-[#0038AE]">
                          UNDO
                        </span>
                      </button>

                      <ReactSketchCanvas
                        ref={signComp}
                        data-clarity-mask="True"
                        onStroke={(e) => {
                          setSigned(true);
                        }}
                        onChange={(e) => {
                          if (e.length === 0) {
                            setSigned(false);
                          }
                        }}
                        canvasColor="#F2F2F2"
                        svgStyle={{
                          borderRadius: "20px",
                        }}
                        style={{
                          height: 300,
                        }}
                        strokeWidth={5}
                        strokeColor="black"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {(agreementCompletedCount < TermsAndConditionAgreements.length ||
            !signed) &&
            !haveInsurance && (
              <GuideBanner body="Please ensure that you have read and agreed to all consents" />
            )}

          {(agreementCompletedCount < TermsAndConditionAgreements.length ||
            !signed) &&
            haveInsurance && (
              <GuideBanner body="Please ensure that you have read and agreed to the consent" />
            )}

          <div className="flex items-center  gap-8 sticky bottom-0 sm:static bg-white w-full">
            <button
              onClick={() => {
                if (patientFlow === PatientFlowType.Yourself) {
                  setStep((prev) => prev - 1);
                } else {
                  setStep((prev) => prev - 1);
                }
              }}
              className="border font-bold border-gray-300 rounded-md px-8 py-4 "
              type="button">
              Back
            </button>
            <button
              type="submit"
              className="w-max bg-black px-16 py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400"
              disabled={
                agreementCompletedCount < TermsAndConditionAgreements.length ||
                !signed
              }>
              Complete
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TermsConsentForm;
