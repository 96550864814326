import { useLazyQuery, useQuery } from "@apollo/client";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET_AGREEMENT_TEMPLATE, GET_CONSUMER_BY_ID } from "../../apollo";
import { IServiceConsumer } from "../../interfaces";
import websocketLoggerService from "../../services/websocket-logger";
import useFormStore from "../../store/form/form.store";
import GuideBanner from "../shared/components/guide-banner.component";
import { PatientFlowType, ScopeType } from "../shared/config";
import { helper } from "../shared/utils";
import TermsAndConditionCard from "../terms-and-conditions/terms-and-conditions-card.component";
import PEDPayment from "./ped-payment.component";

interface CcConsentProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
  NextBtn: React.RefObject<HTMLButtonElement>;
  ccCapturedInitiate: boolean;
  setCcCapuredInitiate: (ccConsentCaptured: boolean) => void;
}

const stripePromise = loadStripe(
  "pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3"
);

const CcConsentForm: React.FC<CcConsentProps> = ({
  setStep,
  step,
  NextBtn,
  ccCapturedInitiate,
  setCcCapuredInitiate,
}) => {
  const [isCcCaptured, setCcCaptured] = useFormStore((state) => [
    state.isCcConsentCaptured,
    state.setCcConsentCaptured,
  ]);
  const location = useLocation();
  const [TermsAndConditionAgreements, setTermsAndConditionAgreements] =
    useState([]);
  const [patientFlow] = useFormStore((state) => [state.patientFlow]);
  const [currentTemplate, setCurrentTemplate] = useState(0);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const { data: queryResponse } = useQuery(GET_AGREEMENT_TEMPLATE, {
    variables: {
      agreementTemplateFilter: {
        organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
      },
    },
    context: {
      scope: ScopeType.Consumer,
    },
  });

  websocketLoggerService.sendMessage({
    eventType: "CC_CONSENT",
    eventSubType: "API Request: GET_AGREEMENT_TEMPLATE",
    eventData: `org id: ${parseInt(process.env.REACT_APP_ORGANIZATION_ID)}`,
  });

  const [getConsumerByIdPatient] = useLazyQuery<{
    getConsumerById: IServiceConsumer;
  }>(GET_CONSUMER_BY_ID, {
    context: {
      scope: ScopeType.Consumer,
    },
  });

  const [newPatientId] = useFormStore((state) => [state.newPatientID]);
  const [newPatient, setNewPatient] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: patientData } = await getConsumerByIdPatient({
          variables: {
            consumer: {
              consumerId: newPatientId,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            },
          },
          // disable cache for this
          fetchPolicy: "no-cache",
        });

        websocketLoggerService.sendMessage({
          eventType: "CC_CONSENT",
          eventSubType: "API Request: getConsumerByIdPatient success",
          eventData: `org id: ${parseInt(
            process.env.REACT_APP_ORGANIZATION_ID
          )}, patient id: ${newPatientId}`,
        });

        const response = helper.getPropertyValue<IServiceConsumer>(
          patientData,
          "consumerById"
        );
        setNewPatient(response);
      } catch (error) {
        console.log(error);
        websocketLoggerService.sendMessage({
          eventType: "CC_CONSENT",
          eventSubType: "ERROR: getConsumerByIdPatient failed",
          eventData: `error - ${JSON.stringify(error)}`,
        });
      }
    };
    if (newPatientId) {
      getData();
    }
  }, [getConsumerByIdPatient, newPatientId, setStep]);

  useEffect(() => {
    if (!queryResponse) return;
    const template = queryResponse?.getAgreementTemplate?.filter((data) => {
      return data.name === "Credit Card on File Authorization Form";
    });

    setTermsAndConditionAgreements(template);
  }, [location.pathname, queryResponse]);

  const handleIDisagreeBtn = () => {
    websocketLoggerService.sendMessage({
      eventType: "CC_CONSENT",
      eventSubType:
        "skipped consent or choose to Later over a phone call option",
      eventData: `Patient id - ${newPatientId}`,
    });
    setCcCaptured(false);
    setCcCapuredInitiate(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className="pb-24 px-4 w-3/4 max-lg:w-full">
      <div className="border border-gray-200 p-4 rounded-md mb-8 flex items-center justify-between gap-2">
        <div className="flex items-center gap-4 text-lg">
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="9.5" cy="10.2206" r="9.5" className="fill-lightBlue" />
            <path
              d="M4.46875 10.7233L7.66928 13.8527L14.5276 7.14685"
              stroke="white"
              strokeWidth="1.5"
            />
          </svg>

          <p className="flex justify-between capitalize ">
            {newPatient?.firstName} {newPatient?.lastName}
          </p>
        </div>
        <span className="text-gray-400">Patient</span>
      </div>

      <div
        className={`w-full bg-yellow-primary border border-[#FBBC05] px-6 py-3 mt-5 rounded-md`}>
        <p className="font-semibold ">Payment Information</p>
        <p className="text-sm">
          Please Note: Credit Card on file will be utilized for insured patient
          payment responsibility.
        </p>
      </div>

      <GuideBanner body="Disclaimer: Credit Card on file will be used for a maximum of 6 months from today for patient responsibility and shall on exceed $400." />

      {paymentSuccess ? (
        <div className="text-white bg-green-500 rounded-md flex p-2 items-center justify-center gap-5 my-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p>Payment Successful</p>
        </div>
      ) : (
        <PEDPayment setPaymentSuccess={setPaymentSuccess} />
      )}

      <h1 className="font-bold my-6 flex items-center justify-between">
        <p className="text-lg">Add Consent for Credit Card on File</p>
        <span className="text-red-500 text-md font-normal">Optional*</span>
      </h1>

      <p>Do you consent to Credit Card on file?</p>
      <form className="flex flex-col mb-2">
        <div className="flex items-center justify-start gap-2 mt-2">
          <input
            onChange={() => setCcCapuredInitiate(true)}
            type="radio"
            id="yes"
            checked={ccCapturedInitiate}
            name="patientFlow"
            value="yes"
          />
          <label htmlFor="online">Yes</label>
        </div>
        <div className="flex items-center justify-start gap-2 mt-2">
          <input
            onChange={handleIDisagreeBtn}
            type="radio"
            id="later on phone call"
            checked={!ccCapturedInitiate}
            name="patientFlow"
            value="later on phone call"
          />
          <label htmlFor="later on phone call">Later over a phone call </label>
        </div>
      </form>
      {ccCapturedInitiate && (
        <div>
          <div id={`template-1`} className={`overflow-y-auto w-full`}>
            <div>
              <section className="flex flex-col flex-1 w-full mx-auto items-center">
                <div className="w-full mb-5">
                  {TermsAndConditionAgreements?.length ? (
                    <TermsAndConditionCard
                      key={0}
                      index={0}
                      patient={newPatient}
                      currentTemplate={currentTemplate}
                      setCurrentTemplate={setCurrentTemplate}
                      agreementData={TermsAndConditionAgreements[0]}
                      ccCapture={true}
                    />
                  ) : null}
                </div>

                <button
                  onClick={() => {
                    handleIDisagreeBtn();
                    if (patientFlow === PatientFlowType.Yourself) {
                      setStep((prevStep) => prevStep + 1);
                    } else {
                      setStep((prevStep) => prevStep + 1);
                    }
                  }}
                  className="underline text-gray-500 text-md lg:self-start mb-2">
                  Skip this step
                </button>
              </section>
            </div>
          </div>

          <div className="flex items-center gap-4 sticky bottom-0 justify-start  w-full h-20 bg-white">
            <button
              onClick={() => {
                if (patientFlow === PatientFlowType.Yourself) {
                  setStep((prev) => prev - 1);
                } else {
                  setStep((prev) => prev - 1);
                }
              }}
              className="border font-bold border-gray-300 rounded-md px-6 py-4 my-4"
              type="button">
              Back
            </button>
            <button
              ref={NextBtn}
              onClick={() => {
                if (patientFlow === PatientFlowType.Yourself) {
                  setStep((prev) => prev + 1);
                } else {
                  setStep((prev) => prev + 1);
                }
              }}
              type="button"
              disabled={!isCcCaptured}
              className={`w-full ${
                !isCcCaptured ? "bg-gray-400" : "bg-black"
              } px-6 sm:px-12 text-sm sm:text-base py-4 my-4 text-white rounded-md font-bold`}>
              Next Up: Consent Sign
            </button>
          </div>
        </div>
      )}

      {!ccCapturedInitiate && (
        <div className="flex flex-col items-start h-80">
          <div
            style={{
              marginBottom: "30vh",
            }}></div>
          <div className="flex items-center gap-4 sticky bottom-0  justify-start  w-full h-20 bg-white">
            <button
              onClick={() => {
                if (patientFlow === PatientFlowType.Yourself) {
                  setStep((prev) => prev - 1);
                } else {
                  setStep((prev) => prev - 1);
                }
              }}
              className="border font-bold border-gray-300 rounded-md px-6 py-4 my-4"
              type="button">
              Back
            </button>
            <button
              ref={NextBtn}
              onClick={() => {
                if (patientFlow === PatientFlowType.Yourself) {
                  setStep((prev) => prev + 1);
                } else {
                  setStep((prev) => prev + 1);
                }
              }}
              type="button"
              className="w-full bg-black px-6 sm:px-12 text-sm sm:text-base py-4 my-4 text-white rounded-md font-bold">
              Next Up: Consent Sign
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CcConsentForm;
