import heic2any from "heic2any";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import pdf_icon from "../../../assets/images/pdf_icon.avif";

const UploadFile = ({ field, form, ...props }) => {
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const removeInputFile = () => {
    form.setFieldValue(field.name, "");
    setPreview(null);
    inputRef.current.value = "";
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const toBase64 = async (file) =>
    await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  function isHEIC(file: File): boolean {
    // check file extension since windows returns blank mime for heic
    const x = file.type
      ? file.type.split("image/").pop()
      : file.name.split(".").pop()?.toLowerCase();
    return x === "heic" || x === "heif";
  }

  async function convertHEIC(file: File): Promise<File> {
    return await new Promise(function (resolve) {
      if (!isHEIC(file)) return resolve(file);
      heic2any({
        blob: file,
        toType: "image/jpeg",
      }).then(function (convertedBlob: Blob) {
        const convertedFile = new File(
          [convertedBlob],
          file.name.substring(0, file.name.lastIndexOf(".")) + ".jpeg",
          {
            type: "image/jpeg",
            lastModified: Date.now(),
          }
        );
        resolve(convertedFile);
      });
    });
  }

  useEffect(() => {
    if (field.value) {
      if (field.value?.split(";")[0]?.split("/")[1] === "pdf") {
        const blob = b64toBlob(field.value.split(",")[1], "application/pdf");
        const blobUrl = URL.createObjectURL(blob);
        setPreview(blobUrl);
      } else {
        setPreview(field.value);
      }
    } else {
      setPreview(null);
    }
  }, [field.value]);

  return (
    <div
      className={`flex items-center justify-center w-full mt-2  ${
        preview ? "mb-5" : ""
      }`}>
      <div className={`h-52 w-full ${preview ? "" : "hidden"}`}>
        <div className=" flex h-44 items-center justify-between gap-5 rounded-md border-2 border-primary/60 border-dashed p-4">
          {field.value &&
          field.value?.split(";")[0]?.split("/")[1] === "pdf" ? (
            <>
              <object
                width="100%"
                height="120"
                data={preview}
                className="flex-1 max-lg:hidden"
                type="application/pdf"></object>
              <img
                src={pdf_icon}
                alt="PDF"
                className="hidden max-lg:block h-28"
              />
            </>
          ) : (
            <img
              className=" max-h-32 h-28 max-lg:h-auto max-w-[50%]"
              src={preview}
              data-clarity-mask="True"
            />
          )}
          <button
            onClick={removeInputFile}
            type="button"
            className="flex items-center gap-4 p-4 pr-0">
            <p className="max-lg:text-sm text-primary">Clear attachment</p>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              strokeWidth={0.5}
              className="stroke-primary"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.99984 17.3333C4.39734 17.3333 0.666504 13.6025 0.666504 8.99996C0.666504 4.39746 4.39734 0.666626 8.99984 0.666626C13.6023 0.666626 17.3332 4.39746 17.3332 8.99996C17.3332 13.6025 13.6023 17.3333 8.99984 17.3333ZM8.99984 15.6666C10.7679 15.6666 12.4636 14.9642 13.7139 13.714C14.9641 12.4638 15.6665 10.7681 15.6665 8.99996C15.6665 7.23185 14.9641 5.53616 13.7139 4.28591C12.4636 3.03567 10.7679 2.33329 8.99984 2.33329C7.23173 2.33329 5.53603 3.03567 4.28579 4.28591C3.03555 5.53616 2.33317 7.23185 2.33317 8.99996C2.33317 10.7681 3.03555 12.4638 4.28579 13.714C5.53603 14.9642 7.23173 15.6666 8.99984 15.6666ZM8.99984 7.82163L11.3565 5.46413L12.5357 6.64329L10.1782 8.99996L12.5357 11.3566L11.3565 12.5358L8.99984 10.1783L6.64317 12.5358L5.464 11.3566L7.8215 8.99996L5.464 6.64329L6.64317 5.46413L8.99984 7.82163Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </div>

      <label
        htmlFor={field.name}
        className={`flex flex-col items-center justify-center w-full h-44 border-2 border-dashed rounded-lg cursor-pointer   hover:bg-gray-50 ${
          preview ? "hidden" : ""
        } ${loading ? "border-gray-400" : "border-primary/60"}`}>
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.5 17.5H18.5V19.5H0.5V17.5ZM10.5 4.328V15.5H8.5V4.328L2.429 10.4L1.015 8.986L9.5 0.5L17.985 8.985L16.571 10.399L10.5 4.33V4.328Z"
              fill={loading ? "gray" : "#3537E8"}
            />
          </svg>
          <p
            className={`mb-2 mt-5 ${
              loading ? "text-gray-400" : "text-primary"
            }`}>
            <span className="font-semibold ">
              {loading ? "Uploading" : "Click to upload"}
            </span>
          </p>
          <p className="text-gray-500">
            Max file size:&nbsp; <span className="font-semibold">5MB</span>
          </p>
          <p className="text-gray-500">
            Supported file types:&nbsp;
            <span className="font-semibold">JPG, PNG, PDF</span>
          </p>
        </div>
        <input
          id={field.name}
          ref={inputRef}
          name={field.name}
          data-clarity-mask="True"
          disabled={loading}
          onChange={async (event) => {
            event.preventDefault();
            setLoading(true);
            if (event.currentTarget.files[0].size > 5242880) {
              toast.error("File too large.");
              inputRef.current.value = "";
              setLoading(false);
              return;
            }

            let file = event.currentTarget.files[0];

            if (
              event.currentTarget.files[0].type === "image/heic" ||
              event.currentTarget.files[0].type === "image/heif"
            ) {
              file = await convertHEIC(event.currentTarget.files[0]);
            }

            const uplaodedImgToBase64 = await toBase64(file);
            form.setFieldValue(field.name, uplaodedImgToBase64);
            // set error to null
            form.setFieldError(field.name, "");
            setLoading(false);
          }}
          type="file"
          className="hidden"
          accept="capture=camera, image/jpg, image/jpeg, image/png, .heic, .heif, application/pdf"
        />
      </label>
    </div>
  );
};

export default UploadFile;
