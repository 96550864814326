import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./app.component";
import ConsumerLogin from "./auth/auth0.component";
import GuestLogin from "./auth/guest-login.component";
import Logout from "./auth/logout.component";
import ProviderAppointmentSuccess from "./providers/provider-appointment-success.component";
import ProviderBookAppointment from "./providers/provider-book-appointment.component";
import ProviderResult from "./providers/provider-result.component";
import ScheduleSessionHome from "./schedule-session/schedule-session-home.component";
import { NotFound, PrivateRoute, Unauthorized } from "./shared/components";
import Verifyemail from "./shared/components/verify-email.component";
import StepFlow from "./step-flow/step-flow";
import StepSuccess from "./step-flow/step-success";
import Room from "./tele/rooms/tele-room.component";
import AppointmentSuccess from "./tele/tele-appointment-success.component";
import Appointment from "./tele/tele-appointment.component";
import TeleProvider from "./tele/tele-provider.component";

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="login" element={<ConsumerLogin />} />
          <Route path="guest" element={<GuestLogin />} />
          <Route path="logout" element={<Logout />} />
          <Route element={<PrivateRoute />}>
            <Route path="/add-patient-details" element={<StepFlow />} />
            <Route path="/appointment/:id" element={<TeleProvider />}>
              <Route index element={<Appointment />} />
              <Route path="consult-now" element={<Room />} />
            </Route>
            <Route
              path="appointment-success"
              element={<AppointmentSuccess />}
            />
            <Route path="consent/patient-onboarding-1" element={<StepFlow />} />
            <Route path="consent/patient-onboarding-3" element={<StepFlow />} />
            <Route path="consent/patient-onboarding-4" element={<StepFlow />} />
            <Route path="consent/patient-onboarding-5" element={<StepFlow />} />
            <Route path="consent/patient-onboarding-6" element={<StepFlow />} />
            <Route path="consent/patient-onboarding-7" element={<StepFlow />} />
            <Route path="consent/patient-onboarding-8" element={<StepFlow />} />

            <Route path="success" element={<StepSuccess />} />
          </Route>

          {/* Schedule session */}
          <Route path="schedule" element={<ScheduleSessionHome />} />
          {/* Provider result */}
          <Route path="providers" element={<ProviderResult />} />
          <Route
            path="book-appointment"
            element={<ProviderBookAppointment />}
          />
          <Route
            path="book-appointment-success"
            element={<ProviderAppointmentSuccess />}
          />

          <Route path="verifyemail" element={<Verifyemail />} />
        </Route>
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
