import {
  selectIsConnectedToRoom,
  selectLocalPeer,
  selectLocalPeerRole,
  selectPeersByRole,
  useHMSActions,
  useHMSNotifications,
  useHMSStore,
} from "@100mslive/react-sdk";
import React from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { OTRoleType } from "../../../interfaces";
import { useConsumerStore } from "../../../store";
import { useServiceConsumer } from "../../shared/hooks";
import MeetingRoom from "./tele-meeting-room.component";
import WaitingRoom from "./tele-waiting-room.component";

function Room() {
  const serviceConsumer = useServiceConsumer();
  const appointmentProps = useConsumerStore((state) => state.appointmentProps);

  const { id } = useParams();

  const state = useLocation().state;
  // 100ms actions and selectors
  const hmsActions = useHMSActions(),
    navigate = useNavigate(),
    localPeer = useHMSStore(selectLocalPeer),
    isConnected = useHMSStore(selectIsConnectedToRoom),
    { name: localPeerRole } = useHMSStore(selectLocalPeerRole) || {},
    waitingPeers = useHMSStore(selectPeersByRole(OTRoleType.Waiting)),
    hostPeer = useHMSStore(selectPeersByRole(OTRoleType.ServiceProvider));

  const notification = useHMSNotifications();

  React.useEffect(() => {
    if (!notification) {
      return;
    }
    switch (notification.message) {
      case "Removed from room ":
        navigate("../success");
        break;
    }
  }, [navigate, notification]);

  React.useEffect(() => {
    window.onunload = () => {
      if (isConnected) {
        hmsActions.leave();
      }
    };
  }, [hmsActions, isConnected]);

  const getPeersBeforeMe = () => {
    // ToDo: add logic to compute peers added before me
    return waitingPeers.length;
  };

  return (
    <>
      <section className="flex flex-col flex-1 w-full max-w-3xl mx-auto xl:px-12">
        {!isConnected ? (
          <Navigate to={`/appointment/${id}`} />
        ) : localPeerRole === OTRoleType.Waiting ? (
          <WaitingRoom
            noOfPeers={getPeersBeforeMe()}
            appointmentDetails={state?.appointmentDetails}
          />
        ) : (
          <MeetingRoom host={hostPeer?.[0]} patient={localPeer} />
        )}
      </section>
    </>
  );
}

export default Room;
