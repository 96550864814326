import {
  selectMessagesByPeerID,
  selectPeers,
  useHMSStore,
} from "@100mslive/react-sdk";
import { useEffect, useState } from "react";
import { OTRoleType } from "../../../interfaces";
import dateService from "../../../services/date.service";
import docProfile from "../../../assets/images/doc-profile.png";
import galleryImage1 from "../../../assets/images/img1.png";
import galleryImage2 from "../../../assets/images/img2.png";
import galleryImage3 from "../../../assets/images/img3.png";

const WaitingRoom = ({ noOfPeers, appointmentDetails }) => {
  const estimatedDateTime = dateService.addMinutes((noOfPeers || 1) * 10);
  const allPeers = useHMSStore(selectPeers);
  const [chats, setChats] = useState([]);
  let hostId: string;
  allPeers.forEach((peer) => {
    if (peer.roleName === OTRoleType.ServiceProvider) {
      hostId = peer.id;
    }
  });
  const peerChatMessages = useHMSStore(selectMessagesByPeerID(hostId));

  useEffect(() => {
    if (!peerChatMessages) {
      return;
    }
    setChats(peerChatMessages);
  }, [peerChatMessages]);

  return (
    <div className="px-4">
      <div className="flex items-center gap-2 ml-4  ">
        <img src={docProfile} alt="" className="w-14 h-14  rounded-full" />
        <div className="flex flex-col p-4 ">
          <p className="flex justify-between font-bold ">
            Dr.{" "}
            {appointmentDetails?.appointmentType?.serviceProvider?.firstName}{" "}
            {appointmentDetails?.appointmentType?.serviceProvider?.lastName}
          </p>
          <div className="bg-red-50 text-red-500 px-3 mt-2 w-max py-1">
            In Consultation
          </div>
        </div>
      </div>
      <div className="flex gap-4  mt-4">
        <div className="flex gap-4  rounded border border-lightBlue items-center bg-white w-full px-2 py-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="37"
            fill="none"
            className="w-5 h-5"
            viewBox="0 0 37 37">
            <g
              stroke="#3537E8"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              clipPath="url(#clip0_5913_39051)">
              <path d="M26.023 32v-3a6 6 0 00-6-6h-12a6 6 0 00-6 6v3M14.023 17a6 6 0 100-12 6 6 0 000 12zM35.023 31.998v-3a6 6 0 00-4.5-5.805M24.523 5.193a6 6 0 010 11.625"></path>
            </g>
            <defs>
              <clipPath id="clip0_5913_39051">
                <path
                  fill="#fff"
                  d="M0 0H36V36H0z"
                  transform="translate(.523 .5)"></path>
              </clipPath>
            </defs>
          </svg>
          <div>
            <div className="text-sm text-primary">{noOfPeers} Patient </div>
            <div className="text-sm text-gray-light">before you</div>
          </div>
        </div>
        <div className="flex gap-4  rounded border border-Green items-center bg-white w-full px-2 py-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            fill="none"
            className="w-5 h-5"
            viewBox="0 0 19 19">
            <g clipPath="url(#clip0_5819_12301)">
              <path
                fill="#34A853"
                d="M9.5 1.583A7.917 7.917 0 111.585 9.5h1.583a6.334 6.334 0 101.097-3.563h2.07v1.584h-4.75V2.77h1.583V4.75a7.901 7.901 0 016.334-3.167zm.792 3.959v3.63l2.568 2.567-1.12 1.12-3.031-3.032V5.542h1.583z"></path>
            </g>
            <defs>
              <clipPath id="clip0_5819_12301">
                <path fill="#fff" d="M0 0H19V19H0z"></path>
              </clipPath>
            </defs>
          </svg>
          <div>
            <div className="text-sm text-Green">
              {estimatedDateTime.format("LTS")}{" "}
            </div>
            <div className="text-sm text-gray-light">Meeting starting</div>
          </div>
        </div>
      </div>
      <div className="flex items-center bg-bgLightBlue rounded-[6px] mt-4 px-4 py-4 gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="25"
          fill="none"
          viewBox="0 0 26 25">
          <path
            fill="#09121F"
            d="M5.792 20.513L0 25V1.282C0 .942.137.616.38.376A1.31 1.31 0 011.3 0h23.4c.345 0 .675.135.92.376.243.24.38.566.38.906v17.949c0 .34-.137.666-.38.906a1.31 1.31 0 01-.92.376H5.791zm-.9-2.564H23.4V2.564H2.6v17.16l2.292-1.775zM11.7 8.974h2.6v2.565h-2.6V8.974zm-5.2 0h2.6v2.565H6.5V8.974zm10.4 0h2.6v2.565h-2.6V8.974z"></path>
        </svg>
        <div>Messages from ABC Health</div>
        <div>
          {chats.map((chat, index) => {
            return (
              <p
                className="break-all bg-chat-yellow p-2 rounded-lg text-left"
                key={index}>
                {chat.message}
              </p>
            );
          })}
        </div>
      </div>
      <div className="border-b mt-4 pb-2 text-black text-xl font-semibold border-primary">
        Waiting room
      </div>
      <div className="py-4 border-b border-primary pb-20">
        <span className="text-lg font-semibold">ABC welcomes you.</span>
        <div className="mt-3">
          <div className="font-semibold">
            Hi there! Dr. Michelle will connect with you soon.
          </div>
          <div className="text-sm text-gray-gray mt-6">
            Thank you for your patience we look forward to serving you.
          </div>
        </div>
      </div>
      <div className="pb-8 border-b border-primary">
        <div className=" mt-4 pb-2 text-black text-xl font-semibold border-primary">
          Gallery
        </div>
        <div className="flex mt-4 gap-4 overflow-y-scroll">
          <img src={galleryImage1} alt="" className="w-36" />
          <img src={galleryImage2} alt="" className="w-36" />
          <img src={galleryImage3} alt="" className="w-36" />
        </div>
        <div className="flex items-center mt-3 gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24">
            <g clipPath="url(#clip0_5819_12267)">
              <path
                fill="#09121F"
                d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414L7.828 11z"></path>
            </g>
            <defs>
              <clipPath id="clip0_5819_12267">
                <path
                  fill="#fff"
                  d="M0 0H24V24H0z"
                  transform="rotate(-90 12 12)"></path>
              </clipPath>
            </defs>
          </svg>
          <div className="text-sm text-gray-gray">1/3</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24">
            <g clipPath="url(#clip0_5819_12266)">
              <path
                fill="#09121F"
                d="M16.172 13H4v-2h12.172l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13z"></path>
            </g>
            <defs>
              <clipPath id="clip0_5819_12266">
                <path
                  fill="#fff"
                  d="M0 0H24V24H0z"
                  transform="rotate(90 12 12)"></path>
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div className="mt-2 mb-10">
        <span className="text-lg font-semibold ">
          Check out these links while you wait
        </span>
        <div className="mt-4 flex flex-col gap-3 ">
          <a
            href="https://www.webmd.com/first-aid"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-between pb-4 text-primary border-b border-primary">
            <div>https://www.webmd.com/first-aid</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16">
              <g clipPath="url(#clip0_5819_12263)">
                <path
                  fill="#000"
                  d="M10.669 6.276L4.93 12.014l-.943-.943 5.738-5.738H4.669V4h7.333v7.333H10.67V6.276z"></path>
              </g>
              <defs>
                <clipPath id="clip0_5819_12263">
                  <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
              </defs>
            </svg>
          </a>
          <a
            href="https://www.webmd.com/digestive"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-between pb-4 text-primary border-b border-primary">
            <div>https://www.webmd.com/digestive</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16">
              <g clipPath="url(#clip0_5819_12263)">
                <path
                  fill="#000"
                  d="M10.669 6.276L4.93 12.014l-.943-.943 5.738-5.738H4.669V4h7.333v7.333H10.67V6.276z"></path>
              </g>
              <defs>
                <clipPath id="clip0_5819_12263">
                  <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
              </defs>
            </svg>
          </a>
          <a
            href="https://www.webmd.com/lung"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-between pb-4 text-primary border-b border-primary">
            <div>https://www.webmd.com/lung</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16">
              <g clipPath="url(#clip0_5819_12263)">
                <path
                  fill="#000"
                  d="M10.669 6.276L4.93 12.014l-.943-.943 5.738-5.738H4.669V4h7.333v7.333H10.67V6.276z"></path>
              </g>
              <defs>
                <clipPath id="clip0_5819_12263">
                  <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default WaitingRoom;
