import { useLazyQuery } from "@apollo/client";
import { Form, Formik, FormikErrors } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { GET_EVENT_TEMPLATE_REGIONS } from "../../../apollo/event/event.queries";
import { IStateResponse } from "../../../interfaces";
import { ScheduleType } from "../../../interfaces/schedule.interface";
import { loggerService, networkService } from "../../../services";
import FormikInput from "../../shared/components/formik-input.component";
import FormikReactSelect from "../../shared/components/formik-select.component";
import GuideBanner from "../../shared/components/guide-banner.component";

interface scheduleSessionLocationProps {
  value: any;
  setCurrentOverlay: React.Dispatch<React.SetStateAction<string>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<{
    visitType: string;
    location: string;
    preferredDate: string;
    specialty: string;
    visitReason: string;
  }>>;
}

const ScheduleSessionLocation: React.FC<scheduleSessionLocationProps> = ({
  value,
  setCurrentOverlay,
  setFieldValue,
}) => {
  const [scheduleType, setScheduleType] = useState(ScheduleType.InPerson);
  const [states, setStates] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  const handleScheduleTypeChange = (type: ScheduleType) => {
    setScheduleType(type);
  };

  // DO not remove its for provider specific states only
  // useEffect(() => {
  //   try {
  //     const fetchStates = async () => {
  //       const data = await networkService.get<IStateResponse>(
  //         `${process.env.REACT_APP_LOCATION_SERVICE_URL}/states/United%20States`
  //       );
  //       setStates(data.states);
  //     };

  //     fetchStates();
  //   } catch (error) {
  //     loggerService.error("Address", error);
  //   }
  // }, []);

  useEffect(() => {
    try {
      const fetchStates = async () => {
        const data = await networkService.get<IStateResponse>(
          `${process.env.REACT_APP_LOCATION_SERVICE_URL}/states/United%20States`
        );

        const updatedStates = data.states.map((state) => {
          return {
            label: state,
            value: state,
          };
        });
        setStates(updatedStates);
      };
      // fetchCountries();
      fetchStates();
    } catch (error) {
      loggerService.error("Address", error);
    }
  }, [setStates]);

  const [getTemplateRegions, { data: regionsData }] = useLazyQuery<{
    regionsByAppointmentTypeTemplateId: {
      regions: string[];
    };
  }>(GET_EVENT_TEMPLATE_REGIONS, {
    onCompleted: (data) => {
      const regions = data.regionsByAppointmentTypeTemplateId.regions,
        defaultRegion =
          Array.isArray(regions) && !!regions.length ? regions[0] : "";
    },
  });

  useEffect(() => {
    getTemplateRegions({
      variables: {
        appointmentTypeTemplateId: 192,
      },
    });
  }, [getTemplateRegions]);

  const scheduleSessionLocationSchema = Yup.object({
    state: Yup.string().required("Please select a state"),
    pincode: Yup.string().test("Required", "Required", (val) => {
      if (val?.length > 0 || scheduleType === ScheduleType.Virtual) {
        return true;
      }
      return false;
    }),
  });
  return (
    <div className="mt-4">
      {/* Heading */}
      <p className="items-center justify-center gap-3 text-primary hidden max-lg:flex">
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_5913_49695)">
            <path
              d="M9 16.1749L12.7125 12.4624C13.4467 11.7282 13.9466 10.7927 14.1492 9.77435C14.3517 8.75596 14.2477 7.70039 13.8503 6.74111C13.4529 5.78183 12.78 4.96192 11.9167 4.38507C11.0533 3.80821 10.0383 3.50032 9 3.50032C7.96167 3.50032 6.94666 3.80821 6.08332 4.38507C5.21997 4.96192 4.54706 5.78183 4.14969 6.74111C3.75231 7.70039 3.64831 8.75596 3.85084 9.77435C4.05337 10.7927 4.55333 11.7282 5.2875 12.4624L9 16.1749ZM9 18.2959L4.227 13.5229C3.28301 12.5789 2.64014 11.3762 2.3797 10.0668C2.11925 8.75746 2.25293 7.40026 2.76382 6.16687C3.27472 4.93347 4.13988 3.87927 5.24991 3.13757C6.35994 2.39588 7.66498 2 9 2C10.335 2 11.6401 2.39588 12.7501 3.13757C13.8601 3.87927 14.7253 4.93347 15.2362 6.16687C15.7471 7.40026 15.8808 8.75746 15.6203 10.0668C15.3599 11.3762 14.717 12.5789 13.773 13.5229L9 18.2959ZM9 10.2499C9.39783 10.2499 9.77936 10.0919 10.0607 9.8106C10.342 9.5293 10.5 9.14777 10.5 8.74994C10.5 8.35212 10.342 7.97059 10.0607 7.68928C9.77936 7.40798 9.39783 7.24994 9 7.24994C8.60218 7.24994 8.22065 7.40798 7.93934 7.68928C7.65804 7.97059 7.5 8.35212 7.5 8.74994C7.5 9.14777 7.65804 9.5293 7.93934 9.8106C8.22065 10.0919 8.60218 10.2499 9 10.2499ZM9 11.7499C8.20435 11.7499 7.44129 11.4339 6.87868 10.8713C6.31607 10.3087 6 9.54559 6 8.74994C6 7.95429 6.31607 7.19123 6.87868 6.62862C7.44129 6.06601 8.20435 5.74994 9 5.74994C9.79565 5.74994 10.5587 6.06601 11.1213 6.62862C11.6839 7.19123 12 7.95429 12 8.74994C12 9.54559 11.6839 10.3087 11.1213 10.8713C10.5587 11.4339 9.79565 11.7499 9 11.7499Z"
              fill="#3537E8"
            />
          </g>
          <defs>
            <clipPath id="clip0_5913_49695">
              <rect
                width="18"
                height="18"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
        Patient’s Location
      </p>
      <p className="max-lg:text-center font-semibold mt-5 mx-4">
        Where are you scheduling the appointment from?
      </p>

      {/* Schedule Type button */}
      <div className="flex gap-4 items-center justify-between mt-5 bg-bgLightBlue p-3 py-1 rounded-full mx-4 max-lg:mx-aut0 max-w-sm max-lg:w-4/5">
        <button
          type="button"
          onClick={() => handleScheduleTypeChange(ScheduleType.InPerson)}
          className={`text-sm p-2 py-2 w-full ${
            scheduleType === ScheduleType.InPerson
              ? "border-[1.4px] shadow-lg border-primary bg-white text-primary rounded-full font-semibold"
              : ""
          }`}>
          In Person
        </button>
        <button
          type="button"
          onClick={() => handleScheduleTypeChange(ScheduleType.Virtual)}
          className={`text-sm p-2 py-2 w-full ${
            scheduleType === ScheduleType.Virtual
              ? "border-[1.4px] shadow-lg border-primary bg-white text-primary rounded-full font-semibold"
              : ""
          }`}>
          {ScheduleType.Virtual}
        </button>
      </div>

      {/* Form */}

      <Formik
        initialValues={{
          state: value.patientLocation.split(" - ")[0],
          pincode: value.patientLocation.split(" - ")[1] || "",
        }}
        validationSchema={scheduleSessionLocationSchema}
        onSubmit={(values) => {
          setCurrentOverlay("");
          if (values.state && values.pincode)
            setFieldValue(
              "patientLocation",
              `${values.state as string} - ${(values?.pincode as string) || ""}`
            );
          else setFieldValue("patientLocation", values.state);
        }}>
        {({ values }) => (
          <Form className="mt-4 flex flex-col gap-4 px-4">
            <FormikReactSelect name="state" isMulti={false} options={states} />

            <FormikInput
              name="pincode"
              placeholder={`State Pincode ${
                scheduleType === ScheduleType.Virtual ? " (Optional)" : ""
              }`}
              type="text"
              onInput={(e) =>
                (e.target.value = e.target.value.replace(/[^\d+]/g, ""))
              }
              pattern="[0-9]*"
              maxLength={6}
              label={`Enter Pincode`}
              value={values.pincode}
            />
            <GuideBanner body="For virtual appointments please select the patient location. For in person appointments please enter your zip code to find nearest locations with appointment availability." />

            <button
              type="submit"
              className="w-full bg-black px-20 max-sm:px-4 py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16">
              Done
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ScheduleSessionLocation;
