import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import HiCreditCard from "@heroicons/react/solid/CreditCardIcon";
import {
  CONSUMER_PAYMENT_SOURCES,
  GET_STRIPE_INTENT_SECRET,
} from "../../apollo";
import { loggerService } from "../../services";
import FieldError from "../shared/components/field-error.component";
import { ScopeType } from "../shared/config";
import { useServiceConsumer } from "../shared/hooks";

const STRIPE_PROMISE = loadStripe(process.env.REACT_APP_STRIPE_PK);

const PaymentForm: React.FC<PEDPaymentProps> = ({ setPaymentSuccess }) => {
  const [paymentMethod, setPaymentMethod] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const [fieldVals, setFieldVals] = useState({
    paymentSources: [],
  });

  const organizationId = 105;

  useQuery(CONSUMER_PAYMENT_SOURCES, {
    variables: {
      organizationId,
    },
    context: {
      scope: ScopeType.Consumer,
    },
    onCompleted: ({ getCustomerPaymentSources }) => {
      setFieldVals({ paymentSources: getCustomerPaymentSources });
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    try {
      const { error } = await stripe.confirmSetup({
        elements,
        redirect: "if_required",
      });

      if (error) {
        console.log(error);
        setPaymentSuccess(false);
        setErrorMessage(error.message);
      } else {
        console.log("move to next step");
        setPaymentSuccess(true);
      }
    } catch (err) {
      loggerService.error(err);
    }
  };

  const [checked, setChecked] = React.useState(true);

  const handleChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const clearSelection = () => {
    setPaymentMethod("");
  };

  const handleSavedCardSubmit = () => {
    if (paymentMethod) {
      // navigate(nextUrl);
    }
  };

  return (
    <>
      {fieldVals.paymentSources.length > 0 && (
        <div className="mb-4 mt-4">
          <h1 className="text-2xl font-medium tracking-wide text-gray-800 md:text-3xl mb-4">
            Saved Cards
          </h1>
          <form className="grid gap-y-4 mb-4">
            {fieldVals.paymentSources.map(
              ({ card: { last4, exp_month, exp_year } }, index) => {
                return (
                  <div
                    key={index}
                    className="border-2 border-solid	border-primary flex items-center rounded-lg p-4 gap-x-4">
                    <input
                      type="radio"
                      name="saved-card"
                      id={`card-${index}`}
                      className="focus:border-transparent focus:ring-0"
                      value={`${last4 as string}${index}`}
                      checked={paymentMethod === `${last4 as string}${index}`}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor={`card-${index}`}
                      className="flex justify-between items-center grow gap-x-4">
                      <HiCreditCard className="w-6" />
                      <p className="justify-self-start mr-auto">
                        **** **** **** {last4}
                      </p>
                      <p>
                        <span className="font-bold">Exp: </span> {exp_month}/
                        {exp_year}
                      </p>
                    </label>
                  </div>
                );
              }
            )}
          </form>
          <button
            onClick={clearSelection}
            className="bg-primary text-white p-2 rounded-lg">
            Clear Selection
          </button>
        </div>
      )}
      {paymentMethod === "" ? (
        <form className="flex flex-col flex-1 mt-8" onSubmit={handleSubmit}>
          <div className="flex-1 ">
            <h1 className="text-2xl font-medium tracking-wide text-gray-800 md:text-3xl">
              Credit Card on File
            </h1>
            <p className="text-md text-gray-500">
              For Deductible or Co-insurance , Co- Pay if Applicable
            </p>
            <PaymentElement className="mt-6 sm:mt-8" />
            {errorMessage && <FieldError message={errorMessage} />}
          </div>
          <label className="flex items-center mt-5 space-x-3">
            <input
              type="checkbox"
              checked={checked}
              className="p-2 border-gray-200 text-primary"
              onChange={(e) => {
                setChecked(!checked);
              }}
            />
            <span>
              I authorize Care2U to charge my credit card above for agreed upon
              purchases. I understand that my information will be saved to file
              for future transactions on my account. This authorization will
              remain valid until unless it has been revoked explicitly.
            </span>
          </label>
          <div className="mt-6 sm:mt-8">
            <button
              type="button"
              // onClick={() => navigate(nextUrl)}
              className="block w-full text-lg font-medium text-center focus:outline-none text-care2u">
              Skip
            </button>
          </div>

          <button
            type="submit"
            disabled={!stripe || !checked}
            className="block w-full px-12 py-3 mt-2 text-base font-medium tracking-wide text-black border border-black transition-colors duration-300 rounded bg-care2u hover:bg-care2u/80  disabled:bg-gray-300">
            Next
          </button>
        </form>
      ) : (
        <div>
          <label className="flex items-center mt-5 space-x-3 mb-5">
            <input
              type="checkbox"
              checked={checked}
              className="p-2 border-gray-200 text-primary"
              onChange={(e) => {
                setChecked(!checked);
              }}
            />
            <span>
              I authorize Care2U to charge my credit card above for agreed upon
              purchases. I understand that my information will be saved to file
              for future transactions on my account. This authorization will
              remain valid until unless it has been revoked explicitly.
            </span>
          </label>
          <button
            type="submit"
            onClick={handleSavedCardSubmit}
            disabled={!checked}
            className="block w-full px-12 py-3 mt-2 text-base font-medium tracking-wide text-black border border-black  transition-colors duration-300 rounded bg-care2u hover:bg-care2u/80  disabled:bg-gray-300">
            Next
          </button>
        </div>
      )}
    </>
  );
};

interface PEDPaymentProps {
  setPaymentSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const PEDPayment: React.FC<PEDPaymentProps> = ({ setPaymentSuccess }) => {
  const serviceConsumer = useServiceConsumer(),
    consumerId = serviceConsumer?.id || 0,
    [getStripeIntentSecret, { data }] = useMutation<{
      addPaymentIntent: { clientSecret: string };
    }>(GET_STRIPE_INTENT_SECRET);

  const organizationId = 105;

  React.useEffect(() => {
    if (!consumerId) return;
    const queryIntentSecret = async () => {
      try {
        await getStripeIntentSecret({
          variables: {
            organizationId,
          },
          context: {
            scope: ScopeType.Consumer,
          },
        });
      } catch (err) {
        console.log(err);

        loggerService.error(err);
      }
    };

    queryIntentSecret();
  }, [consumerId, getStripeIntentSecret]);

  if (!data?.addPaymentIntent?.clientSecret) return null;
  return (
    <section className="flex flex-col flex-1 w-full max-w-3xl px-6 mx-auto xl:px-12">
      {/* <PEDSteps active="4" /> */}
      <Elements
        stripe={STRIPE_PROMISE}
        options={{
          clientSecret: data.addPaymentIntent.clientSecret,
        }}>
        <PaymentForm setPaymentSuccess={setPaymentSuccess} />
      </Elements>
    </section>
  );
};

export default PEDPayment;
