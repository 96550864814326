import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { UPDATE_CONSUMER_APPOINTMENT } from "../../apollo";
import GuideImg from "../../assets/images/guide.png";
import FormikInput from "../shared/components/formik-input.component";
import { regexConstants } from "../shared/config";

const ProviderBookAppointment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const [updateConsumerAppointment] = useMutation(
    UPDATE_CONSUMER_APPOINTMENT,
    {}
  );

  const schema = Yup.object().shape({
    mobileNumber: Yup.string()
      .required("Patient mobile phone number is required.")
      .matches(
        regexConstants.phoneRegExp,
        "Please enter a valid Patient Mobile Phone Number"
      ),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please enter a valid email address"),
  });

  const initialValues = {
    mobileNumber: "",
    email: "",
  };

  const bookAppointment = async (values) => {
    const mobileNumber = `+1${values.mobileNumber as string}`;

    try {
      await updateConsumerAppointment({
        variables: {
          appointment: {
            appointmentTypeId: parseInt(state.selectedSlot.appointmentTypeId),
            date: state.selectedSlot.date,
            startTime: state.selectedSlot.startTime,
            endTime: state.selectedSlot.endTime,
            organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            note: "",
            // organizationName: values.organizationName,
            consumer: {
              firstName: " ",
              lastName: " ",
              email: values.email,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
              mobileNumber,
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              attributes: JSON.stringify({
                organizationName: "",
              }),
            },
          },
        },
      });
      navigate("/book-appointment-success", {
        state: {
          selectedSlot: state.selectedSlot,
          selectedProvider: state.selectedProvider,
        },
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="h-screen bg-gradient-to-t from-[#6993FF]/30 to-white max-lg:bg-none">
      <div className="hidden max-lg:flex gap-2 justify-center py-3 text-sm">
        <div className="flex flex-col gap-1 justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6 fill-green-400">
            <path
              fillRule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
              clipRule="evenodd"
            />
          </svg>
          <div className="w-full h-1 bg-gradient-to-l from-green-500 via-blue-700 to-violet-700"></div>
          <div>Schedule Appointment</div>
        </div>
        <div className="flex flex-col gap-1 justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6 fill-gray-300">
            <path
              fillRule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
              clipRule="evenodd"
            />
          </svg>
          <div className="w-full h-1 bg-gray-300"></div>
          <div>Add Patient Details</div>
        </div>
      </div>

      <div className="mx-auto w-1/2 max-lg:mx-auto max-lg:w-full mt-20 max-lg:mt-0">
        <div className="text-center mt-4 text-2xl flex justify-center gap-2 items-center flex-col w-full">
          <img
            src={GuideImg}
            alt=""
            className="w-24 object-cover h-24 ml-2  rounded-full"
          />
          <div className="px-4">
            Great! <br /> Proceed to book your appointment
          </div>
        </div>
        <div className="mt-10 flex justify-center max-lg:pb-40 w-full bg-white max-lg:bg-transparent rounded-lg shadow-xl max-lg:shadow-none px-10 py-5 max-lg:px-0 max-lg:py-0">
          <Formik
            onSubmit={async (values) => await bookAppointment(values)}
            initialValues={initialValues}
            validationSchema={schema}>
            {({ values }) => (
              <Form className="flex flex-col gap-4 w-full ">
                <div className="px-4 flex flex-col gap-4">
                  <FormikInput
                    name="email"
                    placeholder="Enter Email"
                    type="email"
                    label="Email"
                    value={values.email}
                  />
                  <FormikInput
                    name="mobileNumber"
                    placeholder="000 000 0000"
                    type="tel"
                    maxLength="10"
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(/[^\d+]/g, ""))
                    }
                    label="Mobile Number"
                    value={values.mobileNumber}
                  />
                </div>
                <div className="max-lg:w-full flex justify-center items-center p-2 px-4 max-lg:fixed max-lg:bottom-0 max-lg:bg-white">
                  <button
                    type="submit"
                    disabled={
                      values.mobileNumber.length !== 10 ||
                      values.email.length === 0
                    }
                    className="w-full bg-black  py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16 max-sm:px-4">
                    Book Appointment
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ProviderBookAppointment;
