import React from "react";
import { useNavigate } from "react-router-dom";
import azodha from "../../../assets/images/azodha.svg";
import websocketLoggerService from "../../../services/websocket-logger";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = window.location.pathname;

  const isSuccess = location === "/success";

  const isSchedule = location.startsWith("/schedule");

  const bookAppointmentSuccess = location.startsWith(
    "/book-appointment-success"
  );

  const isAddPatientDetails = location.startsWith("/add-patient-details");

  return (
    <div>
      <div className="bg-white shadow h-16 flex items-center border-b border-lightBlue justify-between px-32 max-lg:px-5">
        <img className="h-10" src={azodha} alt="Azodha Logo" />
        {!location.startsWith("/schedule") && (
          <button
            className="underline uppercase tracking-wider"
            onClick={() => {
              websocketLoggerService.sendMessage({
                eventType: "Logout button clicked",
                eventSubType: "Logout button clicked",
                eventData: `Location: ${location}`,
              });

              navigate("/logout");
            }}>
            Save & Exit
          </button>
        )}
      </div>
      {
        <div className="flex justify-center max-lg:hidden">
          <div className="">
            {isSuccess || bookAppointmentSuccess ? (
              <div className="w-full h-1 bg-green-500"></div>
            ) : isAddPatientDetails ? (
              <div className="w-full h-1 bg-lightestBlue"></div>
            ) : (
              <div className="w-full h-1 bg-gradient-to-l from-green-500 via-blue-700 to-violet-700 "></div>
            )}
            <div className="flex items-center justify-start gap-2 mt-2 px-4">
              {(isSuccess || isAddPatientDetails || bookAppointmentSuccess) && (
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle cx="10" cy="10.5879" r="10" fill="#34A853" />
                  <circle cx="10" cy="10" r="10" fill="#34A853" />
                  <path
                    d="M4.70703 10.5284L8.07602 13.8225L15.2953 6.76367"
                    stroke="white"
                    strokeWidth="2"
                  />
                </svg>
              )}
              <span
                className={`${isAddPatientDetails ? "text-lightBlue" : ""}`}>
                Schedule Appointment
              </span>
            </div>
          </div>
          <div className="">
            {isSuccess ? (
              <div className="w-full h-1 bg-green-500 "></div>
            ) : isAddPatientDetails ? (
              <div className="w-full h-1 bg-gradient-to-l from-green-500 via-blue-700 to-violet-700 "></div>
            ) : (
              <div className="w-full h-1 bg-lightestBlue"></div>
            )}
            <div className="flex items-center justify-start gap-2 mt-2 px-4">
              {isSuccess && (
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle cx="10" cy="10.5879" r="10" fill="#34A853" />
                  <circle cx="10" cy="10" r="10" fill="#34A853" />
                  <path
                    d="M4.70703 10.5284L8.07602 13.8225L15.2953 6.76367"
                    stroke="white"
                    strokeWidth="2"
                  />
                </svg>
              )}
              <span
                className={`${
                  isAddPatientDetails || isSuccess ? "" : "text-gray-400"
                }`}>
                Add Patient Details
              </span>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default Header;
