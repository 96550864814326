import { HMSRoomProvider } from "@100mslive/react-sdk";
import { Outlet } from "react-router-dom";

const TeleProvider: React.FC = () => {
  return (
    <HMSRoomProvider>
      <Outlet />
    </HMSRoomProvider>
  );
};

export default TeleProvider;
