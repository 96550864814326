import React, { useEffect } from "react";
import { IServiceConsumer } from "../../interfaces";
import dateService from "../../services/date.service";
import websocketLoggerService from "../../services/websocket-logger";
import useFormStore from "../../store/form/form.store";
import { parseDOB } from "../shared/utils/helper.util";

interface ITermsAndConditionProps {
  setAgreementCompletedCount?: React.Dispatch<React.SetStateAction<number>>;
  currentTemplate: number;
  setCurrentTemplate: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  agreementData: any;
  patient: IServiceConsumer;
  consentInputs?: any;
  ccCapture?: Boolean;
}

const TermsAndConditionCard: React.FC<ITermsAndConditionProps> = ({
  setAgreementCompletedCount,
  currentTemplate,
  setCurrentTemplate,
  index,
  agreementData,
  patient,
  consentInputs,
  ccCapture,
}) => {
  const [agreement, setAgreement] = React.useState("");
  const [show, setShow] = React.useState(currentTemplate === index);
  const [completed, setCompleted] = React.useState(false);
  const setCcCaptured = useFormStore((state) => state.setCcConsentCaptured);

  useEffect(() => {
    if (!agreementData || !patient) {
      return;
    }
    const doc = new DOMParser().parseFromString(
      agreementData.contentHtml,
      "text/html"
    );

    let bodyContent = doc.body.innerHTML.trim();

    agreementData.fields.forEach((field, ind: number) => {
      const key: string = field.key;
      if (field.type === "text" || field.type === "email") {
        const obj = {
          patientname: patient?.firstName + " " + patient?.lastName,
          email: patient?.email,
          relationship: "",
          address: "",
        };
        const value = (obj[key] as string) || "";
        bodyContent = bodyContent.replaceAll(
          `\${${key}}`,
          `<span style="margin: 0px 2px" class='${
            field.key as string
          }'  data-clarity-mask="True" >${value}</span>`
        );
      } else if (field.key === "dob") {
        const value = patient?.birthDate
          ? parseDOB(patient?.birthDate, "DD - MMMM - YYYY")
          : "";
        bodyContent = bodyContent.replaceAll(
          `\${${key}}`,
          `<span  data-clarity-mask="True" class='${
            field.key as string
          }'>${value} </span>`
        );
      } else if (field.type === "datetime") {
        bodyContent = bodyContent.replaceAll(
          `\${${key}}`,
          dateService.getCurrentDate("DD - MMMM - YYYY")
        );
      } else if (field.type === "boolean") {
        if (
          agreementData.name !==
            "Advance Beneficiary Notice of Non-coverage (ABN)" &&
          agreementData.name !==
            "Patient Attestation of No Insurance / Out of Network Insurance"
        ) {
          bodyContent = bodyContent.replace(
            `\${${key}}`,
            `<input data-clarity-mask="True" id="${key}"  type="checkbox"  style="margin-right: 2px; border-radius: 5px;  border: 1px solid #166FF6;" />`
          );
        } else {
          bodyContent = bodyContent.replace(`\${${key}}`, "");
        }
      } else if (field.type === "base64") {
        bodyContent = bodyContent.replaceAll(
          `\${${key}}`,
          `<div style="width: 45%; max-width : 220px; height: 60px;background-color:#F5F5F5;cursor:default; border-radius: 2px; margin-top: 10px; display: flex; justify-content: center; align-items: center; font-size: 16px; font-weight: 500; text-align:center; color: #afafaf;">Placeholder For Signature</div>`
        );
      }
    });

    const agreementId: string = agreementData.id;

    const agreements = `<form id="myForm-${agreementId}"> ${bodyContent} </form> `;
    if (!agreements) {
      return;
    }
    setAgreement(agreements);
  }, [agreementData, index, patient]);

  useEffect(() => {
    setShow(index === currentTemplate);
  }, [currentTemplate, index]);

  React.useEffect(() => {
    const patientNameTag = document.querySelectorAll(".patientname");
    patientNameTag.forEach((item) => {
      item.innerHTML = consentInputs?.patientname || "";
    });
    const relationshipTag = document.querySelectorAll(".relationship");
    relationshipTag.forEach((item) => {
      item.innerHTML = consentInputs?.relationship || "";
    });
    const addressTag = document.querySelectorAll(".address");
    addressTag.forEach((item) => {
      item.innerHTML = consentInputs?.address || "";
    });
    const dobTag = document.querySelectorAll(".dob");
    dobTag.forEach((item) => {
      item.innerHTML = consentInputs?.dob || "";
    });
    const notifierTag = document.querySelectorAll(".notifier");
    notifierTag.forEach((item) => {
      item.innerHTML = consentInputs?.notifier || "";
    });
    const identificationNumberTag = document.querySelectorAll(
      ".identificationNumber"
    );
    identificationNumberTag.forEach((item) => {
      item.innerHTML = consentInputs?.identificationNumber || "";
    });
  }, [consentInputs]);

  useEffect(() => {
    if (
      agreementData.name ===
      "Patient Attestation of No Insurance / Out of Network Insurance"
    ) {
      const timeoutId = setTimeout(() => {
        const radioInputs = document.querySelectorAll('input[type="radio"]');
        radioInputs.forEach((radio: HTMLInputElement) => {
          radio.style.display = "inline-block";
        });
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [agreementData, show]);

  useEffect(() => {
    if (!agreementData || !patient) {
      return;
    }
    const allSpan = document.querySelectorAll(".selection");
    if (
      agreementData.name ===
        "Advance Beneficiary Notice of Non-coverage (ABN)" ||
      agreementData.name ===
        "Advance Beneficiary Notice of Non-coverage (ABN)-DLFEE" ||
      agreementData.name ===
        "Advance Beneficiary Notice of Non-coverage (ABN)-ALF"
    ) {
      const radioInputs = document.querySelectorAll('input[type="radio"]');
      radioInputs.forEach((radio: HTMLInputElement) => {
        radio.style.display = "inline-block";
      });
      if (
        location.pathname === "/consent/patient-onboarding-5" &&
        agreementData.name ===
          "Advance Beneficiary Notice of Non-coverage (ABN)-DLFEE"
      ) {
        document.getElementById("option1")?.setAttribute("disabled", "true");
        document.getElementById("option2")?.setAttribute("checked", "true");
        document.getElementById("option2")?.setAttribute("disabled", "true");
        document
          .getElementById("option2")
          ?.setAttribute(
            "style",
            "background-color: #9D9D9D; border-radius: 5px;"
          );

        document.getElementById("option3")?.setAttribute("disabled", "true");
        allSpan.forEach((span) => {
          span.innerHTML = "Delivery Fee for at Home services";
          document
            .querySelectorAll(".underline")
            .forEach((underline: HTMLDivElement) => {
              underline.style.display = "none";
            });
        });
      } else if (
        location.pathname === "/consent/patient-onboarding-6" &&
        agreementData.name ===
          "Advance Beneficiary Notice of Non-coverage (ABN)-ALF"
      ) {
        document.getElementById("option1")?.setAttribute("disabled", "true");
        document.getElementById("option2")?.setAttribute("checked", "true");
        document.getElementById("option2")?.setAttribute("disabled", "true");
        document
          .getElementById("option2")
          ?.setAttribute(
            "style",
            "background-color: #9D9D9D; border-radius: 5px;"
          );
        document.getElementById("option3")?.setAttribute("disabled", "true");

        allSpan.forEach((span) => {
          span.innerHTML = "Assisted Living Facility Entrance Assessment/Exam";
          document
            .querySelectorAll(".underline")
            .forEach((underline: HTMLDivElement) => {
              underline.style.display = "none";
            });
        });
      } else {
        document
          .querySelectorAll(".underline")
          .forEach((underline: HTMLDivElement) => {
            underline.style.display = "inline-block";
          });
        const radio1 = document.querySelector("#option4");
        const radio2 = document.querySelector("#option5");

        radio1?.addEventListener("change", (e) => {
          const checked = (e.target as HTMLInputElement).checked;

          allSpan.forEach((span) => {
            if (checked) {
              span.innerHTML = "Delivery Fee for at Home services";
              document
                .querySelectorAll(".underline")
                .forEach((underline: HTMLDivElement) => {
                  underline.style.display = "none";
                });
            } else {
              span.innerHTML = "";
              document
                .querySelectorAll(".underline")
                .forEach((underline: HTMLDivElement) => {
                  underline.style.display = "inline-block";
                });
            }
          });
        });

        radio2?.addEventListener("change", (e) => {
          const checked = (e.target as HTMLInputElement).checked;
          const allSpan = document.querySelectorAll(".selection");
          allSpan.forEach((span) => {
            if (checked) {
              span.innerHTML =
                "Assisted Living Facility Entrance Assessment/Exam";
              document
                .querySelectorAll(".underline")
                .forEach((underline: HTMLDivElement) => {
                  underline.style.display = "none";
                });
            } else {
              span.innerHTML = "";
              document
                .querySelectorAll(".underline")
                .forEach((underline: HTMLDivElement) => {
                  underline.style.display = "inline-block";
                });
            }
          });
        });
      }
    }
  }, [agreementData, patient, show]);

  const handleIAgreeBtn = () => {
    websocketLoggerService.sendMessage({
      eventType: "Terms and condition Card",
      eventSubType: "consent-agreement signed",
      eventData: `Consent Agreement ${
        agreementData?.name as string
      } signed by patientID: ${patient?.id} location: ${window.location.href}`,
    });
    setShow(false);
    setCompleted(true);
    if (setAgreementCompletedCount) {
      setAgreementCompletedCount((prev: number) => prev + 1);
    }
    setCurrentTemplate((prev: number) => prev + 1);
    window.scrollTo(0, 0);
    if (ccCapture) {
      setCcCaptured(true);
    }
  };

  return (
    <div className="border mt-4 w-full rounded-xl">
      <button
        className={` w-full border-b py-4 border-primary/25 ${
          show
            ? "rounded-t-xl bg-white"
            : "rounded-xl border-none bg-bgLightBlue"
        }  flex items-start justify-between px-4 gap-5`}
        type="button"
        onClick={() => {
          if (completed && !show) {
            setCompleted(false);
            setAgreementCompletedCount((prev: number) => prev - 1);
          }
          setShow(!show);
        }}>
        <div className="flex items-center gap-4">
          <svg
            className={`${completed ? "" : "hidden"}`}
            style={{ minWidth: "20px" }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="#34A853" />
            <path d="M4 10L7.5 13.5L15 6" stroke="white" strokeWidth="1.5" />
          </svg>

          <svg
            className={`${!completed ? "" : "hidden"}`}
            style={{ minWidth: "20px" }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="#9D9D9D" />
            <path d="M4 10L7.5 13.5L15 6" stroke="white" strokeWidth="1.5" />
          </svg>
          <p className="text-primary font-semibold text-left">
            {agreementData.name}
          </p>
        </div>
        <div className={`${show ? "rotate-180" : ""}`}>
          <svg
            className="w-6 h-6 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </button>
      <div
        id={`template-${index}`}
        className={`overflow-y-auto w-full ${show ? "" : "hidden"}`}>
        <div>
          <section className="flex flex-col flex-1 w-full max-w-3xl mx-auto xl:px-6 items-center">
            <div className="w-full">
              <div className="mt-6 text-lg leading-loose tracking-wide text-gray-700 px-2">
                <div dangerouslySetInnerHTML={{ __html: agreement }} />
              </div>
            </div>

            <button
              onClick={handleIAgreeBtn}
              className={`bg-black px-28 py-4 my-4 max-lg:px-4 text-white rounded-md font-bold ${index}-i-agree-btn`}>
              <p> I have read and agree to the terms.</p>
            </button>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionCard;
