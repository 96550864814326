import { ErrorMessage, Field, FieldAttributes } from "formik";
import FieldError from "./field-error.component";

interface FormikInputProps extends FieldAttributes<any> {
  label: string;
}

const FormikInput = (props: FormikInputProps) => {
  return (
    <div className="flex-1 max-sm:w-full">
      <label
        className={`text-sm  ${
          !props.value ? "text-black" : "text-gray-light"
        }`}>
        {props.label}
        {props.isRequired && <span className="text-red-500 text-base">*</span>}
      </label>
      <Field
        name={props.name}
        data-clarity-mask="True"
        className="w-full py-3 px-2 border border-lightBlue bg-white rounded-md mt-1 focus:border-primary focus:text-primary focus:ring-0 disabled:bg-gray-200"
        {...props}>
        {props.children}
      </Field>
      <ErrorMessage name={props.name}>
        {(msg) => <FieldError message={msg} />}
      </ErrorMessage>
    </div>
  );
};

export default FormikInput;
