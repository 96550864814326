import { useLazyQuery, useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import {
  CONSUMER_EPISODE_START,
  GET_SERVICE_CONSUMER_BY_EMAIL_OR_MOBILE,
} from "../../apollo";
import { IConsumerEpisode, IServiceConsumer } from "../../interfaces";
import dateService from "../../services/date.service";
import websocketLoggerService from "../../services/websocket-logger";
import { useConsumerStore } from "../../store";
import { ScopeType } from "../shared/config";
import { useServiceConsumer } from "../shared/hooks";
import { helper } from "../shared/utils";
import FirstTimePatientInfoForm from "./pi-first-time-patient-form.component";
import ReturningPatientForm from "./pi-returning-patient-form.component";

interface PersonalInfoProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;

  NextBtn: React.MutableRefObject<HTMLButtonElement>;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({
  setStep,

  NextBtn,
}) => {
  const serviceConsumer = useServiceConsumer();
  const { user } = useAuth0();

  const [setConsumer, setConsumerEpisode] = useConsumerStore((state) => [
    state.setConsumer,
    state.setConsumerEpisode,
  ]);

  const [addConsumerEpisode] = useMutation<{
      createConsumerEpisode: IConsumerEpisode;
    }>(CONSUMER_EPISODE_START),
    [getServiceConsumerByEmail] = useLazyQuery<{
      getConsumerByEmail: IServiceConsumer;
    }>(GET_SERVICE_CONSUMER_BY_EMAIL_OR_MOBILE, {
      context: {
        scope: ScopeType.Consumer,
      },
    });

  useEffect(() => {
    const getServiceConsumer = async () => {
      const userTimezone = dateService.getUserTimezone();

      if (!user?.email && !user?.phone_number) {
        return;
      }

      try {
        const userEmail = user?.email || "";
        const userPhoneNumber = user?.phone_number || "";

        const { data } = await getServiceConsumerByEmail({
          variables: {
            consumer: {
              email: userEmail,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
              mobileNumber: userPhoneNumber,
            },
          },
          // disable cache to get latest data
          fetchPolicy: "no-cache",
        });

        websocketLoggerService.sendMessage({
          eventType: `Auth0 Login`,
          eventSubType: `API: getServiceConsumerByEmail success`,
          eventData: `User Email: ${user?.email}`,
        });
        const response = helper.getPropertyValue<IServiceConsumer>(
          data,
          "getConsumer"
        );

        if (!response) {
          return;
        }

        const serviceConsumer = { ...response };
        if (!serviceConsumer.timeZone) {
          serviceConsumer.timeZone = userTimezone;
        }

        serviceConsumer.isGuestUser = false;
        const { data: episodeData } = await addConsumerEpisode({
          variables: {
            consumerEpisodeInput: {
              consumerId: serviceConsumer?.id,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            },
          },
        });

        websocketLoggerService.sendMessage({
          eventType: `Auth0 Login`,
          eventSubType: `API request: addConsumerEpisode success`,
          eventData: `API request: consumer id: ${serviceConsumer?.id}`,
        });

        setConsumerEpisode({
          id: episodeData.createConsumerEpisode.id,
          isComplete: episodeData.createConsumerEpisode.isComplete,
        });

        const { idProof, insuranceDetails, ...dateToSetInStore } =
          serviceConsumer;

        setConsumer(dateToSetInStore);
      } catch (error) {
        console.log(error);

        websocketLoggerService.sendMessage({
          eventType: `Auth0 Login`,
          eventSubType: `Error`,
          eventData: `Error data ${JSON.stringify(error)}`,
        });
      }
    };
    getServiceConsumer();
  }, [
    addConsumerEpisode,
    getServiceConsumerByEmail,
    setConsumer,
    setConsumerEpisode,
    user,
    user?.email,
    user?.phone_number,
  ]);

  if (serviceConsumer?.dependants?.length > 0) {
    return <ReturningPatientForm setStep={setStep} NextBtn={NextBtn} />;
  }

  return <FirstTimePatientInfoForm setStep={setStep} NextBtn={NextBtn} />;
};

export default PersonalInfo;
