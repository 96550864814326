const BottomOverLayLayout = ({ setCurrentOverlay, children }) => {
  return (
    <div
      id="bottom-overlay"
      onClick={(e) => {
        if ((e.target as HTMLElement).id !== "bottom-overlay") return;
        setCurrentOverlay("");
      }}
      className="h-screen backdrop-blur-sm bg-gray-500/30 fixed bottom-0 w-full">
      <div className="rounded-t-3xl w-full h-5/6 bg-white absolute bottom-0 p-2 overflow-y-auto">
        {children}
      </div>
    </div>
  );
};

export default BottomOverLayLayout;
