import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loggerService } from "../../../services";
import { useConsumerStore } from "../../../store";

const Verifyemail: React.FC = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const timerRef = useRef(null);
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const setConsumerState = useConsumerStore((state) => state.setConsumerState);
  const { logout: Auth0Signout } = useAuth0();
  const [timer, setTimer] = useState("0");

  const isUserVerified = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const { data: updatedUser } = await axios.get(
        `https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`,
        {
          headers: {
            authorization: `bearer ${accessToken}`,
          },
        }
      );

      if (updatedUser?.email_verified) {
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const sendEmail = async () => {
    if (!user || timer !== "0") {
      return;
    }
    handleTimer();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_PLATFORM_API_URL}/api/auth0-authentication/resend-email`,
        {
          userId: user?.sub,
        },
        {
          headers: { organization_code: process.env.REACT_APP_CARE2U_ORG_CODE },
        }
      );

      if (res) {
        toast.success("Email sent successfully");
      }
    } catch (error) {
      toast.success("Something went wrong. Please try again later.");
      loggerService.error(error);
    }
  };

  const handleProceed = async () => {
    const isVerified = await isUserVerified();
    // if (isVerified) window.location.reload();
    const redirectUrl = sessionStorage.getItem("redirectUrl");

    if (isVerified) {
      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate("/consent/patient-onboarding-1");
      }
    } else setShowError(true);
  };

  useEffect(() => {
    if (user?.email_verified) {
      const redirectUrl = sessionStorage.getItem("redirectUrl");
      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate("/consent/patient-onboarding-1");
      }
    }
  }, [navigate, user?.email_verified]);

  const handleTimer = () => {
    let count = 6;
    const timerInterval = setInterval(() => {
      count--;
      const minutes = Math.floor(count / 60).toString();
      let seconds = (count % 60).toString();
      if (parseInt(seconds) < 10) {
        seconds = "0" + seconds;
      }
      setTimer(minutes + ":" + seconds);
      timerRef.current.style.display = "inline-block";
      if (count === 0) {
        setTimer("0");
        timerRef.current.style.display = "none";
        clearInterval(timerInterval);
      }
    }, 1000);
  };

  const handleEditEmail = () => {
    setConsumerState(null);

    const redirectUrl =
      window.location.origin + sessionStorage.getItem("redirectUrl");
    setTimeout(() => {
      Auth0Signout({
        returnTo: redirectUrl,
      });
    }, 0);

    localStorage.clear();
    sessionStorage.clear();
    toast.success("Logged out successfully.");
  };

  return (
    <main className="bg-white font-lato h-screen mx-auto max-w-screen-2xl flex justify-between max-lg:flex-col">
      <div className="flex mt-6 px-14 flex-col items-start w-1/2 max-lg:w-full max-lg:px-0 max-xl:px-10 ">
        <div className="max-lg:px-4">
          <img
            height="100"
            width="100"
            className="w-18 "
            src="https://care2u.com/wp-content/uploads/2022/04/CARE2U_Logo-Color_FINAL-1536x709.png"
            alt="care 2 u logo"
          />
        </div>
        <div className="w-full">
          <h2 className="mt-6 text-2xl font-bold text-gray-700 max-lg:px-4">
            Please Verify Your Email
          </h2>

          <p className="mt-2 max-lg:px-4">
            To continue with your registration, please verify your email by
            following these simple steps:
          </p>

          <div className="mt-6 bg-[#D9D9D9]/40 rounded-lg flex flex-col gap-3 max-lg:rounded-none">
            <div className="flex mx-4 py-4 items-start gap-4 border-b-2">
              <div className="rounded-full bg-black text-white text-center mt-[4px]">
                <p className="text-sm w-6 h-6 max-lg:w-5 max-lg:h-5 flex items-center justify-center">
                  1
                </p>
              </div>
              <div className="w-full">
                <span className="font-semibold">
                  Check your inbox for our email: &nbsp;
                </span>
                <span>
                  Look for an email from Care2U requesting you to verify your
                  email address.
                </span>
              </div>
            </div>
            <div className="flex mx-4 py-4 items-start gap-4 border-b-2">
              <div className="rounded-full bg-black text-white text-center  mt-[4px]">
                <p className="text-sm w-6 h-6 max-lg:w-5 max-lg:h-5 flex items-center justify-center">
                  2
                </p>
              </div>
              <div className="w-full">
                <span className="font-semibold">
                  Click on the verification link: &nbsp;
                </span>
                <span>
                  Open the email and click on the verification link provided.
                </span>
              </div>
            </div>
            <div className="flex mx-4 py-4 items-start gap-4 border-b-2">
              <div className="rounded-full bg-black text-white text-center  mt-[4px]">
                <p className="text-sm w-6 h-6 max-lg:w-5 max-lg:h-5 flex items-center justify-center">
                  3
                </p>
              </div>
              <div className="w-full">
                <span className="font-semibold"> Hit Proceed:&nbsp;</span>
                <span>
                  Return to this page and complete the process by clicking the
                  Proceed button.
                </span>
              </div>
            </div>
          </div>

          <div className="bg-[#D9D9D9]/40 px-4 py-4 rounded-md mt-4">
            <div className=" max-lg:px-4 mb-4">
              {user?.email && (
                <div>
                  <span className="font-semibold mr-4">
                    The email you have entered is:
                  </span>
                  <p>{user?.email}</p>
                </div>
              )}
            </div>

            <div className="flex gap-10 items-center mt-1 max-lg:px-4 max-lg:gap-4">
              <button
                onClick={handleEditEmail}
                className="text-primary flex items-center gap-2 font-semibold">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                  />
                </svg>
                Edit Email
              </button>
              <button
                onClick={sendEmail}
                className="flex items-center gap-2 disabled:text-gray-500 resend-code text-[#1E7FC2] cursor-pointer max-lg:px-4">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  className="resend-logo"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.463 2.43301C5.27756 0.86067 7.59899 -0.00333986 10 9.70266e-06C15.523 9.70266e-06 20 4.47701 20 10C20 12.136 19.33 14.116 18.19 15.74L15 10H18C18.0001 8.43163 17.5392 6.89781 16.6747 5.58927C15.8101 4.28072 14.5799 3.25517 13.1372 2.64013C11.6944 2.0251 10.1027 1.84771 8.55996 2.13003C7.0172 2.41234 5.59145 3.14191 4.46 4.22801L3.463 2.43301ZM16.537 17.567C14.7224 19.1393 12.401 20.0034 10 20C4.477 20 0 15.523 0 10C0 7.86401 0.67 5.88401 1.81 4.26001L5 10H2C1.99987 11.5684 2.46075 13.1022 3.32534 14.4108C4.18992 15.7193 5.42007 16.7449 6.86282 17.3599C8.30557 17.9749 9.89729 18.1523 11.44 17.87C12.9828 17.5877 14.4085 16.8581 15.54 15.772L16.537 17.567Z"
                    fill={timer !== "0" ? "gray" : "#3537E8"}
                  />
                </svg>

                <p
                  className={`font-semibold ${
                    timer !== "0" ? "text-gray-500" : "text-primary"
                  }`}>
                  Resend email &nbsp;
                  <span ref={timerRef} className="hidden font-normal">
                    in {timer}
                  </span>
                </p>
              </button>
            </div>
          </div>
          <div className="max-lg:px-4 flex flex-col">
            <button
              onClick={handleProceed}
              className="w-full bg-black px-20 py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16">
              Proceed
            </button>
            {showError && (
              <div className="bg-red-100 p-4 rounded-lg flex items-start justify-start gap-4">
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  className="w-8 h-8"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.8587 8.2413L15.4429 0.5933C15.2546 0.266867 14.7922 0 14.4155 0L5.58468 6.51042e-05C5.2078 6.51042e-05 4.74548 0.266932 4.55721 0.593365L0.141213 8.24137C-0.0470708 8.5676 -0.0470708 9.1014 0.141213 9.42795L4.55721 17.0759C4.7455 17.4022 5.20786 17.6692 5.58468 17.6692H14.4155C14.7922 17.6692 15.2546 17.4022 15.4429 17.0759L19.8589 9.42795C20.0471 9.10138 20.0471 8.5676 19.8588 8.24137L19.8587 8.2413ZM18.7905 8.86162L14.4186 16.4338C14.405 16.4436 14.3867 16.4542 14.3718 16.4607H5.62825C5.61328 16.4543 5.59499 16.4436 5.58138 16.4338L1.20955 8.86162C1.20766 8.84508 1.20766 8.82379 1.20955 8.80739L5.58138 1.23522C5.59499 1.22552 5.61328 1.21491 5.62825 1.20833H14.3716C14.3866 1.21491 14.4049 1.22552 14.4183 1.23522L18.7903 8.80739C18.7924 8.82379 18.7924 8.84489 18.7905 8.86162H18.7905Z"
                    fill="#EF0035"
                  />
                  <path
                    d="M10.5749 10.8287L10.8116 2.69141H9.1875L9.42408 10.8287H10.5749Z"
                    fill="#EF0035"
                  />
                  <path
                    d="M10.0171 12.1816C9.4248 12.1816 9.00195 12.6216 9.00195 13.2309C9.00195 13.8229 9.40807 14.2798 9.98307 14.2798C10.6093 14.2798 10.9984 13.8229 10.9984 13.2309C10.9985 12.6217 10.6093 12.1816 10.0171 12.1816Z"
                    fill="#EF0035"
                  />
                </svg>

                <p className="text-red-500">
                  Verification is not complete yet, Please follow the above
                  steps to complete the verification and proceed.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-1/2 h-full px-0 max-lg:w-full max-lg:p-0 max-lg:mt-0">
        <div className="w-full h-full relative carousel overflow-hidden">
          <div className="carousel-images flex h-full max-lg:mt-32">
            <img
              src="https://app-config.azo.dev/upsplash-image-1.jpg"
              alt="Image 1"
              className="object-cover"
            />
            <img
              src="https://app-config.azo.dev/upsplash-image-2.jpg"
              alt="Image 2"
              className="object-cover"
            />
            <img
              src="https://app-config.azo.dev/upsplash-image-3.jpg"
              alt="Image 3"
              className="object-cover"
            />
          </div>
          <div className="absolute bottom-0 max-lg:top-14 w-full">
            <div className="w-3/5 p-10 bg-[#161616] text-white max-lg:w-[60%] max-lg:mx-auto max-lg:min-h-min max-lg:rounded max-lg:p-4 max-lg:text-center max-xl:w-full max-xl:rounded-none">
              <h2 className="text-bold text-3xl max-lg:text-[16px] leading-tight">
                Providing inclusive healthcare at ease
              </h2>
              <div className="mt-4 max-lg:text-[8px]">
                Our team of experienced Hospitalists and Emergency Physicians
                deliver comprehensive, high complexity care in the home,
                integrated with your primary care physician.
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Verifyemail;
