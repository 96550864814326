import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  from,
  HttpLink,
  InMemoryCache,
  NextLink,
  Observable,
  Operation,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import NProgress from "nprogress";
import { toast } from "react-toastify";
import { helper } from "../app/shared/utils";
import { loggerService } from "../services";
import { useConsumerStore } from "../store";

// NProgress Middleware
export class NProgressLink extends ApolloLink {
  inProgressCount: number;

  constructor() {
    super();
    this.inProgressCount = 0;
  }

  request(operation: Operation, forward: NextLink) {
    return new Observable<FetchResult>((observer) => {
      this._onRequestStart();
      const subscription = forward(operation).subscribe({
        next: (result) => {
          this._onRequestEnd();
          observer.next(result);
        },
        error: (error) => {
          this._onRequestEnd();
          observer.error(error);
        },
        complete: observer.complete.bind(observer),
      });

      return () => {
        subscription?.unsubscribe();
      };
    });
  }

  private _onRequestStart() {
    this.inProgressCount++;
    NProgress.start();
  }

  private _onRequestEnd() {
    this.inProgressCount--;
    !this.inProgressCount && NProgress.done();
  }
}

const authorizationMiddleware = new ApolloLink((operation, forward) => {
  // qeury token from store
  const token: string = useConsumerStore.getState().token;

  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });

  return forward(operation);
});

// error handler middleware
const errorHandlerMiddleware = onError(
  ({ graphQLErrors, networkError, operation }) => {
    const handleErrors = helper.getPropertyValue<boolean>(
      operation.getContext(),
      "handleErrors",
      true
    );

    if (handleErrors) {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          loggerService.error(
            "graphQLErrors",
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `Message: ${message}, Location: ${locations}, Path: ${path}`
          );
          toast.error(message);
        });
      }

      if (networkError) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        loggerService.error(`[Network error]: ${networkError}`);
        toast.error(networkError.message);
      }
    }
  }
);

// httpLink Middleware
const httpLinkMiddleware = new HttpLink({
  uri: `${process.env.REACT_APP_OPEN_SCHEDULING_URL}/api/graphql`,
});

const client = new ApolloClient({
  // The `from` function combines an array of individual links into a link chain
  link: from([
    new NProgressLink(),
    authorizationMiddleware,
    errorHandlerMiddleware,
    httpLinkMiddleware,
  ]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export default client;
