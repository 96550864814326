interface IBaseConsumer {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  birthDate: string;
  mobileNumber: string;
  homePhoneNumber?: string;
  timeZone: string;
  isGuestUser: boolean;
  organizationId?: number;
  isPatient?: boolean;
  idProof?: {
    idNumber?: number;
    state?: number;
    drivingLicenseFront?: string;
    drivingLicenseBack?: string;
    healthCareProxyDocument?: string;
  };
  addresses?: {
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    country?: string;
    zipCode?: string;
  };
  insuranceDetails?: {
    idNumber?: number;
    backSide?: string;
    frontSide?: string;
  };
}

export interface IServiceConsumer extends IBaseConsumer {
  dependants: IBaseConsumer[];
}

export interface IConsumerGuardian extends IBaseConsumer {
  gender: string;
  birthDate: string;
  mobileNumber: string;
  externalId?: string;
}

export interface IConsumerDependant {
  patientId: string;
  description: string;
  region: string;
}

export interface IConsumerEpisode {
  id: number;
  isComplete: boolean;
}

export interface IConsumerStore {
  token: string;
  consumer: IServiceConsumer;
  dependant: IConsumerDependant;
  appointmentProps: Record<string, string>;
}

export enum ConsumerAppointmentType {
  Schedule = 1,
  ConsultNow = 2,
}

export enum OTRoleType {
  ServiceProvider = "serviceprovider",
  Patient = "patient",
  Waiting = "waiting",
}

export interface IConsumerAppointment {
  entityId: string;
  entitySlug: string;
  entityType: string;
  entityCode: string;
  appointmentType: ConsumerAppointmentType;
  appointmentToken: string;
}
