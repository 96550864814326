import {
  HMSPeer,
  useAVToggle,
  useHMSActions,
  useVideo,
} from "@100mslive/react-sdk";
import { useNavigate } from "react-router-dom";
interface IMeetingRoomProps {
  host: HMSPeer;
  patient: HMSPeer;
}

function VideoTile({ peer }) {
  const { videoRef } = useVideo({
    trackId: peer.videoTrack,
  });
  return (
    <>
      <video
        ref={videoRef}
        className={`z-10 object-cover w-full h-full ${
          peer.isLocal ? "local" : ""
        }`}
        autoPlay
        muted
        playsInline
      />
      <div className="px-2 py-1 text-sm bg-gray-600 text-white mt-2 ml-2 rounded-lg peer-name">
        {peer.name}
        {peer.isLocal ? "(You)" : ""}
      </div>
    </>
  );
}

const MeetingRoom: React.FC<IMeetingRoomProps> = ({ host, patient }) => {
  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } =
    useAVToggle();
  const hmsActions = useHMSActions();
  const navigate = useNavigate();

  if (!host || !patient) {
    return (
      <div className="flex-1">
        We are preparing the room for your visit, our provider with will be with
        you shortly.
      </div>
    );
  }

  const handleDropOff = () => {
    hmsActions.leave();
    navigate("/appointment-success");
  };

  return (
    <div className="flex border  overflow-hidden bg-gray-50 font-dm-sans">
      {/* video conferencing */}
      <div className="flex-col flex-1 xl:flex">
        <div className="flex overflow-y-auto ">
          <div className="relative w-full h-screen overflow-hidden ">
            <VideoTile peer={host} />
            <div className="absolute object-cover border-2 border-black rounded-xl w-28 h-36 right-8 bottom-28">
              {<VideoTile peer={patient} />}
            </div>

            <div className="absolute flex items-center justify-between inset-x-0 bottom-0 w-full py-4 px-8 bg-[#383838]">
              <div className="flex items-center space-x-3">
                <button
                  className={`p-3 rounded-lg focus:outline-none ${
                    isLocalAudioEnabled ? "bg-primary" : "bg-red-500"
                  }`}
                  onClick={toggleAudio}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.3843 13.1588C12.1362 13.1588 13.5552 11.7399 13.5552 9.98795V5.62798C13.5552 3.87606 12.1362 2.45709 10.3843 2.45709C8.63235 2.45709 7.21338 3.87606 7.21338 5.62798V9.98795C7.21338 11.7399 8.63235 13.1588 10.3843 13.1588Z"
                      stroke="white"
                      strokeWidth="1.57317"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.32025 8.52145V9.86908C4.32025 13.2144 7.03929 15.9334 10.3846 15.9334C13.7299 15.9334 16.4489 13.2144 16.4489 9.86908V8.52145"
                      stroke="white"
                      strokeWidth="1.57317"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.3842 15.9334V18.3116"
                      stroke="white"
                      strokeWidth="1.57317"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>

                <button
                  className={`p-3 rounded-lg focus:outline-none ${
                    isLocalVideoEnabled ? "bg-primary" : "bg-red-500"
                  }`}
                  onClick={toggleVideo}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.1625 17.059H5.15251C2.64751 17.059 1.81516 15.3943 1.81516 13.7216V7.04692C1.81516 4.54192 2.64751 3.70956 5.15251 3.70956H10.1625C12.6675 3.70956 13.4999 4.54192 13.4999 7.04692V13.7216C13.4999 16.2266 12.6596 17.059 10.1625 17.059Z"
                      stroke="white"
                      strokeWidth="1.57317"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.7036 14.4272L13.4999 12.8814V7.87933L15.7036 6.33353C16.7817 5.58044 17.6696 6.04022 17.6696 7.36406V13.4046C17.6696 14.7285 16.7817 15.1882 15.7036 14.4272Z"
                      stroke="white"
                      strokeWidth="1.57317"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.346 9.59157C10.0027 9.59157 10.5351 9.0592 10.5351 8.40249C10.5351 7.74578 10.0027 7.21341 9.346 7.21341C8.68929 7.21341 8.15692 7.74578 8.15692 8.40249C8.15692 9.0592 8.68929 9.59157 9.346 9.59157Z"
                      stroke="white"
                      strokeWidth="1.57317"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <button
                onClick={() => handleDropOff()}
                className="px-5 font-semibold py-2.5 focus:outline-none bg-[#FF4F57] hover:bg-[#FF4F57]/80 duration-300 transition-colors text-sm text-white rounded-md">
                Drop off
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingRoom;
