import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_CONSUMER_BY_ID } from "../../apollo";
import docProfile from "../../assets/images/doc-profile.png";
import { IServiceConsumer } from "../../interfaces";
import { loggerService } from "../../services";
import websocketLoggerService from "../../services/websocket-logger";
import useFormStore from "../../store/form/form.store";
import { ScopeType } from "../shared/config";
import { helper } from "../shared/utils";
import useAvailabilityStore from "../../store/availability/availability.store";
import dateService, { USER_TIME_FORMAT_12H } from "../../services/date.service";

const StepSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [newPatientId] = useFormStore((state) => [state.newPatientID]);
  const [newPatient, setNewPatient] = useState(null);

  const [getConsumerByIdPatient] = useLazyQuery<{
    getConsumerById: IServiceConsumer;
  }>(GET_CONSUMER_BY_ID, {
    context: {
      scope: ScopeType.Consumer,
    },
  });

  const [
    patientLocation,
    specialty,
    provider,
    appointmentDate,
    appointmentStartTime,
    appointmentEndTime,
  ] = useAvailabilityStore((state) => [
    state.patientLocation,
    state.specialty,
    state.provider,
    state.appointmentDate,
    state.appointmentStartTime,
    state.appointmentEndTime,
  ]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: patientData } = await getConsumerByIdPatient({
          variables: {
            consumer: {
              consumerId: newPatientId,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            },
          },
          fetchPolicy: "no-cache",
        });

        const response = helper.getPropertyValue<IServiceConsumer>(
          patientData,
          "consumerById"
        );
        setNewPatient(response);

        websocketLoggerService.sendMessage({
          eventType: "Terms and Conditions",
          eventSubType: "API Request: GET_CONSUMER_BY_ID success",
          eventData: `consumerId: ${newPatientId}, organizationId: ${process.env.REACT_APP_ORGANIZATION_ID}`,
        });
      } catch (error) {
        websocketLoggerService.sendMessage({
          eventType: "Terms and Conditions",
          eventSubType: "Error on API Request: GET_CONSUMER_BY_ID Failed",
          eventData: `Error: ${JSON.stringify(error)}`,
        });
        loggerService.error(error);
      }
    };
    if (newPatientId) {
      getData();
    }
  }, [getConsumerByIdPatient, newPatientId]);

  return (
    <div className="bg-blue-gradient min-h-screen overflow-hidden flex flex-col justify-start pt-10 items-center gap-y-2 px-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="102"
        height="94"
        fill="none"
        className="max-sm:hidden"
        viewBox="0 0 102 94">
        <circle cx="47" cy="47" r="47" fill="#F0F1FF"></circle>
        <circle cx="54.582" cy="47" r="47" fill="#34A853"></circle>
        <path
          fill="#168C35"
          fillRule="evenodd"
          d="M78.84 32.629L40.78 69.742 21.227 50.675l6.72-6.855L40.78 56.334l31.339-30.56 6.72 6.855z"
          clipRule="evenodd"></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M82.747 32.39L45.53 68.78 26.41 50.083l6.572-6.72L45.53 55.632l30.645-29.964 6.572 6.721z"
          clipRule="evenodd"></path>
      </svg>

      <h1 className="text-2xl px-4 max-sm:text-center">
        Great, you are all set for your appointment! We look forward to serving
        you.
      </h1>
      {newPatient ? (
        <div className="w-1/2 max-lg:w-3/4 max-sm:w-full shadow-blue mt-4 border border-lightBlue p-4 rounded-md flex items-center justify-between gap-2">
          <div className="flex items-center gap-4 text-lg">
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle
                cx="9.5"
                cy="10.2206"
                r="9.5"
                className="fill-lightBlue"
              />
              <path
                d="M4.46875 10.7233L7.66928 13.8527L14.5276 7.14685"
                stroke="white"
                strokeWidth="1.5"
              />
            </svg>

            <p className="flex justify-between ">
              {newPatient?.firstName} {newPatient?.lastName}
            </p>
          </div>
          <span className="text-gray-400">Patient</span>
        </div>
      ) : (
        <p className="mb-10">Loading...</p>
      )}
      <div className="w-1/2 max-lg:w-3/4 max-sm:w-full shadow-blue border mt-2 border-lightBlue rounded-md mb-2 flex items-center justify-between gap-2">
        <div className="flex max-sm:flex-col items-start justify-between gap-4 text-lg w-full">
          {provider !== "anyProvider" && (
            <div className="flex items-center gap-2 ml-4 ">
              <img
                src={docProfile}
                alt=""
                className="w-14 h-14  rounded-full"
              />
              <div className="flex flex-col p-4 ">
                <p className="flex justify-between font-bold ">
                  Dr. {provider}
                </p>
                <p className="flex justify-between text-primary ">
                  {specialty}
                </p>
              </div>
            </div>
          )}
          <div
            className={`flex max-sm:border-t max-sm:border-primary max-sm:w-full ${
              provider === "anyProvider" ? " rounded-t-md" : ""
            } `}>
            <div className="flex flex-col border-l p-4 max-sm:border-none ">
              <p className="flex justify-between font-bold ">
                {new Date(appointmentDate).toLocaleString("en-US", {
                  weekday: "short",
                  day: "numeric",
                  month: "short",
                })}
              </p>
              <p className="flex justify-between text-primary ">
                {dateService.convertUtcDateTimeToBrowserFormat(
                  appointmentDate,
                  appointmentStartTime,
                  USER_TIME_FORMAT_12H
                )}{" "}
                -{" "}
                {dateService.convertUtcDateTimeToBrowserFormat(
                  appointmentDate,
                  appointmentEndTime,
                  USER_TIME_FORMAT_12H
                )}
              </p>
            </div>
            <div className="flex flex-col border-l p-4 ">
              <p className="flex justify-between font-bold ">Location</p>
              <p className="flex justify-between text-primary ">
                {patientLocation}
              </p>
            </div>
          </div>
        </div>
      </div>

      <span className="font-bold">Join meeting 10 mins prior.</span>
      <div className="text-sm">
        Doctor will be waiting for you on given link.{" "}
      </div>
      <button
        className="w-40 border max-sm:mb-10 mt-4 bg-black px-8 py-3 text-white rounded font-bold"
        onClick={() => navigate("/logout")}>
        Done
      </button>
    </div>
  );
};

export default StepSuccess;
