import { useEffect, useRef } from "react";

const WebOverlayLayout = ({ setCurrentOverlay, children }) => {
  const divRef = useRef(null); // Ref for the div you want to detect clicks outside of

  // Event handler to check if a click occurred outside the div

  // Add a click event listener when the component mounts
  // useEffect(() => {
  //   const handleClickOutside = (e) => {
  //     if (
  //       divRef.current &&
  //       !divRef.current.contains(e.target) &&
  //       window.innerWidth > 1024
  //     ) {
  //       console.log(!divRef.current.contains(e.target));

  //       setCurrentOverlay("");
  //     }
  //   };
  //   document.addEventListener("click", handleClickOutside);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [divRef, setCurrentOverlay]);

  return (
    <div
      ref={divRef}
      className="absolute bg-white w-4/5 pb-10 rounded-md left-1/2 top-16 transform -translate-x-1/2 max-lg:hidden shadow-lg"
      onBlur={() => {
        console.log("blur");
      }}>
      <div className="bg-white">{children}</div>
    </div>
  );
};

export default WebOverlayLayout;
