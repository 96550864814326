import { gql } from "@apollo/client";

export const GET_ORG_EVENET_TEMPLATES = gql`
  query Query($organizationId: Int!) {
    appointmentTypeTemplatesByOrganization(organizationId: $organizationId) {
      id
      templateName
      name
      color
      type
      locationType
      description
      durationInMinutes
      maximumAppointmentsPerDay
      startDate
      endDate
    }
  }
`;

export const GET_SERVICE_PROVIDER_EVENTS = gql`
  query Query($serviceProviderId: Int!) {
    appointmentTypes(serviceProviderId: $serviceProviderId) {
      id
      durationInMinutes
      startDate
      endDate
      name
      description
      type
      appointmentTypeTemplateId
      minTimeBeforeBookingInMinutes
      serviceProviderId
      locationType
      serviceProviderScheduleId
      color
      maximumAppointmentsPerDay
      disabled
      paymentLink
      cancellationPolicy
      organizationId
      templateName
      serviceProviderSchedule {
        id
        name
        default
        periods {
          dayOfWeek
          startTime
          endTime
        }
        overrides {
          date
          startTime
          endTime
          unavailable
        }
      }
    }
  }
`;

export const GET_EVENT_TEMPLATES = gql`
  query Query($organizationId: Int!) {
    appointmentTypeTemplatesByOrganization(organizationId: $organizationId) {
      id
      name
      color
      type
      description
      locationType
      durationInMinutes
      startDate
      endDate
      organizationId
      templateName
    }
  }
`;

export const GET_EVENT_TEMPLATE_DETAILS = gql`
  query Query($appointmentTypeTemplateId: Int!) {
    appointmentTypeTemplate(
      appointmentTypeTemplateId: $appointmentTypeTemplateId
    ) {
      id
      name
      color
      type
      description
      locationType
      durationInMinutes
      startDate
      endDate
      organizationId
      templateName
    }
  }
`;

export const GET_EVENT_TEMPLATE_REGIONS = gql`
  query Query($appointmentTypeTemplateId: Int!) {
    regionsByAppointmentTypeTemplateId(
      appointmentTypeTemplateId: $appointmentTypeTemplateId
    ) {
      regions
    }
  }
`;
