const FormikRadioButton = ({
  form: { setFieldValue },
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => {
  console.log(id, value);

  return (
    <div
      className={`flex items-center justify-start gap-4 border  rounded-lg p-4 pr-4 ${
        id === value ? "border-primary" : "border-gray-300"
      }`}>
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        className={`text-primary focus:ring-0 focus:outline-none w-6 h-6`}
        {...props}
      />
      <label
        className={`${id === value ? "text-primary" : ""} min-w-full`}
        onClick={() => setFieldValue(name, id)}
        htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default FormikRadioButton;
