import { useLazyQuery, useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  ADD_SERVICE_CONSUMER,
  CONSUMER_LOOK_UP,
  GET_CONSUMER_GUARDIAN,
  LINK_CONSUMER,
  UPDATE_CONSUMER_GUARDIAN,
  UPDATE_CONSUMER_IDENTITY_PROOF,
} from "../../apollo";
import { IServiceConsumer } from "../../interfaces";
import { loggerService, networkService } from "../../services";
import dateService from "../../services/date.service";
import websocketLoggerService from "../../services/websocket-logger";
import { useConsumerStore } from "../../store";
import useFormStore from "../../store/form/form.store";
import DateSeparateInput from "../shared/components/date-separate-input.component";
import FieldError from "../shared/components/field-error.component";
import FormikInput from "../shared/components/formik-input.component";
import GuideBanner from "../shared/components/guide-banner.component";
import UploadFile from "../shared/components/upload-file.component";
import { PatientFlowType, ScopeType, regexConstants } from "../shared/config";
import { useServiceConsumer } from "../shared/hooks";
import { parseDOB, parseISODateStr } from "../shared/utils/helper.util";

const phoneRegExp = regexConstants.phoneRegExp;

interface ReturningPatientFormProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;

  NextBtn: React.MutableRefObject<HTMLButtonElement>;
}

const ReturningPatientForm: React.FC<ReturningPatientFormProps> = ({
  setStep,

  NextBtn,
}) => {
  const [
    setIsGuardian,
    newPatientID,
    setNewPatientID,
    setPatientFlow,
    patientFlow,
    isValidDate,
  ] = useFormStore((store) => [
    store.setIsGuardian,
    store.newPatientID,
    store.setNewPatientID,
    store.setPatientFlow,
    store.patientFlow,

    store.isValidDate,
  ]);

  const [patientType, setPatientType] = useState(
    patientFlow || PatientFlowType.Yourself
  );

  const hideForm = location.pathname === "/consent/patient-onboarding-3";

  const time = new Date();
  time.setSeconds(time.getSeconds() + 100);

  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp: time,
  });

  const { user } = useAuth0();

  const [addServiceConsumer] = useMutation<{
    addConsumer: IServiceConsumer;
  }>(ADD_SERVICE_CONSUMER);

  const [consumerLookup] = useLazyQuery<{
    consumerLookUp: IServiceConsumer[];
  }>(CONSUMER_LOOK_UP, {
    context: {
      scope: ScopeType.Consumer,
    },
  });

  const [linkConsumer] = useMutation<{
    linkConsumers: IServiceConsumer;
  }>(LINK_CONSUMER);

  const [duplicatePatient, setDuplicatePatient] =
    React.useState<IServiceConsumer>(null);

  const serviceConsumer: any = useServiceConsumer(),
    updateConsumer = useConsumerStore((store) => store.setConsumer),
    [updateConsumerGuardian] = useMutation<{
      updateConsumer: IServiceConsumer;
    }>(UPDATE_CONSUMER_GUARDIAN),
    [fetchCurrentPatientDetails] = useLazyQuery<{
      consumerById: IServiceConsumer;
    }>(GET_CONSUMER_GUARDIAN);

  const [currentPatient, setCurrentPatient] =
      React.useState<any>(serviceConsumer),
    [updateConsumerDrivingLicense] = useMutation<{
      updateConsumer: IServiceConsumer;
    }>(UPDATE_CONSUMER_IDENTITY_PROOF);

  useEffect(() => {
    if (patientFlow === PatientFlowType.Yourself) {
      fetchCurrentPatient(serviceConsumer.id);
    } else {
      newPatientID && fetchCurrentPatient(newPatientID);
    }
  }, []);

  const formSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .matches(regexConstants.firstNameRegExp, "Please enter a valid name."),
    lastName: Yup.string()
      .required("Last Name is required")
      .matches(regexConstants.lastNameRegExp, "Please enter a valid name."),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please enter a valid email address"),
    birthDate: Yup.date()
      .typeError("Invalid Date")
      .required("Patient date of birth is required."),
    gender: Yup.string().required("Patient Sex assigned at birth is required."),
    mobileNumber: Yup.string()
      .required("Patient phone number is required.")
      .matches(phoneRegExp, "Please enter a valid phone number"),
    homePhoneNumber: Yup.string().test(
      "isRequired",
      "Please enter a valid phone number",
      (val) => {
        if (!val || val?.length === 0) return true;
        else {
          return regexConstants.phoneRegExp.test(val);
        }
      }
    ),
    patientFrontLicense: Yup.string().required(
      "Patient front license required"
    ),
    otp: Yup.string().test("isRequired", "Invalid OTP", (val) => {
      if (duplicatePatient && val && val?.length > 6) return false;
      return true;
    }),
  });

  const checkDuplicate = async (values: any, setValues) => {
    try {
      const inputDate = values.birthDate;
      const parts = inputDate.split("/");
      const dateObject = `${parts[2] as string}-${parts[0] as string}-${
        parts[1] as string
      }`;

      const { data: duplicatePatient } = await consumerLookup({
        variables: {
          consumer: {
            organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            firstName: values.firstName,
            lastName: values.lastName,
            birthDate: dateObject,
            gender: values.gender,
          },
        },
      });

      if (duplicatePatient?.consumerLookUp?.length === 0) {
        websocketLoggerService.sendMessage({
          eventType: "Checking Duplicate Patient",
          eventSubType: "API Request consumerLookup success",
          eventData: `No data found for consumer: ${JSON.stringify(values)}`,
        });
        return false;
      } else if (duplicatePatient?.consumerLookUp?.length === 1) {
        setDuplicatePatient(duplicatePatient.consumerLookUp[0]);
        websocketLoggerService.sendMessage({
          eventType: "Checking Duplicate Patient",
          eventSubType: "API Request consumerLookup success",
          eventData: `one data found for consumer: ${JSON.stringify(values)}`,
        });
        return true;
      } else if (duplicatePatient?.consumerLookUp?.length > 1) {
        setDuplicatePatient({} as IServiceConsumer);
        setValues({
          ...values,
          verificationType: "option2",
        });
        websocketLoggerService.sendMessage({
          eventType: "Checking Duplicate Patient",
          eventSubType: "API Request consumerLookup success",
          eventData: `More than one data found for consumer: ${JSON.stringify(
            values
          )}`,
        });
        return true;
      }
    } catch (error) {
      websocketLoggerService.sendMessage({
        eventType: "Checking Duplicate Patient",
        eventSubType: "API Request consumerLookup error",
        eventData: `Error: ${JSON.stringify(error)}`,
      });
      toast.error(error.message);
    }

    setDuplicatePatient(null);
    return false;
  };

  const handleSendOTP = async (values, setValues) => {
    try {
      const otpSend: any = await networkService.post(
        `${process.env.REACT_APP_OPEN_SCHEDULING_URL}/api/auth0-authentication/send-sms-otp`,
        {
          organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
          consumerId: duplicatePatient?.id,
        }
      );
      if (otpSend.error) {
        toast("Phone number is not valid");
        websocketLoggerService.sendMessage({
          eventType: "Returning Patient Info OTP Send",
          eventSubType: "API: sendSMSOTP Error",
          eventData: `Error ${JSON.stringify(otpSend?.error)}`,
        });
        return;
      }
      toast.success("OTP sent to patient's mobile number.");
      websocketLoggerService.sendMessage({
        eventType: "Returning Patient Info OTP Send",
        eventSubType: "API: sendSMSOTP Success",
        eventData: `Patient id ${duplicatePatient?.id as unknown as string}`,
      });
      const time = new Date();
      time.setSeconds(time.getSeconds() + 100);
      restart(time);

      setValues({
        ...values,
        otpSent: true,
      });
    } catch (error) {
      websocketLoggerService.sendMessage({
        eventType: "Returning Patient Info OTP Send",
        eventSubType: "API: sendSMSOTP Error",
        eventData: `Error ${JSON.stringify(error)}`,
      });
      console.log(error);
    }
  };

  const handleVerifyOTP = async (values, otpFromClient = false) => {
    const userTimezone = dateService.getUserTimezone();
    let currentServiceConsumer = serviceConsumer;
    if (!serviceConsumer && patientFlow !== PatientFlowType.Yourself) {
      const { data } = await addServiceConsumer({
        variables: {
          consumer: {
            email: user.email || null,
            firstName: "",
            timeZone: userTimezone,
            mobileNumber: user.phone_number || null,
            organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
          },
        },
        context: {
          scope: ScopeType.Consumer,
        },
      });
      const response = data.addConsumer;
      currentServiceConsumer = { ...response };
      if (!currentServiceConsumer.timeZone) {
        currentServiceConsumer.timeZone = userTimezone;
      }
      currentServiceConsumer.isGuestUser = false;
      const { idProof, insuranceDetails, ...dateToSetInStore } =
        currentServiceConsumer;
      updateConsumer(dateToSetInStore);
      websocketLoggerService.sendMessage({
        eventType: "Creating Guardian for Dependant",
        eventSubType: "API: addServiceConsumer Success",
        eventData: `Patient id ${currentServiceConsumer?.id as string}`,
      });
    }
    try {
      const { data: consumer } = await linkConsumer({
        variables: {
          linkGuardianToConsumer: {
            otp: String(values.otp),
            guardianId:
              patientFlow === PatientFlowType.Yourself
                ? null
                : currentServiceConsumer.id,
            organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            otpFromClient,
            isGuardianCase: patientFlow === PatientFlowType.Yourself,
            consumer: {
              id: duplicatePatient?.id || null,
              firstName: values.firstName,
              lastName: values.lastName,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
              email: values.email,
              mobileNumber: `+1${values.mobileNumber as string}`,
              homePhoneNumber:
                values.homePhoneNumber !== ""
                  ? `+1${values.homePhoneNumber as string}`
                  : "",
              gender: values.gender,
              timeZone: userTimezone,
              birthDate: parseISODateStr(values.birthDate),
              idProof: {
                drivingLicenseFront: values.patientFrontLicense,
              },
            },
          },
        },
      });

      websocketLoggerService.sendMessage({
        eventType: "Returning Patient Info Link Consumer",
        eventSubType: "API: linkConsumer Success",
        eventData: `Patient id ${
          consumer?.linkConsumers?.id as unknown as string
        }`,
      });

      if (consumer.linkConsumers) {
        setNewPatientID(consumer.linkConsumers.id);
        if (patientFlow === PatientFlowType.Yourself) {
          const { idProof, insuranceDetails, ...dateToSetInStore } =
            consumer.linkConsumers;
          updateConsumer(dateToSetInStore);
        }
        setStep(1);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      websocketLoggerService.sendMessage({
        eventType: "Returning Patient Info Link Consumer",
        eventSubType: "API: linkConsumer Error",
        eventData: `Error ${JSON.stringify(error)}`,
      });
      console.log(error);
    }
  };

  const fetchCurrentPatient = async (id) => {
    try {
      const { data } = await fetchCurrentPatientDetails({
        variables: {
          consumer: {
            consumerId: id,
            organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
          },
        },
        context: {
          scope: ScopeType.Consumer,
        },
        fetchPolicy: "no-cache",
      });
      websocketLoggerService.sendMessage({
        eventType: "Returning Patient Form",
        eventSubType: "API Request fetchCurrentPatient success",
        eventData: `consumerId: ${id as string}`,
      });
      setCurrentPatient(data.consumerById);
    } catch (err) {
      websocketLoggerService.sendMessage({
        eventType: "Returning Patient Form",
        eventSubType: "API Request fetchCurrentPatient error",
        eventData: `consumerId: ${id as string} Error : ${JSON.stringify(err)}`,
      });
      loggerService.error("Error while fetching current patient", err);
    }
  };

  const handleSubmit = async (
    values,
    setFieldError,
    setSubmitting,
    setValues
  ) => {
    try {
      if (
        patientType === "newPatient" &&
        (await checkDuplicate(values, setValues))
      ) {
        setSubmitting(false);
        // scroll bottom
        window.scrollTo(0, document.body.scrollHeight);
        return;
      }
      const userTimezone = dateService.getUserTimezone();
      let currentServiceConsumer = serviceConsumer;
      if (!serviceConsumer && patientFlow !== PatientFlowType.Yourself) {
        const { data } = await addServiceConsumer({
          variables: {
            consumer: {
              email: user?.email || null,
              firstName: "",
              timeZone: userTimezone,
              mobileNumber: user?.phone_number || null,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            },
          },
          context: {
            scope: ScopeType.Consumer,
          },
        });
        const response = data.addConsumer;
        currentServiceConsumer = { ...response };
        if (!currentServiceConsumer.timeZone) {
          currentServiceConsumer.timeZone = userTimezone;
        }
        currentServiceConsumer.isGuestUser = false;
        const { idProof, insuranceDetails, ...dateToSetInStore } =
          currentServiceConsumer;
        updateConsumer(dateToSetInStore);
      }

      const {
        firstName,
        lastName,
        email,
        birthDate,
        gender,
        mobileNumber,
        homePhoneNumber,
        patientFrontLicense,
      }: {
        firstName: string;
        lastName: string;
        email: string;
        birthDate: string;
        gender: string;
        mobileNumber: string;
        homePhoneNumber: string;
        patientFrontLicense: string;
      } = values;

      if (patientFlow === PatientFlowType.Dependant) {
        setIsGuardian(true);
      }

      let payload: any = {
        birthDate: currentServiceConsumer?.birthDate
          ? currentServiceConsumer?.birthDate
          : null,
        firstName: currentServiceConsumer.firstName,
        lastName: currentServiceConsumer.lastName,
        email: currentServiceConsumer?.email,
        gender: currentServiceConsumer.gender,
        mobileNumber: currentServiceConsumer.mobileNumber?.slice(2),
        homePhoneNumber: currentServiceConsumer?.homePhoneNumber?.slice(2),
        timeZone: currentServiceConsumer.timeZone,
        id: currentServiceConsumer?.id,
        organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
        dependants: [
          {
            id: currentPatient?.id || null,
            firstName,
            lastName,
            gender,
            mobileNumber,
            homePhoneNumber,
            email,
            birthDate: parseISODateStr(birthDate),
            timeZone: userTimezone,
            organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            isPatient: true,
          },
        ],
      };

      if (patientFlow === PatientFlowType.Yourself) {
        payload = {
          id: currentServiceConsumer?.id,
          firstName,
          lastName,
          gender,
          mobileNumber,
          homePhoneNumber,
          email,
          birthDate: parseISODateStr(birthDate),
          timeZone: currentServiceConsumer.timeZone,
          organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
          idProof: {
            ...currentServiceConsumer?.idProof,
            drivingLicenseFront: patientFrontLicense,
          },
          insuranceDetails: {},
          dependants: [],
          isPatient: true,
        };
      }

      if (payload.mobileNumber) {
        payload.mobileNumber = `+1${payload.mobileNumber as string}`;
      }
      if (payload.homePhoneNumber && payload.homePhoneNumber !== "") {
        payload.homePhoneNumber = `+1${payload.homePhoneNumber as string}`;
      }

      if (
        patientFlow === PatientFlowType.Dependant &&
        payload.dependants[0].mobileNumber
      ) {
        payload.dependants[0].mobileNumber = `+1${String(
          payload.dependants[0].mobileNumber
        )}`;
      }
      if (
        patientFlow === PatientFlowType.Dependant &&
        payload.dependants[0].homePhoneNumber &&
        payload.dependants[0].homePhoneNumber !== ""
      ) {
        payload.dependants[0].homePhoneNumber = `+1${String(
          payload.dependants[0].homePhoneNumber
        )}`;
      }
      if (
        patientFlow === PatientFlowType.Dependant &&
        !payload.dependants[0].mobileNumber
      ) {
        toast.error("Please Provide Patient's Phone number.");
        return;
      }

      let data = null;
      if (patientFlow === PatientFlowType.Yourself && !serviceConsumer) {
        data = await addServiceConsumer({
          variables: {
            consumer: payload,
          },
          context: {
            scope: ScopeType.Consumer,
          },
        });
        data = data.data;
        data.consumer = data.addConsumer;
      } else {
        data = await updateConsumerGuardian({
          variables: {
            consumer: payload,
          },
          context: {
            scope: ScopeType.Consumer,
          },
        });
        data = data.data;
        data.consumer = data.updateConsumer;
      }

      websocketLoggerService.sendMessage({
        eventType: "Returning Patient Form on submit",
        eventSubType: "API updateConsumerGuardian success",
        eventData: `consumerId: ${currentServiceConsumer?.id as string}`,
      });

      const { idProof, insuranceDetails, ...dateToSetInStore } = data.consumer;
      updateConsumer(dateToSetInStore);

      let highestIdObj = null;
      let highestId = -Infinity;
      if (patientFlow === PatientFlowType.Dependant && !currentPatient?.id) {
        for (let i = 0; i < data.consumer.dependants.length; i++) {
          if (data.consumer.dependants[i]?.id > highestId) {
            highestId = data.consumer.dependants[i]?.id;
            highestIdObj = data.consumer.dependants[i];
          }
        }
        setNewPatientID(highestIdObj?.id);
      } else if (patientFlow === PatientFlowType.Yourself) {
        highestIdObj = data.consumer;
        setNewPatientID(currentServiceConsumer?.id);
      } else {
        highestIdObj = data.consumer.dependants.filter((patient) => {
          return patient.id === currentPatient?.id;
        })[0];
        setNewPatientID(currentPatient?.id);
      }

      if (patientFlow === PatientFlowType.Dependant) {
        await updateConsumerDrivingLicense({
          variables: {
            consumer: {
              ...highestIdObj,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
              idProof: {
                ...currentPatient?.idProof,
                drivingLicenseFront: patientFrontLicense,
              },
              insuranceDetails: {},
            },
          },
          context: {
            scope: ScopeType.Consumer,
          },
        });

        websocketLoggerService.sendMessage({
          eventType: "Returning Patient Form on submit",
          eventSubType: "API updateConsumerDrivingLicense success",
          eventData: `consumerId: ${highestIdObj?.id as string}`,
        });
      }

      setStep((prev) => prev + 1);
    } catch (err) {
      websocketLoggerService.sendMessage({
        eventType: "Returning Patient Form on submit",
        eventSubType: "API updateConsumerGuardian failure",
        eventData: `Error ${JSON.stringify(err)}`,
      });
      toast.error("Something went wrong. Please try again.");
      loggerService.error("PEDGuardian", err);
      setSubmitting(false);
    }
  };

  if (!serviceConsumer) return null;

  const handlePatientChange = (value) => {
    if (Number(value) === serviceConsumer.id) {
      // he himself is the patient
      setNewPatientID(serviceConsumer.id);
      fetchCurrentPatient(serviceConsumer.id);
      console.log("returing patient form");

      setPatientFlow(PatientFlowType.Yourself);
    } else {
      // he is the guardian
      const patient = serviceConsumer.dependants.filter((patient) => {
        return patient.id === Number(value);
      });
      setNewPatientID(patient[0].id);
      fetchCurrentPatient(patient[0].id);
      console.log("returng depant");
      setPatientFlow(PatientFlowType.Dependant);
    }
  };

  const handleNextButton = async () => {
    setStep((prev) => prev + 1);
  };

  return (
    <div className="w-3/4 max-lg:w-full">
      <div className="px-4">
        {location.pathname === "/consent/patient-onboarding-3" ? (
          <GuideBanner body="High Intensity Physician Led Advanced Care (HIPLAC). Also know as alternative to Hospital Level care provided in the Home" />
        ) : (
          <GuideBanner body="Add the patient's information who will be using hospital at home services. Healthcare proxy can add their details after patient's information." />
        )}

        <form className="flex bg-bgLightBlue p-4 rounded-[10px] flex-col items-start justify-start gap-2 mt-5">
          <h1 className="font-bold text-xl mt-3">
            {location.pathname === "/consent/patient-onboarding-3"
              ? "Please select the name of the patient that is providing consent to enrollment for our hospital-level care at home?"
              : "Who are you registering for?"}
          </h1>
          <span className="-mt-2 text-gray-light">
            Select registered information or register a new individual
          </span>
          {((serviceConsumer?.isPatient &&
            location.pathname === "/consent/patient-onboarding-3") ||
            location.pathname !== "/consent/patient-onboarding-3") && (
            <div
              className={`flex items-center justify-start gap-2 mt-2 border bg-white p-4  text-md w-full rounded border-primary ${
                patientType === PatientFlowType.Yourself
                  ? "text-primary font-semibold"
                  : "border"
              }`}>
              <input
                data-clarity-mask="True"
                onChange={() => {
                  setPatientType(PatientFlowType.Yourself);
                  setPatientFlow(PatientFlowType.Yourself);
                  setCurrentPatient(serviceConsumer);
                  setNewPatientID(serviceConsumer.id);
                  handlePatientChange(serviceConsumer.id);
                  setDuplicatePatient(null);
                }}
                type="radio"
                id={PatientFlowType.Yourself}
                name="patientType"
                checked={patientType === PatientFlowType.Yourself}
                value={PatientFlowType.Yourself}
                className="text-primary"
              />
              <label className="w-full" htmlFor={PatientFlowType.Yourself}>
                <span className="font-normal">Yourself</span>
                {serviceConsumer?.firstName && " : "}
                {serviceConsumer?.firstName} {serviceConsumer?.lastName}
              </label>
            </div>
          )}
          <div className="relative flex py-2 items-center w-full">
            <div className="flex-grow border-t border-lightBlue"></div>
            <span className="flex-shrink mx-4 text-black">OR</span>
            <div
              className="flex-grow border-t border-lightBlue
            "></div>
          </div>
          <h2 className="text-black font-semibold">Select Existing Patient</h2>
          {serviceConsumer.dependants.map((dependant, index) => {
            return (
              <div
                key={index}
                className={`flex items-center justify-start gap-2 mt-1 border p-4 font-bold text-md w-full rounded bg-white border-primary ${
                  patientType === PatientFlowType.Dependant &&
                  dependant.id === newPatientID
                    ? "text-primary font-semibold"
                    : "border"
                }`}>
                <input
                  data-clarity-mask="True"
                  onChange={() => {
                    setPatientType(PatientFlowType.Dependant);
                    setPatientFlow(PatientFlowType.Dependant);
                    setCurrentPatient(dependant);
                    setNewPatientID(dependant.id);
                    handlePatientChange(dependant.id);
                    setDuplicatePatient(null);
                  }}
                  checked={
                    patientType === PatientFlowType.Dependant &&
                    dependant.id === newPatientID
                  }
                  type="radio"
                  id={dependant.id}
                  name="patientType"
                  value="existingDependant"
                />
                <label className="w-full" htmlFor={dependant.id}>
                  <span className="font-normal">
                    {dependant.firstName} {dependant.lastName}
                  </span>
                </label>
              </div>
            );
          })}
          {location.pathname !== "/consent/patient-onboarding-3" && (
            <>
              <div className="relative flex py-2 items-center w-full">
                <div className="flex-grow border-t border-lightBlue"></div>
                <span className="flex-shrink mx-4 text-black">OR</span>
                <div className="flex-grow border-t border-lightBlue"></div>
              </div>
              <span className="text-black font-semibold">Add Someone Else</span>
              <div
                className={`flex items-center justify-start gap-2 mt-2 border p-4 font-bold text-md w-full rounded bg-white border-primary ${
                  patientType === "newPatient"
                    ? "text-primary font-semibold"
                    : "border"
                }`}>
                <input
                  data-clarity-mask="True"
                  onChange={() => {
                    setPatientType("newPatient");
                    setPatientFlow(PatientFlowType.Dependant);
                    setCurrentPatient(null);
                    setNewPatientID(null);
                    setDuplicatePatient(null);
                  }}
                  type="radio"
                  id="NewPatient"
                  name="patientType"
                  checked={patientType === "newPatient"}
                  value="NewPatient"
                />
                <label className="w-full" htmlFor="NewPatient">
                  <span className="font-normal">
                    Add New Patient &nbsp; &nbsp;
                  </span>
                  {/* New Patient */}
                </label>
              </div>
            </>
          )}
        </form>

        <hr className="my-6" />

        <main className="mt-6">
          <Formik
            initialValues={{
              id: currentPatient?.id || "",
              firstName:
                (!currentPatient?.isGuestUser && currentPatient?.firstName) ||
                "",
              lastName: currentPatient?.lastName || "",
              email:
                (!currentPatient?.isGuestUser &&
                  currentPatient?.email !== "none" &&
                  currentPatient?.email) ||
                "",
              birthDate:
                (currentPatient?.birthDate &&
                  !currentPatient?.isGuestUser &&
                  parseDOB(currentPatient?.birthDate)) ||
                null,
              gender:
                (!currentPatient?.isGuestUser &&
                  currentPatient?.gender !== "UNKNOWN" &&
                  currentPatient?.gender) ||
                "",
              mobileNumber:
                (!currentPatient?.isGuestUser &&
                  currentPatient?.mobileNumber?.slice(2)) ||
                "",
              homePhoneNumber:
                (!currentPatient?.isGuestUser &&
                  currentPatient?.homePhoneNumber?.slice(2)) ||
                "",
              patientFrontLicense:
                currentPatient?.idProof?.drivingLicenseFront || "",
              verificationType: "",
              otp: "",
              otpSent: false,
              care2uOtp: "",
            }}
            enableReinitialize
            validationSchema={formSchema}
            validateOnChange={false}
            // validateOnBlur={false}
            onSubmit={(values, { setSubmitting, setFieldError, setValues }) => {
              handleSubmit(values, setFieldError, setSubmitting, setValues);
            }}>
            {({
              values,
              initialValues,
              isSubmitting,
              errors,
              setValues,
              setFieldValue,
              setFieldError,
            }) => (
              <Form className="flex flex-col gap-4">
                <section
                  className={`flex flex-col gap-4 ${
                    duplicatePatient ? "max-lg:mb-0" : "max-lg:mb-32"
                  }`}>
                  {(currentPatient || patientType === "newPatient") &&
                    !hideForm && (
                      <>
                        <h1 className="font-bold text-3xl">
                          Review Patient Details
                        </h1>
                        <div className="flex items-center mt-2 gap-10">
                          <p className="text-primary  uppercase text-sm ">
                            Patient Details
                          </p>
                          <div className="w-full h-[2px] bg-primary/60 flex-1"></div>
                        </div>
                        <div className="flex gap-6 items-start max-sm:flex-col max-sm:w-full max-sm:gap-3">
                          <FormikInput
                            name="firstName"
                            placeholder="Enter First Name"
                            type="text"
                            label="Patient First Name"
                            value={values.firstName}
                            onChange={(e) => {
                              setFieldValue("firstName", e.target.value.trim());
                            }}
                          />
                          <FormikInput
                            name="lastName"
                            placeholder="Enter Last Name"
                            type="text"
                            label="Patient Last Name"
                            value={values.lastName}
                            onChange={(e) => {
                              setFieldValue("lastName", e.target.value.trim());
                            }}
                          />
                        </div>
                        <FormikInput
                          name="email"
                          placeholder="Enter Email"
                          type="email"
                          disabled={
                            initialValues.email !== "" &&
                            initialValues.email !== "none" &&
                            !serviceConsumer?.isGuestUser
                          }
                          label="Patient Email"
                          value={values.email}
                        />
                        <div className="flex gap-6 items-start max-sm:flex-col max-sm:w-full max-sm:gap-3">
                          <FormikInput
                            name="birthDate"
                            placeholder="MM/DD/YYYY"
                            type="date"
                            label="Patient Date of Birth"
                            component={DateSeparateInput}
                            value={values.birthDate}
                          />

                          <FormikInput
                            name="gender"
                            placeholder="Select"
                            type="text"
                            label="Patient Sex Assigned at Birth"
                            value={values.gender}
                            component="select">
                            <option className="text-gray-400" value="" disabled>
                              Select
                            </option>
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                          </FormikInput>
                        </div>
                        <FormikInput
                          name="mobileNumber"
                          placeholder="000 000 0000"
                          type="tel"
                          onInput={(e) =>
                            (e.target.value = e.target.value.replace(
                              /[^\d+]/g,
                              ""
                            ))
                          }
                          maxLength="10"
                          disabled={
                            !serviceConsumer?.isGuestUser &&
                            initialValues.mobileNumber !== ""
                          }
                          label="Patient Mobile Phone Number"
                          value={values.mobileNumber}
                        />
                        <FormikInput
                          name="homePhoneNumber"
                          placeholder="000 000 0000"
                          onInput={(e) =>
                            (e.target.value = e.target.value.replace(
                              /[^\d+]/g,
                              ""
                            ))
                          }
                          type="tel"
                          maxLength="10"
                          label="Patient Home Phone Number"
                          value={values.homePhoneNumber}
                        />
                        <div className="flex items-center mt-4 mb-2 gap-10">
                          <p className="text-primary  uppercase text-sm ">
                            Patient's proof of Identity
                          </p>
                          <div className="w-full h-[2px] bg-primary/60 flex-1"></div>
                        </div>
                        <div>
                          <label className="mb-0 text-sm font-semibold ">
                            Front of Patient's Driver's License
                            <span className="text-red-500 text-base">*</span>
                          </label>
                          <Field
                            type="file"
                            data-clarity-mask="True"
                            id="patientFrontLicense"
                            name="patientFrontLicense"
                            placeholder="Front of Patient's Driver's License"
                            component={UploadFile}
                          />
                          <ErrorMessage name="patientFrontLicense">
                            {(msg) => <FieldError message={msg} />}
                          </ErrorMessage>
                        </div>
                      </>
                    )}
                </section>

                {hideForm ? (
                  <div
                    className="max-lg:fixed max-lg:bottom-0 max-lg:left-0
                max-lg:px-5 bg-white max-lg:w-full">
                    <button
                      type="button"
                      ref={NextBtn}
                      disabled={!currentPatient && patientType !== "newPatient"}
                      onClick={handleNextButton}
                      className="w-full bg-black px-20 py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16 ">
                      Next Up : HIPLAC Consent
                    </button>
                  </div>
                ) : (
                  <div
                    className="max-lg:fixed max-lg:bottom-0 max-lg:left-0
                max-lg:px-5 bg-white max-lg:w-full">
                    <button
                      type="submit"
                      ref={NextBtn}
                      disabled={
                        (!currentPatient && patientType !== "newPatient") ||
                        !isValidDate ||
                        isSubmitting
                      }
                      className="w-full bg-black px-20 max-sm:px-4 py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16 ">
                      {isSubmitting ? (
                        <div className="flex gap-2 items-center">
                          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                          <div>Submitting...</div>
                        </div>
                      ) : patientFlow === PatientFlowType.Yourself ? (
                        "Next Up : Insurance Details"
                      ) : (
                        "Next Up : Healthcare Proxy Details"
                      )}
                    </button>
                  </div>
                )}

                {duplicatePatient && (
                  <div className=" items-center my-6 gap-10 max-lg:pb-36">
                    <h1 className="text-2xl font-bold">
                      Looks like this is an existing patient
                    </h1>

                    {duplicatePatient?.id && (
                      <>
                        <div className="flex flex-col mt-4 text-lg">
                          <span className="p-4 bg-orange-light leading-7 rounded">
                            If you have access to the registered patient phone
                            (+1 *** ****
                            {duplicatePatient.mobileNumber.slice(-4)}) then you
                            can verify the patient identity and proceed using
                            the first option (Option 1)
                          </span>
                          <div className="flex items-start justify-start gap-2 mt-4 p-2 border rounded">
                            <input
                              type="radio"
                              name="verification-type"
                              id=""
                              checked={values.verificationType === "option1"}
                              onChange={async () =>
                                await setValues({
                                  ...values,
                                  verificationType: "option1",
                                })
                              }
                              className="mt-2"
                            />
                            <label>
                              <div className="flex flex-col gap-2">
                                <span className="font-bold text-primary">
                                  Option 1:
                                </span>
                                <span className="font-bold">
                                  Send One Time Pass Code (OTP) on patient’s
                                  registered mobile number. (+1******
                                  {duplicatePatient.mobileNumber.slice(-4)})
                                </span>
                                <span>
                                  OTP will be sent on patient’s registered
                                  mobile number +1 *** ***{" "}
                                  {duplicatePatient.mobileNumber.slice(-4)}
                                </span>
                              </div>
                            </label>
                          </div>
                          {values.verificationType === "option1" && (
                            <div className="p-4 bg-grey-light mt-4 rounded flex flex-col justify-start">
                              <span className="text-xl font-bold">
                                Verify Patient Phone Number Using One Time Pass
                                Code
                              </span>
                              <span className="mt-2">
                                We will send a verification code on patient’s
                                previously registered mobile number +1 *** ***
                                {duplicatePatient.mobileNumber.slice(-4)}
                              </span>
                              {!values.otpSent && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleSendOTP(values, setValues);
                                  }}
                                  className="bg-primary px-10 mt-4 py-4 max-w-max rounded text-white">
                                  Send OTP
                                </button>
                              )}
                              {/* Form to be shown when OTP is triggered */}
                              {values.otpSent && (
                                <>
                                  <div className="flex flex-col">
                                    <label
                                      htmlFor=""
                                      className="text-primary font-bold mt-4">
                                      Enter patient's One Time Pass Code
                                    </label>
                                    <div>
                                      <Field
                                        type="text"
                                        pattern="\d{6}"
                                        inputmode="numeric"
                                        onChange={(e) => {
                                          if (isNaN(e.target.value)) return;
                                          setValues({
                                            ...values,
                                            otp: e.target.value,
                                          });
                                        }}
                                        name="otp"
                                        onWheel={(e) => e.target.blur()}
                                        id="otp"
                                        maxLength="6"
                                        placeholder="Enter patient's One Time Pass Code"
                                        className="rounded border border-primary py-4 w-3/4 mt-2 focus:border-1"
                                      />

                                      <ErrorMessage name="otp">
                                        {(msg) => <FieldError message={msg} />}
                                      </ErrorMessage>
                                    </div>
                                  </div>
                                  <button
                                    onClick={async () =>
                                      await handleSendOTP(values, setValues)
                                    }
                                    disabled={isRunning}
                                    className="mt-4 text-primary flex gap-2 items-center disabled:text-gray-400">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="none"
                                      viewBox="0 0 24 24">
                                      <g clipPath="url(#clip0_2556_73289)">
                                        <path
                                          fill={isRunning ? "gray" : "#1E7FC2"}
                                          d="M5.463 4.433A9.961 9.961 0 0112 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 006.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0112 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0013.54 5.772l.997 1.795z"></path>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_2556_73289">
                                          <path
                                            fill="#fff"
                                            d="M0 0H24V24H0z"></path>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    <div>
                                      <b>Resend Code</b>{" "}
                                      {isRunning && `in ${minutes}:${seconds}`}
                                    </div>
                                  </button>
                                  <button
                                    type="button"
                                    onClick={async () => {
                                      if (values.otp)
                                        await handleVerifyOTP(values);
                                      else {
                                        setFieldError(
                                          "otp",
                                          "Please enter OTP"
                                        );
                                      }
                                    }}
                                    className="bg-primary px-10 mt-4 py-4 max-w-max rounded text-white">
                                    Verify To Proceed
                                  </button>
                                </>
                              )}
                              <hr className="my-4" />
                              <span>
                                Previously registered patient phone number does
                                not match ?
                              </span>
                              <span className="text-primary font-bold">
                                Follow Option 2 below
                              </span>
                            </div>
                          )}
                        </div>
                        <div className=" flex py-5 items-center">
                          <div className="flex-grow border-t border-lightBlue"></div>
                          <span className="flex-shrink mx-4 text-black">
                            OR
                          </span>
                          <div className="flex-grow border-t border-lightBlue"></div>
                        </div>
                      </>
                    )}
                    <div
                      className={`flex flex-col ${
                        duplicatePatient?.id ? "" : "mt-4"
                      }`}>
                      {duplicatePatient?.id ? (
                        <span className="p-4 bg-orange-light leading-7 rounded text-lg">
                          However if you don’t have access to the registered
                          patient phone number (+1*******
                          {duplicatePatient.mobileNumber.slice(-4)}) or need to
                          update the phone number or other patient demographics
                          please reach out to hospital at home services Team on
                          below phone number in Option 2
                        </span>
                      ) : (
                        <span className="p-4 bg-orange-light leading-7 rounded text-lg">
                          It looks like this patient already exists or has
                          multiple entries, please reach out to hospital at home
                          services team using the below option for the next
                          steps.
                        </span>
                      )}
                      <div
                        className={`flex items-start justify-start gap-2 mt-4 p-2 ${
                          duplicatePatient?.id ? "border" : ""
                        } rounded`}>
                        <input
                          type="radio"
                          name="verification-type"
                          checked={values.verificationType === "option2"}
                          id=""
                          onChange={async () =>
                            await setValues({
                              ...values,
                              verificationType: "option2",
                            })
                          }
                          className={`mt-1 ${
                            duplicatePatient?.id ? "" : "hidden"
                          }`}
                        />
                        <label>
                          <div className="flex flex-col gap-2 text-lg">
                            {duplicatePatient?.id && (
                              <p className="font-bold text-primary">
                                Option 2:
                              </p>
                            )}
                            <p className="font-bold">
                              Please call the hospital at home services team at
                              +1 (877) 247-5428 for patient identity
                              confirmation
                            </p>
                          </div>
                        </label>
                      </div>
                    </div>
                    {values.verificationType === "option2" && (
                      <div className="p-4 bg-grey-light mt-4 rounded flex flex-col justify-start">
                        <span className="text-xl font-bold">
                          Call hospital at home services Team for below details
                        </span>
                        <span className="mt-2 leading-7">
                          Please reach out to hospital at home services team
                          member at
                          <b> +1 (877) 247-5428 </b> to get the one of the below
                          verification detail
                        </span>
                        <div className="flex flex-col">
                          <label
                            htmlFor=""
                            className="text-primary font-bold mt-4">
                            Enter 6 Digit One Time Code
                          </label>
                          <Field
                            type="number"
                            name="otp"
                            onWheel={(e) => e.target.blur()}
                            id="otp"
                            onChange={(e) => {
                              if (isNaN(e.target.value)) return;
                              setValues({
                                ...values,
                                otp: e.target.value,
                              });
                            }}
                            maxLength="6"
                            placeholder="Enter patient's One Time Pass Code"
                            className="rounded border border-primary py-4 w-3/4 mt-2 focus:border-1"
                          />

                          <ErrorMessage name="otp">
                            {(msg) => <FieldError message={msg} />}
                          </ErrorMessage>
                        </div>
                        <button
                          type="button"
                          onClick={async () =>
                            await handleVerifyOTP(values, true)
                          }
                          className="bg-primary px-10 mt-4 py-4 max-w-max rounded text-white">
                          Verify to Proceed
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </main>
      </div>
    </div>
  );
};

export default ReturningPatientForm;
