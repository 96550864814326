import { FormikErrors } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";

const specialists = [
  "Primary Care",
  "Psychiatry",
  "Cardiology",
  "Pediatric",
  "Rheumatology",
  "Oncology",
  "Ophthalmology",
  "Neurology",
  "Urology",
];

interface scheduleSessionSpecialtyProps {
  value: any;
  setCurrentOverlay: React.Dispatch<React.SetStateAction<string>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<{
    visitType: string;
    location: string;
    preferredDate: string;
    specialty: string;
    visitReason: string;
  }>>;
}

const ScheduleSessionSpecialty: React.FC<scheduleSessionSpecialtyProps> = ({
  value,
  setCurrentOverlay,
  setFieldValue,
}) => {
  const [selectedSpecialist, setSelectedSpecialist] = useState<string>(
    value.specialty
  );
  const [specialistFilter, setSpecialistFilter] = useState<string>("");

  const handleSubmitSpecialty = () => {
    if (selectedSpecialist === "") {
      toast.error("Please select a specialty");
      return;
    }
    setFieldValue("specialty", selectedSpecialist);
    setCurrentOverlay("");
  };

  return (
    <div className="mt-4 px-20 max-lg:px-0">
      {/* Heading */}
      <p className="max-lg:flex hidden items-center justify-center gap-3 text-primary ">
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_5913_49695)">
            <path
              d="M9 16.1749L12.7125 12.4624C13.4467 11.7282 13.9466 10.7927 14.1492 9.77435C14.3517 8.75596 14.2477 7.70039 13.8503 6.74111C13.4529 5.78183 12.78 4.96192 11.9167 4.38507C11.0533 3.80821 10.0383 3.50032 9 3.50032C7.96167 3.50032 6.94666 3.80821 6.08332 4.38507C5.21997 4.96192 4.54706 5.78183 4.14969 6.74111C3.75231 7.70039 3.64831 8.75596 3.85084 9.77435C4.05337 10.7927 4.55333 11.7282 5.2875 12.4624L9 16.1749ZM9 18.2959L4.227 13.5229C3.28301 12.5789 2.64014 11.3762 2.3797 10.0668C2.11925 8.75746 2.25293 7.40026 2.76382 6.16687C3.27472 4.93347 4.13988 3.87927 5.24991 3.13757C6.35994 2.39588 7.66498 2 9 2C10.335 2 11.6401 2.39588 12.7501 3.13757C13.8601 3.87927 14.7253 4.93347 15.2362 6.16687C15.7471 7.40026 15.8808 8.75746 15.6203 10.0668C15.3599 11.3762 14.717 12.5789 13.773 13.5229L9 18.2959ZM9 10.2499C9.39783 10.2499 9.77936 10.0919 10.0607 9.8106C10.342 9.5293 10.5 9.14777 10.5 8.74994C10.5 8.35212 10.342 7.97059 10.0607 7.68928C9.77936 7.40798 9.39783 7.24994 9 7.24994C8.60218 7.24994 8.22065 7.40798 7.93934 7.68928C7.65804 7.97059 7.5 8.35212 7.5 8.74994C7.5 9.14777 7.65804 9.5293 7.93934 9.8106C8.22065 10.0919 8.60218 10.2499 9 10.2499ZM9 11.7499C8.20435 11.7499 7.44129 11.4339 6.87868 10.8713C6.31607 10.3087 6 9.54559 6 8.74994C6 7.95429 6.31607 7.19123 6.87868 6.62862C7.44129 6.06601 8.20435 5.74994 9 5.74994C9.79565 5.74994 10.5587 6.06601 11.1213 6.62862C11.6839 7.19123 12 7.95429 12 8.74994C12 9.54559 11.6839 10.3087 11.1213 10.8713C10.5587 11.4339 9.79565 11.7499 9 11.7499Z"
              fill="#3537E8"
            />
          </g>
          <defs>
            <clipPath id="clip0_5913_49695">
              <rect
                width="18"
                height="18"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
        Select Specialty
      </p>
      <p className="max-lg:text-center font-semibold mt-5 mx-4">
        Type of doctor you would like to visit
      </p>

      {/* Search  */}

      <div className="mx-3 relative">
        <input
          type="text"
          placeholder="Search by condition / specialist "
          className="border border-lightBlue rounded-lg w-full h-14 mt-4 placeholder-lightBlue"
          value={specialistFilter}
          onChange={(e) => setSpecialistFilter(e.target.value)}
        />
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-8 right-4">
          <path
            d="M18.031 16.617L22.314 20.899L20.899 22.314L16.617 18.031C15.0237 19.3082 13.042 20.0029 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20.0029 13.042 19.3082 15.0237 18.031 16.617ZM16.025 15.875C17.2941 14.5699 18.0029 12.8204 18 11C18 7.132 14.867 4 11 4C7.132 4 4 7.132 4 11C4 14.867 7.132 18 11 18C12.8204 18.0029 14.5699 17.2941 15.875 16.025L16.025 15.875Z"
            fill="#09121F"
          />
        </svg>
      </div>

      {/* Specialists List */}
      <div className="flex flex-col p-4 h-72 max-lg:h-auto overflow-y-auto">
        <p className="font-semibold text-lg">Specialty</p>
        <div className="flex flex-col gap-2">
          {specialists
            .filter((specialist) => {
              if (specialistFilter === "") return true;
              return specialist
                .toLowerCase()
                .startsWith(specialistFilter.toLowerCase());
            })
            .map((specialist, index) => (
              <div
                key={index}
                onClick={() => setSelectedSpecialist(specialist)}
                className={`border-b py-4 flex items-center justify-between ${
                  selectedSpecialist === specialist
                    ? "border-primary text-primary font-semibold"
                    : "border-gray-300"
                }`}>
                <p>{specialist}</p>
                {selectedSpecialist === specialist && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_5961_72877)">
                      <path
                        d="M9.99974 15.1715L19.1917 5.97852L20.6067 7.39252L9.99974 17.9995L3.63574 11.6355L5.04974 10.2215L9.99974 15.1715Z"
                        fill="#3537E8"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5961_72877">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>
            ))}
        </div>
      </div>

      <div className="bg-white py-2 sticky -bottom-2">
        <button
          type="button"
          className="w-[90%]  mx-4 bg-black px-20 max-sm:px-4 py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16"
          onClick={handleSubmitSpecialty}>
          Done
        </button>
      </div>
    </div>
  );
};

export default ScheduleSessionSpecialty;
