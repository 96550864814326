import { Link } from "react-router-dom";

const AppointmentSuccess = () => {
  return (
    <div className="mt-5 flex flex-col justify-center items-center gap-y-2">
      <h1>Thank You.</h1>
      <p>Your appointment has ended.</p>
      <Link to="/schedule" className="text-care2u flex items-center gap-x-3">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.8327 10H4.16602"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.99935 15.8333L4.16602 9.99996L9.99935 4.16663"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Go back to home page
      </Link>
    </div>
  );
};

export default AppointmentSuccess;
