import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET_AGREEMENT_TEMPLATE, GET_CONSUMER_BY_ID } from "../../apollo";
import { IServiceConsumer } from "../../interfaces";
import { loggerService } from "../../services";
import websocketLoggerService from "../../services/websocket-logger";
import useFormStore from "../../store/form/form.store";
import { ScopeType } from "../shared/config";
import { useServiceConsumer } from "../shared/hooks";
import { helper } from "../shared/utils";
import TermsConsentForm from "./terms-and-condition-form";
import TermsAndConditionCard from "./terms-and-conditions-card.component";

interface ITermsAndConditionProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  haveInsurance: boolean;
}

const TermsAndCondition: React.FC<ITermsAndConditionProps> = ({
  setStep,
  haveInsurance,
}) => {
  const [agreementCompletedCount, setAgreementCompletedCount] = useState(1);
  const location = useLocation();
  const [TermsAndConditionAgreements, setTermsAndConditionAgreements] =
    useState([]);
  const [patientFlow] = useFormStore((state) => [state.patientFlow]);
  const [currentTemplate, setCurrentTemplate] = useState(1);
  const [consentInputs, setConsentInputs] = useState({
    patientname: null,
    relationship: null,
    address: null,
    dob: null,
    notifier: null,
    identificationNumber: null,
  });

  const { data: queryResponse, loading } = useQuery(GET_AGREEMENT_TEMPLATE, {
    variables: {
      agreementTemplateFilter: {
        organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
      },
    },
    context: {
      scope: ScopeType.Consumer,
    },
  });

  websocketLoggerService.sendMessage({
    eventType: "Terms and Conditions",
    eventSubType: "API Request: GET_AGREEMENT_TEMPLATE",
    eventData: `organizationId: ${process.env.REACT_APP_ORGANIZATION_ID}`,
  });

  const [getConsumerByIdPatient] = useLazyQuery<{
    getConsumerById: IServiceConsumer;
  }>(GET_CONSUMER_BY_ID, {
    context: {
      scope: ScopeType.Consumer,
    },
  });

  const [newPatientId] = useFormStore((state) => [state.newPatientID]);
  const serviceConsumer = useServiceConsumer();
  const [newPatient, setNewPatient] = useState(null);

  const formTypeLocation = [
    "/consent/patient-onboarding-1",
    "/consent/patient-onboarding-3",
    "/consent/patient-onboarding-7",
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: patientData } = await getConsumerByIdPatient({
          variables: {
            consumer: {
              consumerId: newPatientId,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            },
          },
          fetchPolicy: "no-cache",
        });

        const response = helper.getPropertyValue<IServiceConsumer>(
          patientData,
          "consumerById"
        );
        setNewPatient(response);

        websocketLoggerService.sendMessage({
          eventType: "Terms and Conditions",
          eventSubType: "API Request: GET_CONSUMER_BY_ID success",
          eventData: `consumerId: ${newPatientId}, organizationId: ${process.env.REACT_APP_ORGANIZATION_ID}`,
        });
      } catch (error) {
        websocketLoggerService.sendMessage({
          eventType: "Terms and Conditions",
          eventSubType: "Error on API Request: GET_CONSUMER_BY_ID Failed",
          eventData: `Error: ${JSON.stringify(error)}`,
        });
        loggerService.error(error);
      }
    };
    if (newPatientId) {
      getData();
    }
  }, [getConsumerByIdPatient, newPatientId, setStep]);

  useEffect(() => {
    if (!queryResponse) return;

    const arrayofElements = !haveInsurance
      ? queryResponse?.getAgreementTemplate?.filter((data) => {
          if (location.pathname !== "/consent/patient-onboarding-3") {
            return (
              data.name ===
                "Patient Attestation of No Insurance / Out of Network Insurance" ||
              data.name === "Privacy Policy"
            );
          }
          return data.name === "Privacy Policy";
        })
      : queryResponse?.getAgreementTemplate?.filter((data) => {
          return data.name === "Privacy Policy";
        });

    if (location.pathname === "/consent/patient-onboarding-1") {
      setTermsAndConditionAgreements([
        ...arrayofElements,
        ...queryResponse?.getAgreementTemplate?.filter((data) => {
          return (
            data.name ===
              "Care 2U Acknowledgment of Receipt of Notice of Privacy Practices; Consent to Use and Disclosure of PHI" ||
            data.name === "Credit Card on File Authorization Form"
          );
        }),
      ]);
    } else if (location.pathname === "/consent/patient-onboarding-2") {
      setTermsAndConditionAgreements([
        ...arrayofElements,
        ...queryResponse?.getAgreementTemplate?.filter((data) => {
          return (
            data.name ===
              "Care 2U Acknowledgment of Receipt of Notice of Privacy Practices; Consent to Use and Disclosure of PHI" ||
            data.name === "Advance Beneficiary Notice of Non-coverage (ABN)"
          );
        }),
      ]);
    } else if (location.pathname === "/consent/patient-onboarding-3") {
      setTermsAndConditionAgreements([
        ...arrayofElements,
        ...queryResponse?.getAgreementTemplate?.filter((data) => {
          return data.name === "HIPLAC Consent";
        }),
      ]);
    } else if (location.pathname === "/consent/patient-onboarding-4") {
      setTermsAndConditionAgreements([
        ...arrayofElements,
        ...queryResponse?.getAgreementTemplate?.filter((data) => {
          return (
            data.name ===
              "Care 2U Acknowledgment of Receipt of Notice of Privacy Practices; Consent to Use and Disclosure of PHI" ||
            data.name === "HIPAA" ||
            data.name === "Credit Card on File Authorization Form"
          );
        }),
      ]);
    } else if (location.pathname === "/consent/patient-onboarding-5") {
      setTermsAndConditionAgreements([
        ...arrayofElements,
        ...queryResponse?.getAgreementTemplate?.filter((data) => {
          return (
            data.name ===
              "Care 2U Acknowledgment of Receipt of Notice of Privacy Practices; Consent to Use and Disclosure of PHI" ||
            (data.name ===
              "Advance Beneficiary Notice of Non-coverage (ABN)-DLFEE" &&
              haveInsurance) ||
            data.name === "Credit Card on File Authorization Form"
          );
        }),
      ]);
    } else if (location.pathname === "/consent/patient-onboarding-6") {
      setTermsAndConditionAgreements([
        ...arrayofElements,
        ...queryResponse?.getAgreementTemplate?.filter((data) => {
          return (
            data.name ===
              "Care 2U Acknowledgment of Receipt of Notice of Privacy Practices; Consent to Use and Disclosure of PHI" ||
            (data.name ===
              "Advance Beneficiary Notice of Non-coverage (ABN)-ALF" &&
              haveInsurance) ||
            data.name === "Credit Card on File Authorization Form"
          );
        }),
      ]);
    } else if (location.pathname === "/consent/patient-onboarding-7") {
      setTermsAndConditionAgreements([
        ...arrayofElements,
        ...queryResponse?.getAgreementTemplate?.filter((data) => {
          return (
            data.name ===
              "Care 2U Acknowledgment of Receipt of Notice of Privacy Practices; Consent to Use and Disclosure of PHI" ||
            (data.name ===
              "Patient Financial Responsibility for Non-Covered Services Form-DLFEE" &&
              haveInsurance) ||
            data.name === "Credit Card on File Authorization Form"
          );
        }),
      ]);
    } else if (location.pathname === "/add-patient-details") {
      setTermsAndConditionAgreements([
        ...arrayofElements,
        ...queryResponse?.getAgreementTemplate?.filter((data) => {
          return (
            data.name === "Patient Informed Consent" ||
            (data.name ===
              "Patient Financial Responsibility for Non-Covered Services Form-DLFEE" &&
              haveInsurance) ||
            data.name === "Credit Card on File Authorization Form"
          );
        }),
      ]);
    } else {
      setTermsAndConditionAgreements([
        ...arrayofElements,
        ...queryResponse?.getAgreementTemplate?.filter((data) => {
          return (
            data.name ===
              "Care 2U Acknowledgment of Receipt of Notice of Privacy Practices; Consent to Use and Disclosure of PHI" ||
            (data.name ===
              "Patient Financial Responsibility for Non-Covered Services Form-ALF" &&
              haveInsurance) ||
            data.name === "Credit Card on File Authorization Form"
          );
        }),
      ]);
    }
  }, [haveInsurance, location.pathname, queryResponse]);

  return (
    <div className="w-3/4 flex flex-col items-start max-lg:w-full max-lg:px-3">
      {newPatient ? (
        <div className="w-full border border-gray-200 p-4 rounded-md mb-8 flex items-center justify-between gap-2">
          <div className="flex items-center gap-4 text-lg">
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle
                cx="9.5"
                cy="10.2206"
                r="9.5"
                className="fill-lightBlue"
              />
              <path
                d="M4.46875 10.7233L7.66928 13.8527L14.5276 7.14685"
                stroke="white"
                strokeWidth="1.5"
              />
            </svg>

            <p className="flex justify-between ">
              {newPatient?.firstName} {newPatient?.lastName}
            </p>
          </div>
          <span className="text-gray-400">Patient</span>
        </div>
      ) : (
        <p className="mb-10">Loading...</p>
      )}

      <h1 className="text-xl self-start font-semibold tracking-wide text-black md:text-2xl max-lg:px-4">
        Sign Consent Forms
      </h1>

      <div className="flex flex-col items-center  h-full w-full mb-5 overflow-y-auto">
        {TermsAndConditionAgreements?.[0] !== undefined &&
          TermsAndConditionAgreements.map((data, index) => {
            if (
              data.name !== "Credit Card on File Authorization Form" &&
              data.name !== "Privacy Policy"
            ) {
              return (
                <TermsAndConditionCard
                  key={index}
                  index={index}
                  currentTemplate={currentTemplate}
                  setCurrentTemplate={setCurrentTemplate}
                  patient={newPatient}
                  agreementData={data}
                  consentInputs={consentInputs}
                  setAgreementCompletedCount={setAgreementCompletedCount}
                />
              );
            }
            return null;
          })}
      </div>

      {!loading && (
        <div>
          <p className="font-semibold text-xl mb-5">
            Patient or Legal Representative details
          </p>

          <TermsConsentForm
            newPatient={newPatient}
            newPatientId={newPatientId}
            serviceConsumer={serviceConsumer}
            TermsAndConditionAgreements={TermsAndConditionAgreements}
            agreementCompletedCount={agreementCompletedCount}
            setStep={setStep}
            haveInsurance={haveInsurance}
            patientFlow={patientFlow}
            setConsentInputs={setConsentInputs}
            consentFormType={
              formTypeLocation.includes(location.pathname) ? 1 : 2
            }
            setAgreementCompletedCount={setAgreementCompletedCount}
          />
        </div>
      )}
    </div>
  );
};

export default TermsAndCondition;
