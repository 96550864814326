// components/FormikReactSelect.tsx
import { useField, useFormikContext } from "formik";
import Select from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

interface MyOption {
  label: string;
  value: any;
}
// define the group option type
interface GroupedOption {
  label: string; // group label
  options: MyOption[];
}
// component props
type Props = {
  name: string;
} & Omit<
  StateManagerProps<MyOption, false | true, GroupedOption>,
  "value" | "onChange"
>;

const FormikReactSelect = (props: Props) => {
  const { name, ...restProps } = props;
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  // flatten the options so that it will be easier to find the value
  const flattenedOptions = props.options?.flatMap((o) => {
    const isNotGrouped = "value" in o;
    if (isNotGrouped) {
      return o;
    } else {
      return o.options;
    }
  });

  // get the value using flattenedOptions and field.value
  const value = flattenedOptions?.filter((o) => {
    const isArrayValue = Array.isArray(field.value);

    if (isArrayValue) {
      const values = field.value as any[];
      return values.includes(o.value);
    } else {
      return field.value === o.value;
    }
  });

  return (
    <Select
      {...restProps}
      value={value}
      placeholder="Select State"
      // onChange implementation
      onChange={(val) => {
        // here I used explicit typing but there maybe a better way to type the value.
        const _val = val as MyOption[] | MyOption;
        const isArray = Array.isArray(_val);
        if (isArray) {
          const values = _val.map((o) => o.value);
          setFieldValue(name, values);
        } else {
          setFieldValue(name, _val.value);
        }
      }}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          padding: "0.35rem 0rem",
          borderRadius: "0.375rem",
          border: "1px solid #919BF3",
          borderColor: state.isFocused ? "#3537E8" : "#919BF3",
          // outline none
          boxShadow: "none",
          outline: "none",
          outlineColor: "#3537E8",
        }),
      }}
    />
  );
};

export default FormikReactSelect;
