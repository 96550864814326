import { ApolloProvider } from "@apollo/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { TourProvider } from "@reactour/tour";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { client } from "../apollo";
import { DEFAULT_LOCALE, messages } from "../i18n";
import { useConsumerStore } from "../store";
import useLangStore from "../store/lang/lang.store";
import { Header } from "./shared/components";
import { useAuthenticated } from "./shared/hooks";
import auth0Service from "./shared/utils/auth0-token-expire";

const steps = [
  {
    selector: "#first-step",
    content: "This is my first Step",
  },
  {
    selector: "#second-step",
    content: "This is my second Step",
  },
];

function App() {
  const isAuthenticated = useAuthenticated();
  const [showHeader, setShowHeader] = useState(true);
  const lang = useLangStore((state) => state.value);
  const locale = lang;
  const location = useLocation();

  useEffect(() => {

    if (isAuthenticated && window.location.pathname !== "/verifyemail") {
      setShowHeader(true);
    } else {
      
      setShowHeader(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, location]);

  const [idTokenExpiry, setConsumerToken] = useConsumerStore((state) => [
    state.idTokenExpiry,
    state.setConsumerToken,
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAndRenewToken = async () => {
      if (!idTokenExpiry) {
        return;
      }
      const tokenIsValid = Date.now() / 1000 < idTokenExpiry;
      if (!tokenIsValid) {
        try {
          const renewedAuthResult = await auth0Service.renewToken();
          const decodedIdToken: { exp: number } = jwtDecode(
            renewedAuthResult.idToken
          );

          setConsumerToken(renewedAuthResult.idToken, decodedIdToken?.exp);
        } catch (error) {
          console.log(error);
          navigate("/logout");
        }
      }
    };

    checkAndRenewToken().catch((error) => {
      console.log(error);
      navigate("/logout");
    });
  }, [idTokenExpiry, navigate, setConsumerToken]);

  return (
    <TourProvider steps={steps}>
      <IntlProvider
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
        messages={messages[locale]}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={window.location.origin}
          useRefreshTokens={true}
          scope="openid phone email profile"
          className="min-h-full">
          <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}>
            {showHeader && <Header />}
            <ApolloProvider client={client}>
              <ToastContainer
                theme="colored"
                bodyClassName="m-0 py-0"
                toastClassName="text-sm font-medium text-gray-800"
              />
              <Outlet />
            </ApolloProvider>
          </GoogleOAuthProvider>
        </Auth0Provider>
      </IntlProvider>
    </TourProvider>
  );
}

export default App;
