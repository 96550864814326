import { gql } from "@apollo/client";

export const GET_SERVICE_CONSUMER_BY_EMAIL_OR_MOBILE = gql`
  query Query($consumer: ConsumerFilterInput!) {
    getConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      homePhoneNumber
      timeZone
      birthDate
      gender
      attributes
      paymentInfoExist
      organizationId
      isPatient
      idProof {
        idNumber
        state
        drivingLicenseFront
        drivingLicenseBack
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide
        backSide
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
        guarantorBirthDate
      }
      dependants {
        id
        firstName
        lastName
        email
        mobileNumber
        homePhoneNumber
        timeZone
        gender
        birthDate
        isPatient
        idProof {
          idNumber
          state
          drivingLicenseFront
          drivingLicenseBack
        }
      }
      externalId
    }
  }
`;

export const CONSUMER_LOOK_UP = gql`
  query Query($consumer: ConsumerFilterInput!) {
    consumerLookUp(consumer: $consumer) {
      id
      mobileNumber
      organizationId
    }
  }
`;

export const GET_CONSUMER_GUARDIAN = gql`
  query Query($consumer: ConsumerFilterInput!) {
    consumerById(consumer: $consumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      homePhoneNumber
      timeZone
      gender
      birthDate
      organizationId
      isPatient
      externalId
      idProof {
        idNumber
        state
        drivingLicenseFront
        drivingLicenseBack
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide
        backSide
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
        guarantorBirthDate
      }
    }
  }
`;
export const GET_CONSUMER_BLOCKED_SLOT = gql`
  query Query($blockedReferenceCode: String!) {
    getBlockedSlot(blockedReferenceCode: $blockedReferenceCode) {
      id
      date
      endTime
      startTime
      appointmentType {
        id
        name
        type
        description
        templateName
        organizationId
        durationInMinutes
        serviceProvider {
          id
          firstName
          lastName
          profilePic
        }
      }
    }
  }
`;

export const GET_AGREEMENT_TEMPLATE = gql`
  query Query($agreementTemplateFilter: AgreementTemplateFilterInput!) {
    getAgreementTemplate(agreementTemplateFilter: $agreementTemplateFilter) {
      id
      organizationId
      name
      contentHtml
      fields {
        key
        type
        required
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_CONSUMER_BY_ID = gql`
  query Query($consumer: ConsumerFilterInput!) {
    consumerById(consumer: $consumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      homePhoneNumber
      timeZone
      gender
      organizationId
      birthDate
      isPatient
      externalId
      dependants {
        id
        firstName
        lastName
        email
        gender
        birthDate
        mobileNumber
        homePhoneNumber
        timeZone
        organizationId
      }
      idProof {
        idNumber
        state
        drivingLicenseFront
        drivingLicenseBack
      }
      addresses {
        id
        line1
        line2
        zipCode
        state
        district
        country
        city
      }

      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide
        backSide
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
        guarantorBirthDate
      }
    }
  }
`;

export const GET_GUEST_CONSUMER = gql`
  query Query($organizationId: Int!) {
    guestUser(organizationId: $organizationId) {
      id
      firstName
      lastName
      email
      gender
      birthDate
      mobileNumber
      homePhoneNumber
      timeZone
      organizationId
      isPatient
      idProof {
        idNumber
        state
        drivingLicenseFront
        drivingLicenseBack
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide
        backSide
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
        guarantorBirthDate
      }
      dependants {
        id
        firstName
        lastName
        email
        gender
        birthDate
        mobileNumber
        homePhoneNumber
        timeZone
        organizationId
        isPatient
        idProof {
          idNumber
          state
          drivingLicenseFront
          drivingLicenseBack
        }
      }
    }
  }
`;

export const CONSUMER_PAYMENT_SOURCES = gql`
  query Query($organizationId: Int!) {
    getCustomerPaymentSources(organizationId: $organizationId) {
      id
      object
      type
      name
      email
      phone
      customer
      card {
        brand
        cvc_check
        country
        exp_month
        exp_year
        fingerprint
        funding
        generated_from
        last4
      }
      created
      address {
        zipCode
        state
      }
    }
  }
`;

export const GET_APPOINTMENT_TYPE_TEMPLATES = gql`
  query Query($organizationId: Int!) {
    appointmentTypeTemplatesByOrganization(organizationId: $organizationId) {
      id
      organizationId
      isTemplate
      templateName
      name
      type
      durationInMinutes
      description
      startDate
      disabled
      paymentLink
      cancellationPolicy
      attributes
      endDate
      minTimeBeforeBookingInMinutes
      locationType
      color
      maximumAppointmentsPerDay
    }
  }
`;
export const GET_AVAILABLE_SLOTS = gql`
  query Query($availabilityFilter: AppointmentAvailabilityFilterInput!) {
    availableSlotsWithServiceProviderInfo(
      availabilityFilter: $availabilityFilter
    ) {
      slots {
        id
        date
        startTime
        endTime
        startDateTime
        endDateTime
        countryCode
        durationInMinutes
        blockedAt
        blockedReferenceCode
        appointmentTypeId
        serviceProviderId
      }

      serviceProviders {
        id
        firstName
        lastName
        email
        timeZone
        active
        attributes
        onboardingStatus
        invite
        externalId
        profilePic
        zipCode
        state
        addresses {
          id
          zipCode
          state
          city
          addressType
          country
        }
      }
    }
  }
`;

export const GET_CONSUMER_APPOINTMENT_EVENT_SLOTS = gql`
  query Query($availabilityFilter: AppointmentAvailabilityFilterInput!) {
    availableSlots(availabilityFilter: $availabilityFilter) {
      id
      date
      startTime
      endTime
      startDateTime
      appointmentTypeId
    }
  }
`;

export const GET_APPOINTMENT_BY_TOKEN = gql`
  query Query($appointmentToken: String!) {
    appointmentByToken(appointmentToken: $appointmentToken) {
      id
      appointmentTypeId
      organizationId
      consumerId
      date
      startTime
      endTime
      note
      token
      status
      durationInMinutes
      cancellationReason
      appointmentType {
        serviceProvider {
          firstName
          lastName
          email
          state
        }
      }
      appointmentWorkflow {
        appointmentId
        workflowId
        appointmentWorkflowTask {
          id
          taskType
          priority
          linkId
          completedDateTime
        }
      }
    }
  }
`;
