import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import GuideImg from "../../assets/images/guide.png";
import quickCheckUpImg from "../../assets/images/quick-checkup.png";
import useAvailabilityStore from "../../store/availability/availability.store";
import BottomOverLayLayout from "../shared/components/bottom-overlay-layout.component";
import ScheduleFooter from "../shared/components/schedule-footer.component";
import WebOverlayLayout from "../shared/components/web-overlay-layout.component";
import ScheduleSessionPreferredDate from "./schedule-overlays/schedule-date.component";
import ScheduleSessionLocation from "./schedule-overlays/schedule-session-location.component";
import ScheduleSessionSpecialty from "./schedule-overlays/schedule-speciality.component";
import ScheduleSessionVisitReason from "./schedule-overlays/schedule-visit-reason.component";
import ScheduleSessionVisitType from "./schedule-overlays/schedule-visit-type.component";

const ScheduleSessionSchema = Yup.object().shape({
  visitType: Yup.string().required("Visit Type is required"),
  patientLocation: Yup.string().required("Location is required"),
  preferredDate: Yup.string().required("Preferred Date is required"),
  specialty: Yup.string().required("Specialty is required"),
  visitReason: Yup.string().required("Visit Reason is required"),
});

const overlayModelsMap = {
  visitType: {
    component: (props) => (
      <div>
        <div className="hidden max-lg:block">
          <BottomOverLayLayout setCurrentOverlay={props.setCurrentOverlay}>
            <ScheduleSessionVisitType {...props} />
          </BottomOverLayLayout>
        </div>
        <WebOverlayLayout setCurrentOverlay={props.setCurrentOverlay}>
          <ScheduleSessionVisitType {...props} />
        </WebOverlayLayout>
      </div>
    ),
  },
  patientLocation: {
    component: (props) => (
      <div>
        <div className="hidden max-lg:block">
          <BottomOverLayLayout setCurrentOverlay={props.setCurrentOverlay}>
            <ScheduleSessionLocation {...props} />
          </BottomOverLayLayout>
        </div>
        <WebOverlayLayout setCurrentOverlay={props.setCurrentOverlay}>
          <ScheduleSessionLocation {...props} />
        </WebOverlayLayout>
      </div>
    ),
  },
  preferredDate: {
    component: (props) => (
      <div>
        <div className="hidden max-lg:block">
          <BottomOverLayLayout setCurrentOverlay={props.setCurrentOverlay}>
            <ScheduleSessionPreferredDate {...props} />
          </BottomOverLayLayout>
        </div>
        <WebOverlayLayout setCurrentOverlay={props.setCurrentOverlay}>
          <ScheduleSessionPreferredDate {...props} />
        </WebOverlayLayout>
      </div>
    ),
  },
  specialty: {
    component: (props) => (
      <div>
        <div className="hidden max-lg:block">
          <BottomOverLayLayout setCurrentOverlay={props.setCurrentOverlay}>
            <ScheduleSessionSpecialty {...props} />
          </BottomOverLayLayout>
        </div>
        <WebOverlayLayout setCurrentOverlay={props.setCurrentOverlay}>
          <ScheduleSessionSpecialty {...props} />
        </WebOverlayLayout>
      </div>
    ),
  },
  visitReason: {
    component: (props) => (
      <div>
        <div className="hidden max-lg:block">
          <BottomOverLayLayout setCurrentOverlay={props.setCurrentOverlay}>
            <ScheduleSessionVisitReason {...props} />
          </BottomOverLayLayout>
        </div>
        <WebOverlayLayout setCurrentOverlay={props.setCurrentOverlay}>
          <ScheduleSessionVisitReason {...props} />
        </WebOverlayLayout>
      </div>
    ),
  },
};

const ScheduleSessionHome = () => {
  const location = window.location.pathname;
  const isSuccess = location === "/success";
  const navigate = useNavigate();

  const [currentOverlay, setCurrentOverlay] = useState("");
  const setWholeState = useAvailabilityStore((state) => state.setWholeState);

  return (
    <div>
      {/* Hero */}
      <div className="bg-gradient-to-t from-[#6993FF]/30 to-white h-full">
        <div className="flex flex-col items-center pt-5 my-10 max-lg:mt-0 max-lg:mb-0">
          <img src={GuideImg} alt="" className="w-20 max-lg:w-auto" />
          <p className="flex max-lg:flex-col items-center max-lg:font-medium font-normal text-3xl max-lg:text-md my-2">
            <span>Hi, I’m Sara.&nbsp; </span>
            <span>Welcome to Azodha Health.</span>
          </p>
          <span className="max-lg:text-sm text-lg font-light text-gray-500">
            Let’s schedule an appointment in few mins.
          </span>
        </div>
        {/* Step */}
        <div className="justify-center mt-4 gap-4 mx-6 hidden max-lg:flex">
          <div className="flex flex-col gap-3 flex-1">
            <div className="w-full h-1 bg-gradient-to-l from-green-500 via-blue-700 to-violet-700"></div>
            <div className={`text-xs text-center`}>Schedule Appointment</div>{" "}
          </div>
          <div className="flex flex-col gap-3 flex-1">
            {isSuccess ? (
              <div className="flex p-3 border-t-4 gap-2 border-green-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  fill="none"
                  viewBox="0 0 20 21">
                  <circle cx="10" cy="10.588" r="10" fill="#34A853"></circle>
                  <circle cx="10" cy="10" r="10" fill="#34A853"></circle>
                  <path
                    stroke="#fff"
                    strokeWidth="2"
                    d="M4.707 10.53l3.369 3.293 7.22-7.058"></path>
                </svg>

                <div className="text-black">Add Patient Details</div>
              </div>
            ) : (
              <>
                <div className="w-full h-1 bg-gray-300"></div>
                <div
                  className={`max-lg:text-xs text-center ${
                    isSuccess ? "text-black" : "text-gray-500"
                  }`}>
                  Add Patient Details
                </div>
              </>
            )}
          </div>
        </div>

        {/* Input buttons */}
        <div className="flex flex-col gap-4 mt-5 pb-32 relative">
          <Formik
            initialValues={{
              visitType: "",
              patientLocation: "",
              preferredDate: "",
              specialty: "",
              visitReason: "",
            }}
            validationSchema={ScheduleSessionSchema}
            onSubmit={async (values) => {
              console.log("values", values);
              setWholeState(values);
              navigate("/providers");
            }}>
            {({ values, setFieldValue }) => (
              <div>
                <Form className="max-lg:flex max-lg:flex-col max-lg:gap-4 w-4/5 mx-auto max-lg:w-full max-lg:px-4">
                  <div className="flex max-lg:flex-col max-lg:gap-4 rounded-md max-w-fit max-lg:max-w-none mx-auto max-lg:mx-0">
                    <div
                      className="max-lg:mx-4"
                      onClick={() => {
                        setCurrentOverlay("visitType");
                      }}>
                      <Field name="visitType">
                        {({ field, form: { touched, errors }, meta }) => (
                          <div>
                            <div className="flex justify-between items-center border border-lightBlue  rounded-l-lg max-lg:rounded-lg p-3 bg-white">
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_5961_72662)">
                                  <path
                                    d="M16.6667 2.16602C17.1267 2.16602 17.5 2.53935 17.5 2.99935V17.9993C17.5 18.4593 17.1267 18.8327 16.6667 18.8327H5C4.54 18.8327 4.16667 18.4593 4.16667 17.9993V16.3327H2.5V14.666H4.16667V12.9993H2.5V11.3327H4.16667V9.66602H2.5V7.99935H4.16667V6.33268H2.5V4.66602H4.16667V2.99935C4.16667 2.53935 4.54 2.16602 5 2.16602H16.6667ZM15.8333 3.83268H5.83333V17.166H15.8333V3.83268ZM11.6667 7.16602V9.66602H14.1667V11.3327H11.6658L11.6667 13.8327H10L9.99917 11.3327H7.5V9.66602H10V7.16602H11.6667Z"
                                    fill="#768089"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_5961_72662">
                                    <rect
                                      width="20"
                                      height="20"
                                      fill="white"
                                      transform="translate(0 0.5)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>

                              <input
                                type="text"
                                placeholder="Visit Type"
                                {...field}
                                className="focus:ring-0 focus:ring-offset-0 border-none h-full w-full"
                              />
                              {values.visitType === "" ? (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clipPath="url(#clip0_5913_50421)">
                                    <path
                                      d="M11 11.5V5.5H13V11.5H19V13.5H13V19.5H11V13.5H5V11.5H11Z"
                                      fill="#3537E8"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5913_50421">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clipPath="url(#clip0_5961_73376)">
                                    <path
                                      d="M9.99974 15.6715L19.1917 6.47852L20.6067 7.89252L9.99974 18.4995L3.63574 12.1355L5.04974 10.7215L9.99974 15.6715Z"
                                      fill="#34A853"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5961_73376">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              )}
                            </div>
                            {meta.touched && meta.error && (
                              <div className="text-red-600 text-sm mt-1 flex items-center gap-3">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M15.952 5.875L20.125 10.0503V15.9528L15.952 20.125H10.0495L5.875 15.952V10.0495L10.0495 5.875H15.952ZM15.3302 7.375H10.6705L7.37575 10.6713V15.331L10.6705 18.6265H15.3302L18.6257 15.331V10.6705L15.3302 7.37575V7.375ZM12.25 15.25H13.75V16.75H12.25V15.25ZM12.25 9.25H13.75V13.75H12.25V9.25Z"
                                    fill="#E21B17"
                                  />
                                </svg>
                                {meta.error}
                              </div>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div
                      className="max-lg:mx-4"
                      onClick={() => {
                        setCurrentOverlay("patientLocation");
                      }}>
                      <Field name="patientLocation">
                        {({ field, form: { touched, errors }, meta }) => (
                          <div>
                            <div className="flex justify-between items-center border border-lightBlue  max-lg:rounded-lg p-3 bg-white">
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M10 17.9179L14.125 13.7929C14.9408 12.9771 15.4963 11.9377 15.7213 10.8061C15.9463 9.67459 15.8308 8.50174 15.3892 7.43587C14.9477 6.37 14.2 5.45899 13.2408 4.81804C12.2815 4.1771 11.1537 3.83499 10 3.83499C8.8463 3.83499 7.71851 4.1771 6.75924 4.81804C5.79997 5.45899 5.05229 6.37 4.61076 7.43587C4.16923 8.50174 4.05368 9.67459 4.27871 10.8061C4.50374 11.9377 5.05926 12.9771 5.875 13.7929L10 17.9179ZM10 20.2746L4.69667 14.9712C3.64779 13.9223 2.93349 12.586 2.64411 11.1311C2.35473 9.67626 2.50326 8.16826 3.07092 6.79782C3.63858 5.42738 4.59987 4.25605 5.83324 3.43194C7.0666 2.60783 8.51665 2.16797 10 2.16797C11.4834 2.16797 12.9334 2.60783 14.1668 3.43194C15.4001 4.25605 16.3614 5.42738 16.9291 6.79782C17.4968 8.16826 17.6453 9.67626 17.3559 11.1311C17.0665 12.586 16.3522 13.9223 15.3033 14.9712L10 20.2746ZM10 11.3346C10.442 11.3346 10.866 11.159 11.1785 10.8464C11.4911 10.5339 11.6667 10.1099 11.6667 9.6679C11.6667 9.22588 11.4911 8.80195 11.1785 8.48939C10.866 8.17683 10.442 8.00124 10 8.00124C9.55798 8.00124 9.13405 8.17683 8.82149 8.48939C8.50893 8.80195 8.33334 9.22588 8.33334 9.6679C8.33334 10.1099 8.50893 10.5339 8.82149 10.8464C9.13405 11.159 9.55798 11.3346 10 11.3346ZM10 13.0012C9.11595 13.0012 8.2681 12.65 7.64298 12.0249C7.01786 11.3998 6.66667 10.552 6.66667 9.6679C6.66667 8.78385 7.01786 7.936 7.64298 7.31088C8.2681 6.68576 9.11595 6.33457 10 6.33457C10.8841 6.33457 11.7319 6.68576 12.357 7.31088C12.9821 7.936 13.3333 8.78385 13.3333 9.6679C13.3333 10.552 12.9821 11.3998 12.357 12.0249C11.7319 12.65 10.8841 13.0012 10 13.0012Z"
                                  fill="#768089"
                                />
                              </svg>

                              <input
                                type="text"
                                placeholder="Patient's Location"
                                {...field}
                                className="focus:ring-0 focus:ring-offset-0 border-none h-full w-full"
                              />
                              {values.patientLocation === "" ? (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clipPath="url(#clip0_5913_50421)">
                                    <path
                                      d="M11 11.5V5.5H13V11.5H19V13.5H13V19.5H11V13.5H5V11.5H11Z"
                                      fill="#3537E8"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5913_50421">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clipPath="url(#clip0_5961_73376)">
                                    <path
                                      d="M9.99974 15.6715L19.1917 6.47852L20.6067 7.89252L9.99974 18.4995L3.63574 12.1355L5.04974 10.7215L9.99974 15.6715Z"
                                      fill="#34A853"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5961_73376">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              )}
                            </div>
                            {meta.touched && meta.error && (
                              <div className="text-red-600 text-sm mt-1 flex items-center gap-3">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M15.952 5.875L20.125 10.0503V15.9528L15.952 20.125H10.0495L5.875 15.952V10.0495L10.0495 5.875H15.952ZM15.3302 7.375H10.6705L7.37575 10.6713V15.331L10.6705 18.6265H15.3302L18.6257 15.331V10.6705L15.3302 7.37575V7.375ZM12.25 15.25H13.75V16.75H12.25V15.25ZM12.25 9.25H13.75V13.75H12.25V9.25Z"
                                    fill="#E21B17"
                                  />
                                </svg>
                                {meta.error}
                              </div>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div
                      className="max-lg:mx-4"
                      onClick={() => {
                        setCurrentOverlay("preferredDate");
                      }}>
                      <Field name="preferredDate">
                        {({ field, form: { touched, errors }, meta }) => (
                          <div>
                            <div className="flex justify-between items-center border border-lightBlue  max-lg:rounded-lg p-3 bg-white">
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M14.167 2.9987H17.5003C17.7213 2.9987 17.9333 3.0865 18.0896 3.24278C18.2459 3.39906 18.3337 3.61102 18.3337 3.83203V17.1654C18.3337 17.3864 18.2459 17.5983 18.0896 17.7546C17.9333 17.9109 17.7213 17.9987 17.5003 17.9987H2.50033C2.27931 17.9987 2.06735 17.9109 1.91107 17.7546C1.75479 17.5983 1.66699 17.3864 1.66699 17.1654V3.83203C1.66699 3.61102 1.75479 3.39906 1.91107 3.24278C2.06735 3.0865 2.27931 2.9987 2.50033 2.9987H5.83366V1.33203H7.50033V2.9987H12.5003V1.33203H14.167V2.9987ZM16.667 9.66536H3.33366V16.332H16.667V9.66536ZM12.5003 4.66536H7.50033V6.33203H5.83366V4.66536H3.33366V7.9987H16.667V4.66536H14.167V6.33203H12.5003V4.66536ZM5.00033 11.332H6.66699V12.9987H5.00033V11.332ZM9.16699 11.332H10.8337V12.9987H9.16699V11.332ZM13.3337 11.332H15.0003V12.9987H13.3337V11.332Z"
                                  fill="#768089"
                                />
                              </svg>

                              <input
                                type="text"
                                placeholder="Preferred Date"
                                {...field}
                                className="focus:ring-0 focus:ring-offset-0 border-none h-full w-full"
                              />
                              {values.preferredDate === "" ? (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clipPath="url(#clip0_5913_50421)">
                                    <path
                                      d="M11 11.5V5.5H13V11.5H19V13.5H13V19.5H11V13.5H5V11.5H11Z"
                                      fill="#3537E8"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5913_50421">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clipPath="url(#clip0_5961_73376)">
                                    <path
                                      d="M9.99974 15.6715L19.1917 6.47852L20.6067 7.89252L9.99974 18.4995L3.63574 12.1355L5.04974 10.7215L9.99974 15.6715Z"
                                      fill="#34A853"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5961_73376">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              )}
                            </div>
                            {meta.touched && meta.error && (
                              <div className="text-red-600 text-sm mt-1 flex items-center gap-3">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M15.952 5.875L20.125 10.0503V15.9528L15.952 20.125H10.0495L5.875 15.952V10.0495L10.0495 5.875H15.952ZM15.3302 7.375H10.6705L7.37575 10.6713V15.331L10.6705 18.6265H15.3302L18.6257 15.331V10.6705L15.3302 7.37575V7.375ZM12.25 15.25H13.75V16.75H12.25V15.25ZM12.25 9.25H13.75V13.75H12.25V9.25Z"
                                    fill="#E21B17"
                                  />
                                </svg>
                                {meta.error}
                              </div>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div
                      className="max-lg:mx-4"
                      onClick={() => {
                        setCurrentOverlay("specialty");
                      }}>
                      <Field name="specialty">
                        {({ field, form: { touched, errors }, meta }) => (
                          <div>
                            <div className="flex justify-between items-center border border-lightBlue  max-lg:rounded-lg p-3 bg-white">
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_5961_72676)">
                                  <path
                                    d="M6.66634 3V4.66667H4.99967V8C4.99967 9.84167 6.49134 11.3333 8.33301 11.3333C10.1747 11.3333 11.6663 9.84167 11.6663 8V4.66667H9.99967V3H12.4997C12.9597 3 13.333 3.37333 13.333 3.83333V8C13.333 10.4775 11.5313 12.5333 9.16634 12.9308V14.25C9.16634 15.8608 10.4722 17.1667 12.083 17.1667C13.3305 17.1667 14.3955 16.3833 14.8122 15.2808C13.9397 14.8917 13.333 14.0167 13.333 13C13.333 11.6192 14.4522 10.5 15.833 10.5C17.2138 10.5 18.333 11.6192 18.333 13C18.333 14.1425 17.5663 15.1058 16.5197 15.4042C16.008 17.3767 14.2155 18.8333 12.083 18.8333C9.55134 18.8333 7.49967 16.7817 7.49967 14.25V12.9308C5.13467 12.5342 3.33301 10.4775 3.33301 8V3.83333C3.33301 3.37333 3.70634 3 4.16634 3H6.66634ZM15.833 12.1667C15.373 12.1667 14.9997 12.54 14.9997 13C14.9997 13.46 15.373 13.8333 15.833 13.8333C16.293 13.8333 16.6663 13.46 16.6663 13C16.6663 12.54 16.293 12.1667 15.833 12.1667Z"
                                    fill="#768089"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_5961_72676">
                                    <rect
                                      width="20"
                                      height="20"
                                      fill="white"
                                      transform="translate(0 0.5)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              <input
                                type="text"
                                placeholder="Select Specialty"
                                {...field}
                                className="focus:ring-0 focus:ring-offset-0 border-none h-full w-full"
                              />
                              {values.specialty === "" ? (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clipPath="url(#clip0_5913_50421)">
                                    <path
                                      d="M11 11.5V5.5H13V11.5H19V13.5H13V19.5H11V13.5H5V11.5H11Z"
                                      fill="#3537E8"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5913_50421">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clipPath="url(#clip0_5961_73376)">
                                    <path
                                      d="M9.99974 15.6715L19.1917 6.47852L20.6067 7.89252L9.99974 18.4995L3.63574 12.1355L5.04974 10.7215L9.99974 15.6715Z"
                                      fill="#34A853"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5961_73376">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              )}
                            </div>
                            {meta.touched && meta.error && (
                              <div className="text-red-600 text-sm mt-1 flex items-center gap-3">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M15.952 5.875L20.125 10.0503V15.9528L15.952 20.125H10.0495L5.875 15.952V10.0495L10.0495 5.875H15.952ZM15.3302 7.375H10.6705L7.37575 10.6713V15.331L10.6705 18.6265H15.3302L18.6257 15.331V10.6705L15.3302 7.37575V7.375ZM12.25 15.25H13.75V16.75H12.25V15.25ZM12.25 9.25H13.75V13.75H12.25V9.25Z"
                                    fill="#E21B17"
                                  />
                                </svg>
                                {meta.error}
                              </div>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div
                      className="max-lg:mx-4"
                      onClick={() => {
                        setCurrentOverlay("visitReason");
                      }}>
                      <Field name="visitReason">
                        {({ field, form: { touched, errors }, meta }) => (
                          <div>
                            <div className="flex justify-between items-center border border-lightBlue rounded-r-lg max-lg:rounded-lg p-3 bg-white">
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_5913_50432)">
                                  <path
                                    d="M9.99967 12.1654V13.832C8.67359 13.832 7.40182 14.3588 6.46414 15.2965C5.52646 16.2342 4.99967 17.5059 4.99967 18.832H3.33301C3.33301 17.0639 4.03539 15.3682 5.28563 14.118C6.53587 12.8677 8.23156 12.1654 9.99967 12.1654ZM9.99967 11.332C7.23717 11.332 4.99967 9.09453 4.99967 6.33203C4.99967 3.56953 7.23717 1.33203 9.99967 1.33203C12.7622 1.33203 14.9997 3.56953 14.9997 6.33203C14.9997 9.09453 12.7622 11.332 9.99967 11.332ZM9.99967 9.66536C11.8413 9.66536 13.333 8.1737 13.333 6.33203C13.333 4.49036 11.8413 2.9987 9.99967 2.9987C8.15801 2.9987 6.66634 4.49036 6.66634 6.33203C6.66634 8.1737 8.15801 9.66536 9.99967 9.66536ZM17.8713 17.192L19.1247 18.4454L17.9463 19.6237L16.693 18.3704C15.9941 18.7824 15.1689 18.9255 14.3721 18.7727C13.5753 18.6199 12.8616 18.1817 12.3648 17.5403C11.8679 16.8989 11.6221 16.0983 11.6733 15.2886C11.7245 14.4789 12.0693 13.7157 12.643 13.142C13.2166 12.5683 13.9799 12.2235 14.7896 12.1723C15.5993 12.1211 16.3999 12.367 17.0413 12.8638C17.6827 13.3606 18.1208 14.0744 18.2736 14.8712C18.4265 15.668 18.2834 16.4931 17.8713 17.192ZM14.9997 17.1654C15.4417 17.1654 15.8656 16.9898 16.1782 16.6772C16.4907 16.3646 16.6663 15.9407 16.6663 15.4987C16.6663 15.0567 16.4907 14.6327 16.1782 14.3202C15.8656 14.0076 15.4417 13.832 14.9997 13.832C14.5576 13.832 14.1337 14.0076 13.8212 14.3202C13.5086 14.6327 13.333 15.0567 13.333 15.4987C13.333 15.9407 13.5086 16.3646 13.8212 16.6772C14.1337 16.9898 14.5576 17.1654 14.9997 17.1654Z"
                                    fill="#768089"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_5913_50432">
                                    <rect
                                      width="20"
                                      height="20"
                                      fill="white"
                                      transform="translate(0 0.5)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>

                              <input
                                type="text"
                                placeholder="Visit Reason"
                                {...field}
                                className="focus:ring-0 focus:ring-offset-0 border-none h-full w-full"
                              />
                              {values.visitReason === "" ? (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clipPath="url(#clip0_5913_50421)">
                                    <path
                                      d="M11 11.5V5.5H13V11.5H19V13.5H13V19.5H11V13.5H5V11.5H11Z"
                                      fill="#3537E8"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5913_50421">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clipPath="url(#clip0_5961_73376)">
                                    <path
                                      d="M9.99974 15.6715L19.1917 6.47852L20.6067 7.89252L9.99974 18.4995L3.63574 12.1355L5.04974 10.7215L9.99974 15.6715Z"
                                      fill="#34A853"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5961_73376">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              )}
                            </div>
                            {meta.touched && meta.error && (
                              <div className="text-red-600 text-sm mt-1 flex items-center gap-3">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M15.952 5.875L20.125 10.0503V15.9528L15.952 20.125H10.0495L5.875 15.952V10.0495L10.0495 5.875H15.952ZM15.3302 7.375H10.6705L7.37575 10.6713V15.331L10.6705 18.6265H15.3302L18.6257 15.331V10.6705L15.3302 7.37575V7.375ZM12.25 15.25H13.75V16.75H12.25V15.25ZM12.25 9.25H13.75V13.75H12.25V9.25Z"
                                    fill="#E21B17"
                                  />
                                </svg>
                                {meta.error}
                              </div>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="max-lg:mx-4 flex items-center justify-center my-10 max-lg:my-0">
                    <button
                      type="submit"
                      className="w-max-fit max-lg:w-full bg-black px-20 max-sm:px-4 py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16">
                      Check Availability
                    </button>
                  </div>
                </Form>
                {currentOverlay !== "" &&
                  overlayModelsMap[currentOverlay].component({
                    value: values,
                    setCurrentOverlay,
                    setFieldValue,
                  })}
              </div>
            )}
          </Formik>
        </div>
      </div>

      {/* Quick Book Appointment */}
      <div className="mx-auto w-fit max-lg:w-[95%] max-lg:mx-0">
        <p className="mx-4 py-4 text-2xl max-lg:hidden text-left">
          Our Providers
        </p>
        <div className="mx-4 py-4 flex gap-4 overflow-x-auto w-full items-center">
          <div className="border border-bgLightBlue rounded-md flex gap-5 p-2 min-w-fit">
            <img src={quickCheckUpImg} className="w-20 rounded-lg" alt="" />
            <div className="w-full flex flex-col justify-between">
              <div className="w-52 max-lg:w-auto">
                <p className="font-semibold tracking-wider">
                  General Physician{" "}
                </p>
                <span className="text-gray-500 tracking-wider">
                  Dr. Michelle Monaghan
                </span>
              </div>
              <button
                type="button"
                className="flex items-center gap-2 text-primary font-medium text-md py-2">
                Book Now
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.91797 7.5H11.0846"
                    stroke="#3537E8"
                    strokeWidth="1.16667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 3.41797L11.0833 7.5013L7 11.5846"
                    stroke="#3537E8"
                    strokeWidth="1.16667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="border border-bgLightBlue rounded-md flex gap-5 p-2 min-w-fit">
            <img src={quickCheckUpImg} className="w-20 rounded-lg" alt="" />
            <div className="w-full flex flex-col justify-between">
              <div className="w-52 max-lg:w-auto">
                <p className="font-semibold tracking-wider">
                  General Physician{" "}
                </p>
                <span className="text-gray-500 tracking-wider">
                  Dr. Michelle Monaghan
                </span>
              </div>
              <button
                type="button"
                className="flex items-center gap-2 text-primary font-medium text-md py-2">
                Book Now
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.91797 7.5H11.0846"
                    stroke="#3537E8"
                    strokeWidth="1.16667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 3.41797L11.0833 7.5013L7 11.5846"
                    stroke="#3537E8"
                    strokeWidth="1.16667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="border border-bgLightBlue rounded-md flex gap-5 p-2 min-w-fit">
            <img src={quickCheckUpImg} className="w-20 rounded-lg" alt="" />
            <div className="w-full flex flex-col justify-between">
              <div className="w-52 max-lg:w-auto">
                <p className="font-semibold tracking-wider">
                  General Physician{" "}
                </p>
                <span className="text-gray-500 tracking-wider">
                  Dr. Michelle Monaghan
                </span>
              </div>
              <button
                type="button"
                className="flex items-center gap-2 text-primary font-medium text-md py-2">
                Book Now
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.91797 7.5H11.0846"
                    stroke="#3537E8"
                    strokeWidth="1.16667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7 3.41797L11.0833 7.5013L7 11.5846"
                    stroke="#3537E8"
                    strokeWidth="1.16667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="mx-auto w-fit max-lg:w-auto max-lg:mb-0 mb-20">
        <p className="mt-8 mb-2 mx-4 font-semibold ">
          What our patient has to say about us
        </p>
        <div className="px-4 pb-4 h-full flex gap-4 overflow-x-auto">
          <div className="border border-lightBlue bg-bgLightBlue rounded-md flex flex-col gap-5 p-4 max-lg:min-w-fit">
            <p className="text-base text-gray-600 w-72 max-lg:w-auto">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
            <div>
              <p className="font-semibold tracking-wider">
                Parth Shukla / Mumbai
              </p>
              <span className="text-primary font-medium tracking-wider">
                General Physician
              </span>
            </div>
          </div>
          <div className="border border-lightBlue bg-bgLightBlue rounded-md flex gap-5 p-4 min-w-fit flex-col">
            <p className="text-base text-gray-600 w-72 max-lg:w-auto">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
            <div>
              <p className="font-semibold tracking-wider">
                Parth Shukla / Mumbai
              </p>
              <span className="text-primary font-medium tracking-wider">
                General Physician
              </span>
            </div>
          </div>
          <div className="border border-lightBlue bg-bgLightBlue rounded-md flex gap-5 p-4 min-w-fit flex-col">
            <p className="text-base text-gray-600 w-72 max-lg:w-auto">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
            <div>
              <p className="font-semibold tracking-wider">
                Parth Shukla / Mumbai
              </p>
              <span className="text-primary font-medium tracking-wider">
                General Physician
              </span>
            </div>
          </div>
        </div>
      </div>
      <ScheduleFooter />
    </div>
  );
};

export default ScheduleSessionHome;
