import { useLazyQuery, useMutation } from "@apollo/client";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  GET_CONSUMER_BY_ID,
  UPDATE_CONSUMER_IDENTITY_PROOF,
} from "../../apollo";
import { IServiceConsumer } from "../../interfaces";
import { loggerService } from "../../services";
import websocketLoggerService from "../../services/websocket-logger";
import useFormStore from "../../store/form/form.store";
import FieldError from "../shared/components/field-error.component";
import GuideBanner from "../shared/components/guide-banner.component";
import UploadFile from "../shared/components/upload-file.component";
import { PatientFlowType, ScopeType } from "../shared/config";
import { helper } from "../shared/utils";

const PatientIdentityProofSchema = Yup.object().shape({
  InsuranceFrontSide: Yup.string().required("Required"),
  InsuranceBackSide: Yup.string(),
});

interface InsuranceUploadProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
  NextBtn: React.RefObject<HTMLButtonElement>;
  haveInsurance: boolean;
  setHaveInsurance: (haveInsurance: boolean) => void;
}

const InsuranceUpload: React.FC<InsuranceUploadProps> = ({
  setStep,
  step,
  NextBtn,
  haveInsurance,
  setHaveInsurance,
}) => {
  const [newPatient, setNewPatient] = useState(null);

  const [newPatientId, patientFlow] = useFormStore((state) => [
    state.newPatientID,
    state.patientFlow,
  ]);

  const [updateConsumerIdentityProof] = useMutation(
      UPDATE_CONSUMER_IDENTITY_PROOF
    ),
    [getConsumerByIdPatient] = useLazyQuery<{
      getConsumerById: IServiceConsumer;
    }>(GET_CONSUMER_BY_ID, {
      context: {
        scope: ScopeType.Consumer,
      },
    });

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: patientData } = await getConsumerByIdPatient({
          variables: {
            consumer: {
              consumerId: newPatientId,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            },
          },
          // disable cache for this
          fetchPolicy: "no-cache",
        });

        const response = helper.getPropertyValue<IServiceConsumer>(
          patientData,
          "consumerById"
        );
        setNewPatient(response);
        websocketLoggerService.sendMessage({
          eventType: "PATIENT_INSURANCE_UPLOAD",
          eventSubType: "API Request: getConsumerByIdPatient success",
          eventData: `ConsumerId : ${newPatientId} , OrganizationId : ${process.env.REACT_APP_ORGANIZATION_ID}`,
        });
      } catch (error) {
        websocketLoggerService.sendMessage({
          eventType: "PATIENT_INSURANCE_UPLOAD",
          eventSubType: "Error: getConsumerByIdPatient failed",
          eventData: `Error : ${JSON.stringify(error)}`,
        });
        loggerService.log(error);
      }
    };
    if (newPatientId) {
      getData();
    }
  }, [getConsumerByIdPatient, newPatientId]);

  const handleSubmit = async (values, setSubmitting) => {
    try {
      const { idProof, insuranceDetails, ...details } = newPatient;
      await updateConsumerIdentityProof({
        variables: {
          consumer: {
            ...details,
            idProof: {},
            insuranceDetails: {
              ...insuranceDetails,
              frontSide: values.InsuranceFrontSide,
              backSide: values.InsuranceBackSide,
            },
          },
        },
        context: {
          scope: ScopeType.Consumer,
        },
      });

      websocketLoggerService.sendMessage({
        eventType: "PATIENT_INSURANCE_UPLOAD",
        eventSubType: "API Request: updateConsumerIdentityProof success",
        eventData: `ConsumerId : ${newPatientId}`,
      });

      // TODO not updating the store if the consumer is guardian due to session storage limit as images are in base64 once it s3 links will update the store
      websocketLoggerService.sendMessage({
        eventType: "PATIENT_INSURANCE_UPLOAD",
        eventSubType:
          "Success: updateConsumerIdentityProof, Navigating to next step",
        eventData: `ConsumerId : ${newPatientId}`,
      });
      if (patientFlow === PatientFlowType.Yourself) {
        setStep((prevStep: number) => prevStep + 1);
      } else {
        setStep((prevStep: number) => prevStep + 1);
      }
    } catch (err) {
      websocketLoggerService.sendMessage({
        eventType: "PATIENT_INSURANCE_UPLOAD",
        eventSubType: "Error: updateConsumerIdentityProof",
        eventData: `Error : ${JSON.stringify(err)}`,
      });
      setSubmitting(false);
      toast.error("File too large.");
      loggerService.error(err);
    }
  };

  return (
    <div className="pb-10 px-4 w-3/4 max-lg:w-full">
      <div className="border border-gray-200 p-4 rounded-md mb-8 flex items-center justify-between gap-2">
        <div className="flex items-center gap-4 text-lg">
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="9.5" cy="10.2206" r="9.5" className="fill-lightBlue" />
            <path
              d="M4.46875 10.7233L7.66928 13.8527L14.5276 7.14685"
              stroke="white"
              strokeWidth="1.5"
            />
          </svg>

          <p className="flex justify-between ">
            {newPatient?.firstName} {newPatient?.lastName}
          </p>
        </div>
        <span className="text-gray-400">Patient</span>
      </div>

      <h1 className="font-bold text-3xl mb-6">Patient Insurance Details</h1>

      <div className="p-4 bg-bgLightBlue rounded-[10px] ">
        <p>Does the patient have insurance ?</p>
        <form className=" flex flex-col items-center gap-4 justify-between mt-2 w-full">
          <div className="flex items-center justify-start gap-2 mt-2 bg-white w-full flex-1 p-4 rounded-xl">
            <input
              onChange={() => setHaveInsurance(true)}
              type="radio"
              id="yes"
              name="patientFlow"
              checked={haveInsurance}
              value="yes"
            />
            <label htmlFor="yes">Yes</label>
          </div>
          <div className="flex items-center justify-start gap-2  bg-white w-full flex-1 p-4 rounded-xl">
            <input
              onChange={() => setHaveInsurance(false)}
              type="radio"
              id="no"
              checked={!haveInsurance}
              name="patientFlow"
              value="no"
            />
            <label htmlFor="no">No </label>
          </div>
        </form>
      </div>

      <hr className="mb-4" />

      {haveInsurance && (
        <div>
          <Formik
            initialValues={{
              InsuranceFrontSide: newPatient?.insuranceDetails?.frontSide || "",
              InsuranceBackSide: newPatient?.insuranceDetails?.backSide || "",
            }}
            enableReinitialize
            validationSchema={PatientIdentityProofSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}>
            {(props: FormikProps<any>) => (
              <Form className="flex flex-col items-start">
                <div className="w-full mt-4">
                  <h1 className="font-bold text-3xl mb-6">
                    Upload Patient's Primary Insurance Card Image
                  </h1>
                  <div>
                    <div>
                      <label className="mb-2 text-sm font-medium text-gray-600 ">
                        Front of the Insurance
                        <span className="text-red-500 text-base"> *</span>
                      </label>
                      <Field
                        type="file"
                        data-clarity-mask="True"
                        id="InsuranceFrontSide"
                        name="InsuranceFrontSide"
                        placeholder="Front of Insurance"
                        component={UploadFile}
                      />
                      <ErrorMessage name="InsuranceFrontSide">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </div>
                    <div className=" my-4">
                      <label className=" mb-2 text-sm font-medium text-gray-600 ">
                        Back of the Insurance
                      </label>
                      <Field
                        type="file"
                        data-clarity-mask="True"
                        id="InsuranceBackSide"
                        name="InsuranceBackSide"
                        placeholder="Front of Insurance"
                        component={UploadFile}
                      />
                      <ErrorMessage name="InsuranceBackSide">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-x-4 sticky bottom-0 sm:static bg-white w-full">
                  <button
                    onClick={() => {
                      if (patientFlow === PatientFlowType.Yourself) {
                        setStep((prev) => prev - 1);
                      } else {
                        setStep((prev) => prev - 1);
                      }
                    }}
                    className="border font-bold text-sm sm:text-base border-gray-300 rounded-md px-6 py-4"
                    type="button">
                    Back
                  </button>
                  <button
                    ref={NextBtn}
                    type="submit"
                    className="w-full bg-black px-6 sm:px-10 text-sm sm:text-base py-4 my-4 text-white rounded-md font-bold">
                    {props.isSubmitting ? (
                      <div className="flex gap-2 items-center">
                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                        <div>Submitting...</div>
                      </div>
                    ) : (
                      "Next Up: Payment Consent"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {!haveInsurance && (
        <div className="flex flex-col items-start h-80">
          <GuideBanner body="I certify that I do not have any health insurance coverage and will be responsible to pay for services offered" />
          <div
            style={{
              marginBottom: "50vh",
            }}></div>
          <div className="flex items-center gap-4 sticky bottom-0 justify-start  w-full h-20 bg-white">
            <button
              onClick={() => {
                if (patientFlow === PatientFlowType.Yourself) {
                  setStep((prev) => prev - 1);
                } else {
                  setStep((prev) => prev - 1);
                }
              }}
              className="border font-bold border-gray-300 rounded-md px-6 py-4 my-4"
              type="button">
              Back
            </button>
            <button
              ref={NextBtn}
              onClick={() => {
                if (patientFlow === PatientFlowType.Yourself) {
                  setStep((prev) => prev + 1);
                } else {
                  setStep((prev) => prev + 1);
                }
              }}
              type="button"
              className="w-full bg-black px-6 sm:px-12 text-sm sm:text-base py-4 my-4 text-white rounded-md font-bold">
              {"Next Up: Consent Sign"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InsuranceUpload;
